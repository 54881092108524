import { useAppDispatch, useAppSelector } from './app/hooks'
import { RootState } from './app/store'
import { Outlet, Route, Routes } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import LoadingOverlay from './components/LoadingOverlay'
import PublicRoute from './components/PublicRoute'
import NotFound from './pages/NotFound'
import SignIn from './pages/Auth'
import Menu from './components/Menu'
import { actions as commonActions, getGroups, getUser, readProfileImage } from './app/commonSlice';

import { useCallback, useEffect, useState } from 'react'
import { logOutUser, refreshSession, validateToken } from './pages/Auth/slice'
import { RESET_INFO, SET_IS_LOADING, SET_PROGRESS_VALUE } from './app/actionTypes'
import Home from './pages/Home'
import Profile from './pages/Profile'
import Users from './pages/Users'
import Groups from './pages/Groups'
import Devices from './pages/Devices'

function App() {
	const appDispatch = useAppDispatch()
	const { session }: any = useAppSelector(
		(state: RootState) => state.authReducer,
	)
	const { user, groups }: any = useAppSelector(
		(state: RootState) => state.commonReducer,
	)
	const { currentUser }: any = useAppSelector(
		(state: RootState) => state.profileReducer,
	)

	const [progressValue, setProgressValue] = useState(0)
	const [checkSession, setCheckSession] = useState(false)

	const signOut = useCallback(() => {
		appDispatch(commonActions[SET_IS_LOADING](true))
		appDispatch(commonActions[SET_PROGRESS_VALUE](1))
		appDispatch(logOutUser()).unwrap()
			.then(() => {
				appDispatch(commonActions[SET_IS_LOADING](false))
				appDispatch(commonActions[RESET_INFO]())
			})
			.catch(() => {
				appDispatch(commonActions[SET_IS_LOADING](false))
				appDispatch(commonActions[RESET_INFO]())
			})
	}, [])

	useEffect(() => {
		if (session) {
			appDispatch(validateToken({ session, onRefresh: (_session: any) => appDispatch(refreshSession(_session)).unwrap() })).unwrap()
				.then(async () => {
					setProgressValue(50)
					try {
						await appDispatch(getUser()).unwrap()
						setProgressValue(95)
						setCheckSession(true)
						return await appDispatch(getGroups()).unwrap()
					} catch (error: any) {
						if (error.message === "Unauthorized") {
							signOut()
							setCheckSession(true)
						}
					}
				}).catch(() => {
					setCheckSession(true)
				})
		} else {
			setCheckSession(true)
		}
	}, [session, getUser, getGroups])

	const [refUser, setRefUser] = useState(currentUser || user)

	useEffect(() => {
		if (currentUser || user) {
			setProgressValue(100)
			setRefUser(currentUser || user)
		}
	}, [currentUser, user])

	return (
		<Routes>
			<Route
				element={
					(!checkSession || (session && (!user || !groups.length))) ?
						<LoadingOverlay progressValue={progressValue || 80} />
						:
						<Outlet />
				}
			>
				<Route element={
					<PrivateRoute
						authenticated={!!session}
						redirectRoute="/sign-in"
					/>
				}>
					<Route
						element={
							<Menu readFileRequest={(imageKey) => appDispatch(readProfileImage(imageKey)).unwrap()} user={refUser} signOut={signOut} userType={!groups.find((group: any) => group.name === "Administradores") ? "common" : "administrator"}>
								<Outlet />
							</Menu>
						}
					>
						<Route path='/' element={<Home />} />
						<Route path='/home' element={<Home />} />
						<Route path='/profile' element={<Profile />} />
						<Route path='/users' element={<Users />} />
						<Route path='/groups' element={<Groups />} />
						<Route path='/devices' element={<Devices />} />
					</Route>
				</Route>
				<Route element={
					<PublicRoute
						authenticated={!!session}
						redirectRoute='/'
					/>
				}>
					<Route path="/sign-in" element={<SignIn />} />
				</Route>
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	)
}

export default App
