import { useCallback, useEffect, useRef, useState } from "react";
import TabBar from "../../components/TabBar";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { listDeviceData, listOwnDeviceData, readDeviceImage, readLatestDeviceData, readOwnLatestDeviceData } from "./slice";
import AlertError from "../../components/AlertError";
import SevenSegmentDisplay from "../../components/SevenSegmentDisplay";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import ImageByKey from "../../components/ImageByKey";
import CustomLineChar from "../../components/CustomLineChart";
import Datepicker from "../../components/Datepicker";
import CustomTable from "../../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { getDeviceState, getErrorMessages } from "../../libs/deviceFunctions";

const padZero = (num: number) => num.toString().padStart(2, '0');

export default function Home() {
	const navigate = useNavigate()
	const appDispatch = useAppDispatch();
	const { device, deviceData, loading, report } = useAppSelector((state: RootState) => state.homeReducer);
	const { user }: any = useAppSelector((state: RootState) => state.commonReducer);
	const { currentUser } = useAppSelector((state: RootState) => state.profileReducer);
	const isOwnUser = !currentUser || user.id === currentUser.id;
	const [errorTitle, setErrorTitle] = useState("");
	const [errors, setErrors] = useState<string[]>([]);

	if (!device || device && !device.id) {
		navigate("/devices")
	}

	const handleReadLatestDeviceData = useCallback(async () => {
		try {
			const response = isOwnUser
				? await appDispatch(readOwnLatestDeviceData({ deviceId: device.id })).unwrap()
				: await appDispatch(readLatestDeviceData({ userId: currentUser.id, deviceId: device.id })).unwrap();
			return response;
		} catch (error: any) {
			setErrorTitle("Erro ao ler dados");
			if (error.code === "#DeviceDataNotFound") {
				setErrors(["Não há dados desse dispositivo"]);
			} else {
				setErrors(["Erro inesperado"]);
			}
		}
	}, [isOwnUser, device, currentUser]);

	useEffect(() => {
		handleReadLatestDeviceData();
	}, []);

	const [tabs, setTabs] = useState([
		{ name: "Status", current: true, onClick: (tabIndex: number) => tabIndex },
		{ name: "Gráficos", current: false, onClick: (tabIndex: number) => tabIndex },
		{ name: "Alarmes", current: false, onClick: (tabIndex: number) => tabIndex },
	]);

	const handleChangeTab = useCallback((tabIndex: number) => {
		let _tabs = [...tabs];
		_tabs.forEach((tab, index) => {
			tab.current = tabIndex === index;
		});
		setTabs(_tabs);
	}, [tabs]);

	const createdAt = new Date(deviceData && deviceData.createdAt);
	const isEnabled = (new Date().getTime() - createdAt.getTime()) < 3 * 60 * 1000;
	const lastUpdated = format(createdAt, "dd/MM/yyyy HH:mm:ss", { locale: ptBR });

	const [dateRange, setDateRange] = useState<any>({
		startDate: `${new Date().getFullYear()}-${padZero(new Date().getMonth() + 1)}-${padZero(new Date().getDate())}`,
		endDate: `${new Date().getFullYear()}-${padZero(new Date().getMonth() + 1)}-${padZero(new Date().getDate())}`
	})

	const errorsTableRef = useRef(null)
	const reportColumns: any = useRef([
		{ title: "Alarme", type: "string", key: "alarm", hidden: false },
		{ title: "Descrição", type: "string", key: "description", hidden: false },
		{ title: "Tipo", type: "string", key: "type", hidden: false, render: (rowData: any) =>  rowData.type === "error" ? "Erro" : "Aviso"},
		{ title: "Criado em", type: "date-time", key: "createdAt", hidden: false }
	]);

	const handleUpdateReport = useCallback(async (startDate: string, endDate: string) => {
		//add 1 day to query
		const _endDate = new Date(endDate);
		_endDate.setDate(_endDate.getDate() + 2);
		const __endDate = `${_endDate.getFullYear()}-${padZero(_endDate.getMonth() + 1)}-${padZero(_endDate.getDate())}`
		const response = isOwnUser
			? await appDispatch(listOwnDeviceData({ deviceId: device.id, startDate, endDate: __endDate })).unwrap()
			: await appDispatch(listDeviceData({ userId: currentUser.id, deviceId: device.id, startDate, endDate: __endDate })).unwrap();
		return response;
	}, [dateRange, device, listOwnDeviceData, listDeviceData])

	useEffect(() => {
		if (dateRange.startDate && dateRange.endDate) {
			handleUpdateReport(dateRange.startDate, dateRange.endDate)
		}
	}, [dateRange, device])

	return (
		<div className="flex w-full h-full justify-start items-center flex-col">
			<div className="w-full p-10 md:p-2 sm:p-2">
				<TabBar tabs={tabs} onChangeTab={handleChangeTab} />
			</div>
			{/* Status view */}
			<span hidden={!tabs[0].current} className="w-full p-10 md:p-2 sm:p-2">
				<div className="flex flex-col gap-5">
					<div className="w-full flex px-4 border-8 border-gray-400">
						<div className="flex flex-wrap w-full h-full">
							<div className="flex flex-col gap-2 py-4">
								<dl className="w-full max-w-[400px]">
									<dt className="w-[300px] text-[30px] font-medium text-gray-900">{device.name}</dt>
									<dd className="text-[20px] text-gray-700">
										{device.nodeId}
									</dd>
									<dd className="text-[20px] text-gray-700">
										{deviceData && deviceData.data && deviceData.data["Devicetype.BM_s_DriveName"]}
									</dd>
								</dl>
								<dl className="w-full max-w-[400px]">
									<dt className="w-[300px] text-[20px] font-medium text-gray-900">{isEnabled ? "Habilitado" : "Desabilitado"}</dt>
									<dd className="text-[20px] text-gray-700">
										Última atualização: {lastUpdated}
									</dd>
								</dl>
							</div>
							<div className="flex px-4 items-center justify-start md:w-full sm:w-full md:justify-center sm:justify-center border-r-8 border-gray-400">
								<SevenSegmentDisplay number={getDeviceState(parseInt(deviceData && deviceData.data && deviceData.data["Drivemanager.BM_w_Statusword"] || "0", 10))} />
							</div>
							<div className="flex h-full flex-grow items-center justify-center md:border-none sm:border-none">
								<ImageByKey
									className="object-fit"
									imageKey={device.deviceImageKey}
									readFileRequest={async (imageKey) => appDispatch(readDeviceImage(imageKey)).unwrap()}
								/>
							</div>
						</div>
					</div>
					<div className="w-full flex px-4 border-8 border-gray-400">
						<div className="flex flex-wrap w-full h-full">
							<div className="mt-5">
								<h3 className="text-base font-semibold leading-7 text-gray-900">Informações do drive</h3>
							</div>
							<div className="w-full mt-4">
								<dl className="w-full max-w-[400px] ">
									<div className="flex w-full py-4 items-center justify-between">
										<dt className="w-[250px] text-sm font-medium leading-6 text-gray-900">Temperatura atual do dispositivo interno da Unidade de Potência</dt>
										<dd className="text-sm leading-6 text-gray-700">{deviceData && deviceData.data && deviceData.data["ConfigurationPowerUnit.BM_u_AmpActAmbientTemp"]}°C</dd>
									</div>
								</dl>
								<dl className="w-full max-w-[400px] ">
									<div className="flex w-full py-4 items-center justify-between">
										<dt className="w-[250px] text-sm font-medium leading-6 text-gray-900">Temperatura atual do dissipador de calor da Unidade de Potência</dt>
										<dd className="text-sm leading-6 text-gray-700">{deviceData && deviceData.data && deviceData.data["ConfigurationPowerUnit.BM_u_AmpActHeatsinkTemp"]}°C</dd>
									</div>
								</dl>
								<dl className="w-full max-w-[400px] ">
									<div className="flex w-full py-4 items-center justify-between">
										<dt className="w-[250px] text-sm font-medium leading-6 text-gray-900">Tensão atual do elo CC da Unidade de Potência</dt>
										<dd className="text-sm leading-6 text-gray-700">{deviceData && deviceData.data && deviceData.data["ConfigurationPowerUnit.BM_u_AmpActDCLinkVolt"]}V</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>
					<div className="w-full flex px-4 border-8 border-gray-400">
						<div className="flex flex-wrap w-full h-full">
							<div className="mt-5">
								<h3 className="text-base font-semibold leading-7 text-gray-900">Informações do motor</h3>
							</div>
							<div className="w-full mt-4">
								<dl className="w-full max-w-[400px] ">
									<div className="flex w-full py-4 items-center justify-between">
										<dt className="w-[250px] text-sm font-medium leading-6 text-gray-900">Temperatura atual</dt>
										<dd className="text-sm leading-6 text-gray-700">{deviceData && deviceData.data && deviceData.data["ConfigurationMotor.BM_i_MotorActTemp"]}°C</dd>
									</div>
								</dl>
								<dl className="w-full max-w-[400px] ">
									<div className="flex w-full py-4 items-center justify-between">
										<dt className="w-[250px] text-sm font-medium leading-6 text-gray-900">Valor atual de I²t</dt>
										<dd className="text-sm leading-6 text-gray-700">{deviceData && deviceData.data && deviceData.data["ConfigurationMotor.BM_ud_MotorAct_I2t"]}%</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</span>
			{/* Charts view */}
			<span hidden={!tabs[1].current} className="w-full p-10 md:p-2 sm:p-2">
				<Datepicker
					value={dateRange}
					onChange={(newDateRange: any) => setDateRange(newDateRange)}
					popoverDirection="down"
					i18n="pt"
					maxDate={new Date()}
					separator="até"
					dateLooking="middle"
					configs={{
						shortcuts: {
							today: "Hoje",
							yesterday: "Ontem",
							past: period => `Últimos ${period} dias`,
							currentMonth: "Esse mês",
							pastMonth: "Mês passado"
						},
						footer: {
							cancel: "Cancelar",
							apply: "Aplicar"
						}
					}}
					displayFormat="DD/MM/YYYY"
					useRange={false}
					asSingle={false}
				/>
				<div className="w-full max-w-[420px] flex flex-col justify-center mt-5">
					<CustomLineChar data={report.map((devData: any) => ({
						y: devData && devData.data && devData.data["ConfigurationMotor.BM_i_MotorActTemp"] || 0,
						x: new Date(devData && devData.createdAt || dateRange.startDate).getTime()
					}))} seriesName={"Temperatura atual do motor"} minX={new Date(dateRange.startDate).getTime()} />
				</div>
			</span>
			{/* Alarms view */}
			<span hidden={!tabs[2].current} className="w-full p-10 md:p-2 sm:p-2">
				<Datepicker
					value={dateRange}
					onChange={(newDateRange: any) => setDateRange(newDateRange)}
					popoverDirection="down"
					i18n="pt"
					maxDate={new Date()}
					separator="até"
					dateLooking="middle"
					configs={{
						shortcuts: {
							today: "Hoje",
							yesterday: "Ontem",
							past: period => `Últimos ${period} dias`,
							currentMonth: "Esse mês",
							pastMonth: "Mês passado"
						},
						footer: {
							cancel: "Cancelar",
							apply: "Aplicar"
						}
					}}
					displayFormat="DD/MM/YYYY"
					useRange={false}
					asSingle={false}
				/>
				<div className="w-full mt-5">
					<CustomTable
						ref={errorsTableRef}
						data={report.map((rowData: any) => {
							return getErrorMessages(rowData.data).map((errorMessage) => ({...errorMessage, createdAt: rowData.createdAt}))
						}).flat()}
						loading={loading}
						isSearchable={false}
						title={"Erros dispositivo"}
						subtitle={"Todos os erros do dispositivo"}
						columns={reportColumns.current}
						editLabel={""}
						isRelational={true}
						refresh={false}
						readFileRequest={async (imageKey) => appDispatch(readDeviceImage(imageKey)).unwrap()}
						onRefresh={() => handleUpdateReport(dateRange.startDate, dateRange.endDate)}
						defaultSortKey="createdAt"
						defaultSortOrder="desc"
						labelRows="linhas"
					/>
				</div>
			</span>
			<AlertError onClose={() => setErrors([])} errorTitle={errorTitle} errors={errors} duration={5000} position="bottom-right" />
		</div>
	);
}
