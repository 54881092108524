import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { LIST_USERS, READ_MY_USER, READ_OTHER_USER, READ_PROFILE_IMAGE, SET_CURRENT_USER_PROFILE, UPDATE_MY_USER_ATTRIBUTES, UPDATE_PASSWORD, UPLOAD_PROFILE_IMAGE } from "../../app/actionTypes"
import { getOwnUser, getUserByUsername, listUsers as _listUsers, uploadFile, readFile, updateOwnUser, updatePassword as _updatePassword } from "../../services/backendAPI"

export const readMyUser = createAsyncThunk(
  READ_MY_USER,
  async (_, thunkAPI) => {
    try {
      const response = await getOwnUser()
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const readOtherUser = createAsyncThunk(
  READ_OTHER_USER,
  async (payload: string, thunkAPI) => {
    try {
      const response = await getUserByUsername(payload)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const listUsers = createAsyncThunk(
  LIST_USERS,
  async (payload: { limit: number, lastIndex: string | null, search: string }, thunkAPI) => {
    try {
      const response = await _listUsers(payload.limit, payload.lastIndex, payload.search)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const uploadProfileImage = createAsyncThunk(
  UPLOAD_PROFILE_IMAGE,
  async ({ imageKey, file }: { imageKey: string, file: File }, thunkAPI) => {
    try {
      const { url: signedUrlResponse } = await uploadFile(imageKey)
      await fetch(signedUrlResponse, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
      return imageKey
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const readProfileImage = createAsyncThunk(
  READ_PROFILE_IMAGE,
  async (imageKey: string, thunkAPI) => {
    try {
      const { url } = await readFile(imageKey)
      return { url }
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const updateUser = createAsyncThunk(
  UPDATE_MY_USER_ATTRIBUTES,
  async (payload: any, thunkAPI) => {
    try {
      const response = await updateOwnUser(payload)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const updatePassword = createAsyncThunk(
  UPDATE_PASSWORD,
  async ({currentPassword, newPassword}: {currentPassword: string, newPassword: string}, thunkAPI) => {
    try {
      const response = await _updatePassword(currentPassword, newPassword)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

interface UserState {
  loadingUsers: boolean;
  loading: boolean;
  error: string | null;
  currentUser: any;
  updateLoading: boolean;
  loadingOtherUser: boolean;
}

const initialState: UserState = {
  loadingUsers: false,
  loading: false,
  error: null,
  currentUser: null,
  updateLoading: false,
  loadingOtherUser: false
};
const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    [SET_CURRENT_USER_PROFILE]: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(readMyUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.currentUser = action.payload
        state.loading = false;
        state.error = null;
      })
      .addCase(readMyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(readMyUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(readOtherUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.currentUser = action.payload
        state.loadingOtherUser = false;
        state.error = null;
      })
      .addCase(readOtherUser.pending, (state) => {
        state.loadingOtherUser = true;
        state.error = null;
      })
      .addCase(readOtherUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingOtherUser = false;
      })
      .addCase(listUsers.fulfilled, (state) => {
        state.loadingUsers = false;
        state.error = null;
      })
      .addCase(listUsers.pending, (state) => {
        state.loadingUsers = true;
        state.error = null;
      })
      .addCase(listUsers.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingUsers = false;
      })
      .addCase(uploadProfileImage.fulfilled, () => {})
      .addCase(uploadProfileImage.pending, () => {})
      .addCase(uploadProfileImage.rejected, () => {})
      .addCase(readProfileImage.fulfilled, () => {})
      .addCase(readProfileImage.pending, () => {})
      .addCase(readProfileImage.rejected, () => {})
      .addCase(updateUser.fulfilled, (state) => {
        state.updateLoading = false;
        state.error = null;
      })
      .addCase(updateUser.pending, (state) => {
        state.updateLoading = true;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.updateLoading = false;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.updateLoading = false;
        state.error = null;
      })
      .addCase(updatePassword.pending, (state) => {
        state.updateLoading = true;
        state.error = null;
      })
      .addCase(updatePassword.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.updateLoading = false;
      })
  }
})

export const actions = slice.actions
export default slice.reducer
