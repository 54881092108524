enum DeviceState {
	NOT_READY_FOR_START = 0,
	INHIBIT_START = 1,
	READY_FOR_SWITCH_ON = 2,
	SWITCHED_ON = 3,
	OPERATION_RELEASED = 4,
	DISABLE_OPERATION_ACTIVE = 5,
	CLOSE_DOWN_ACTIVE = 6,
	QUICK_STOP_ACTIVE = 7,
	ERROR = 15,
	ERROR_REACTION_ACTIVE = 15
}

export function getDeviceState(input: number): DeviceState {
	let binaryInput = input.toString(2).padStart(8, '0'); // Convert to binary and pad to 8 bits
	binaryInput = binaryInput.slice(0, 4) + " " + binaryInput.slice(4)

	if (/^[01]0[01][01] 1111$/.test(binaryInput)) return DeviceState.ERROR_REACTION_ACTIVE;
	if (/^[01]0[01][01] 1000$/.test(binaryInput)) return DeviceState.ERROR;
	if (/^[01]0[01]0 0000$/.test(binaryInput)) return DeviceState.NOT_READY_FOR_START;
	if (/^[01]1[01][01] 0000$/.test(binaryInput)) return DeviceState.INHIBIT_START;
	if (/^[01]01[01] 0001$/.test(binaryInput)) return DeviceState.READY_FOR_SWITCH_ON;
	if (/^[01]011 0011$/.test(binaryInput)) return DeviceState.SWITCHED_ON;
	if (/^[01]011 0111$/.test(binaryInput)) return DeviceState.DISABLE_OPERATION_ACTIVE;
	if (/^[01]011 0111$/.test(binaryInput)) return DeviceState.OPERATION_RELEASED;
	if (/^[01]011 0111$/.test(binaryInput)) return DeviceState.CLOSE_DOWN_ACTIVE;

	return DeviceState.NOT_READY_FOR_START;
}

interface ErrorBit {
	bit: number;
	description: string;
	alarm: number;
	type: "error" | "warning"; //Falha e aviso
}

interface ErrorMessages {
	[key: string]: ErrorBit[];
}

const errorDefinitions: ErrorMessages = {
	// Parâmetro 200
	"Drivemanager.BM_d_SysError1": [
		{ bit: 0, description: "Watchdog-Erro", alarm: 200, type: "error" },
		{ bit: 1, description: "Interrupção falsa ou inesperada ocorreu", alarm: 200, type: "error" },
		{ bit: 2, description: "Interrupção NMI ocorreu - acesso de barramento falso", alarm: 200, type: "error" },
	],
	// Parâmetro 201
	"Drivemanager.BM_w_ProcessorSysError": [
		{ bit: 0, description: "Watchdog-Erro", alarm: 201, type: "error" },
		{ bit: 1, description: "Interrupção falsa ou inesperada ocorreu", alarm: 201, type: "error" },
		{ bit: 2, description: "Interrupção NMI ocorreu - acesso de barramento falso", alarm: 201, type: "error" },
		{ bit: 3, description: "Erro de inicialização do sistema", alarm: 201, type: "error" },
	],
	// Parâmetro 202
	"Drivemanager.BM_w_OperatingSysError": [
		{ bit: 0, description: "Erro de inicialização do sistema", alarm: 202, type: "error" },
		{ bit: 1, description: "Erro de software (entre outros interruptores)", alarm: 202, type: "error" },
		{ bit: 2, description: "Erro na configuração do sistema de operação de time-slice", alarm: 202, type: "error" },
		{ bit: 3, description: "Violação de time slice", alarm: 202, type: "error" },
	],
	// Parâmetro 203
	"Drivemanager.BM_w_ProprogSysError": [
		{ bit: 0, description: "Timeout do protocolo Proprog", alarm: 203, type: "error" },
		{ bit: 1, description: "Erro de protocolo", alarm: 203, type: "error" },
		{ bit: 2, description: "Tipo de módulo errado", alarm: 203, type: "error" },
	],
	// Parâmetro 204
	"Drivemanager.BM_w_ExtendedModuleError": [
		{ bit: 0, description: "Erro no módulo de função A", alarm: 204, type: "error" },
		{ bit: 1, description: "Erro no módulo de função B", alarm: 204, type: "error" },
		{ bit: 2, description: "Erro no módulo de função C", alarm: 204, type: "error" },
		{ bit: 3, description: "Erro no módulo de função D", alarm: 204, type: "error" },
		{ bit: 4, description: "Erro no módulo de função E", alarm: 204, type: "error" },
		{ bit: 5, description: "Erro no módulo de opção G", alarm: 204, type: "error" },
		{ bit: 6, description: "Erro no módulo de opção H", alarm: 204, type: "error" },
		{ bit: 7, description: "Erro no módulo de opção J", alarm: 204, type: "error" },
		{ bit: 8, description: "Erro no módulo de opção K", alarm: 204, type: "error" },
		{ bit: 9, description: "Erro no módulo de opção L", alarm: 204, type: "error" },
		{ bit: 10, description: "Erro no módulo de opção M", alarm: 204, type: "error" },
	],
	// Parâmetro 205
	"Drivemanager.BM_w_PSU_SysError": [
		{ bit: 0, description: "Falha de rede (net fail)", alarm: 205, type: "error" },
		{ bit: 1, description: "Falha de fase", alarm: 205, type: "error" },
		{ bit: 2, description: "Subtensão de entrada de rede", alarm: 205, type: "error" },
	],
	// Parâmetro 206
	"Drivemanager.BM_w_AmpSysError": [
		{ bit: 0, description: "Erro de comunicação de acordo com a especificação hiperface", alarm: 206, type: "error" },
		{ bit: 1, description: "Limite de temperatura do dissipador de calor excedido", alarm: 206, type: "error" },
		{ bit: 2, description: "Sobretensão do elo CC", alarm: 206, type: "error" },
		{ bit: 3, description: "Sobrecorrente", alarm: 206, type: "error" },
		{ bit: 4, description: "Corrente de terra", alarm: 206, type: "error" },
		{ bit: 5, description: "Limite de temperatura do ar interno excedido", alarm: 206, type: "error" },
		{ bit: 6, description: "Cabo do sensor de temperatura do dissipador rompido", alarm: 206, type: "error" },
		{ bit: 7, description: "Relé de segurança desligado (ou com defeito)", alarm: 206, type: "error" },
		{ bit: 8, description: "Fim de jumper", alarm: 206, type: "error" },
		{ bit: 9, description: "Unidade de potência não pronta para operar", alarm: 206, type: "error" },
		{ bit: 10, description: "Falha de fase", alarm: 206, type: "error" },
	],
	// Parâmetro 207
	"Drivemanager.BM_w_MotorSysError": [
		{ bit: 0, description: "Falha de rede", alarm: 207, type: "error" },
		{ bit: 1, description: "Subtensão de entrada de rede", alarm: 207, type: "error" },
		{ bit: 2, description: "Sobretensão de entrada de rede", alarm: 207, type: "error" },
		{ bit: 3, description: "Subtensão do Uzk", alarm: 207, type: "error" },
		{ bit: 4, description: "Curto-circuito no sensor de temperatura", alarm: 207, type: "error" },
		{ bit: 5, description: "Sensor de temperatura do motor não conectado", alarm: 207, type: "error" },
		{ bit: 6, description: "Limite de temperatura do motor excedido", alarm: 207, type: "error" },
		{ bit: 7, description: "Erro I2t > 100%", alarm: 207, type: "error" },
	],
	// Parâmetro 208
	"Drivemanager.BM_w_Enc1SysError": [
		{ bit: 0, description: "Erro de comunicação (especificação hiperface)", alarm: 208, type: "error" },
		{ bit: 1, description: "Código de módulo inválido", alarm: 208, type: "error" },
		{ bit: 2, description: "Cabo do encoder 1 rompido", alarm: 208, type: "error" },
		{ bit: 3, description: "Cabo do encoder 2 rompido", alarm: 208, type: "error" },
		{ bit: 4, description: "Sobrecarga do Encoder 1", alarm: 208, type: "error" },
		{ bit: 5, description: "Limite permitido excedido", alarm: 208, type: "error" },
		{ bit: 6, description: "Tipo de encoder desconhecido", alarm: 208, type: "error" },
		{ bit: 7, description: "Campo de dados inválido para dados do motor", alarm: 208, type: "error" },
		{ bit: 8, description: "Dados do motor inválidos", alarm: 208, type: "error" },
		{ bit: 9, description: "Erro ao armazenar dados do motor", alarm: 208, type: "error" },
		{ bit: 10, description: "Dados do motor protegidos contra gravação", alarm: 208, type: "error" },
		{ bit: 11, description: "Erro de ângulo de campo", alarm: 208, type: "error" },
		{ bit: 12, description: "Encoder sem medição de temperatura", alarm: 208, type: "error" },
	],
	// Parâmetro 209
	"Drivemanager.BM_w_Enc2SysError": [
		{ bit: 0, description: "Erro de comunicação (especificação hiperface)", alarm: 209, type: "error" },
		{ bit: 1, description: "Código de módulo inválido", alarm: 209, type: "error" },
		{ bit: 2, description: "Cabo do encoder 2 rompido", alarm: 209, type: "error" },
		{ bit: 3, description: "Sobrecarga do Encoder 2", alarm: 209, type: "error" },
		{ bit: 4, description: "Limite permitido excedido", alarm: 209, type: "error" },
		{ bit: 5, description: "Tipo de encoder desconhecido", alarm: 209, type: "error" },
		{ bit: 6, description: "Campo de dados inválido para dados do motor", alarm: 209, type: "error" },
		{ bit: 7, description: "Dados do motor inválidos", alarm: 209, type: "error" },
		{ bit: 8, description: "Erro ao armazenar dados do motor", alarm: 209, type: "error" },
		{ bit: 9, description: "Dados do motor protegidos contra gravação", alarm: 209, type: "error" },
		{ bit: 10, description: "Erro de ângulo de campo", alarm: 209, type: "error" },
		{ bit: 11, description: "Encoder sem medição de temperatura", alarm: 209, type: "error" },
	],
	// Parâmetro 210
	"Drivemanager.BM_w_EncManagSysError": [
		{ bit: 0, description: "Posição absoluta do encoder 1 para controle do motor é desconhecida", alarm: 210, type: "error" },
		{ bit: 1, description: "Posição absoluta do encoder 2 para controle do motor é desconhecida", alarm: 210, type: "error" },
	],
	// Parâmetro 211
	"Drivemanager.BM_w_DriveManagSysError": [
		{ bit: 0, description: "Opção 'Start after the first zero impulse' selecionada para encoder não incremental", alarm: 211, type: "error" },
		{ bit: 1, description: "Módulo de emulação de encoder SSI é necessário e está faltando", alarm: 211, type: "error" },
		{ bit: 2, description: "Erro na fonte de setpoint do encoder 1 e do encoder 2", alarm: 211, type: "error" },
	],
	// Parâmetro 212
	"Drivemanager.BM_w_DataRecSysError": [
		{ bit: 0, description: "Erro de cópia do EEPROM", alarm: 212, type: "error" },
		{ bit: 1, description: "Timeout de gravação do EEPROM", alarm: 212, type: "error" },
		{ bit: 2, description: "Erro de checksum do EEPROM", alarm: 212, type: "error" },
		{ bit: 3, description: "Nenhum conjunto de dados de inicialização", alarm: 212, type: "error" },
		{ bit: 4, description: "Software incompatível", alarm: 212, type: "error" },
		{ bit: 5, description: "Conjunto de dados inexistente", alarm: 212, type: "error" },
	],
	// Parâmetro 213
	"Drivemanager.BM_w_PosCtrlSysError": [
		{ bit: 0, description: "Desvio de posição dinâmico", alarm: 213, type: "error" },
		{ bit: 1, description: "Desvio de posição estático", alarm: 213, type: "error" },
		{ bit: 2, description: "Encoder 1 para controle de posição usado, mas não ativo", alarm: 213, type: "error" },
		{ bit: 3, description: "Encoder 2 para controle de posição usado, mas não ativo", alarm: 213, type: "error" },
		{ bit: 4, description: "Monitoramento do interruptor de limite de software 1 ativo", alarm: 213, type: "error" },
		{ bit: 5, description: "Monitoramento do interruptor de limite de software 2 ativo", alarm: 213, type: "error" },
		{ bit: 6, description: "Monitoramento do interruptor de limite de hardware 1 ativo", alarm: 213, type: "error" },
		{ bit: 7, description: "Monitoramento do interruptor de limite de hardware 2 ativo", alarm: 213, type: "error" },
	],
	// Parâmetro 214
	"Drivemanager.BM_w_SpeedCtrlSysError": [
		{ bit: 0, description: "Homing necessário e ainda não executado", alarm: 214, type: "error" },
		{ bit: 1, description: "O valor de setpoint no modo Set-of-setpoints não chegou a tempo", alarm: 214, type: "error" },
	],
	// Parâmetro 215
	"Drivemanager.BM_w_FreeCtrlSecSysError": [
		{ bit: 0, description: "Drive bloqueado", alarm: 215, type: "error" },
		{ bit: 1, description: "O encoder 1 está parametrizado como encoder para o controle do motor, mas a avaliação não está ativada", alarm: 215, type: "error" },
	],
	// Parâmetro 216
	"Drivemanager.BM_w_CANsyncError": [
		{ bit: 0, description: "Timeout Proprog (comunicação)", alarm: 216, type: "error" },
		{ bit: 1, description: "Timeout BACI (comunicação)", alarm: 216, type: "error" },
	],
	// PARÂMETRO 233
	"Drivemanager.BM_w_AmpHiperfaceError": [
		{ bit: 0, description: "Erro de comunicação (especificação hiperface)", alarm: 233, type: "error" },
		{ bit: 1, description: "Código de módulo inválido", alarm: 233, type: "error" },
	],
	// PARÂMETRO 234
	"Drivemanager.BM_w_Enc1SerComError": [
		{ bit: 0, description: "Erro de comunicação (especificação hiperface)", alarm: 234, type: "error" },
		{ bit: 1, description: "Código de módulo inválido", alarm: 234, type: "error" },
	],
	// PARÂMETRO 235
	"Drivemanager.BM_w_Enc2SerComError": [
		{ bit: 0, description: "Erro de comunicação (especificação hiperface)", alarm: 235, type: "error" },
		{ bit: 1, description: "Código de módulo inválido", alarm: 235, type: "error" },
	],
	// PARÂMETRO 236
	"Drivemanager.BM_w_MainsMonitorError": [
		{ bit: 0, description: "Erro de comunicação", alarm: 236, type: "error" },
		{ bit: 1, description: "Erro de protocolo", alarm: 236, type: "error" },
	],
	// PARÂMETRO 240
	"Drivemanager.BM_w_SmallModuleErrorA": [
		{ bit: 0, description: "Erro no módulo de função A", alarm: 240, type: "error" },
	],
	// PARÂMETRO 241
	"Drivemanager.BM_w_SmallModuleErrorB": [
		{ bit: 0, description: "Erro no módulo de função B", alarm: 241, type: "error" },
	],
	// PARÂMETRO 242
	"Drivemanager.BM_w_SmallModuleErrorC": [
		{ bit: 0, description: "Erro no módulo de função C", alarm: 242, type: "error" },
	],
	// PARÂMETRO 243
	"Drivemanager.BM_w_SmallModuleErrorD": [
		{ bit: 0, description: "Erro no módulo de função D", alarm: 243, type: "error" },
	],
	// PARÂMETRO 244
	"Drivemanager.BM_w_SmallModuleErrorE": [
		{ bit: 0, description: "Erro no módulo de função E", alarm: 244, type: "error" },
	],
	// PARÂMETRO 245
	"Drivemanager.BM_w_BigModuleErrorG": [
		{ bit: 0, description: "Erro no módulo de opção G", alarm: 245, type: "error" },
	],
	// PARÂMETRO 246
	"Drivemanager.BM_w_BigModuleErrorH": [
		{ bit: 0, description: "Erro no módulo de opção H", alarm: 246, type: "error" },
	],
	// PARÂMETRO 247
	"Drivemanager.BM_w_BigModuleErrorJ": [
		{ bit: 0, description: "Erro no módulo de opção J", alarm: 247, type: "error" },
	],
	// PARÂMETRO 248
	"Drivemanager.BM_w_BigModuleErrorK": [
		{ bit: 0, description: "Erro no módulo de opção K", alarm: 248, type: "error" },
	],
	// PARÂMETRO 249
	"Drivemanager.BM_w_BigModuleErrorL": [
		{ bit: 0, description: "Erro no módulo de opção L", alarm: 249, type: "error" },
	],
	// PARÂMETRO 250
	"Drivemanager.BM_w_BigModuleErrorM": [
		{ bit: 0, description: "Erro no módulo de opção M", alarm: 250, type: "error" },
	],
	// PARÂMETRO 251
	"Drivemanager.BM_u_ProprogCmdErrId": [
		{ bit: 0, description: "Erro no parâmetro de comunicação", alarm: 251, type: "error" },
	],
	// PARÂMETRO 260
	"Drivemanager.BM_w_SysWarning1": [
		{ bit: 0, description: "Aviso do sistema 1", alarm: 260, type: "warning" },
	],
	// PARÂMETRO 261
	"Drivemanager.BM_w_PSU_Warning": [
		{ bit: 0, description: "Aviso na unidade de fonte de alimentação", alarm: 261, type: "warning" },
	],
	// PARÂMETRO 262
	"Drivemanager.BM_w_AmpWarning": [
		{ bit: 0, description: "Aviso na unidade de potência", alarm: 262, type: "warning" },
	],
	// PARÂMETRO 263
	"Drivemanager.BM_w_MotorWarning": [
		{ bit: 0, description: "Aviso no motor", alarm: 263, type: "warning" },
	],
	// PARÂMETRO 264
	"Drivemanager.BM_w_DriveWarning": [
		{ bit: 0, description: "Avisos globais do drive", alarm: 264, type: "warning" },
	],
	// PARÂMETRO 265
	"Drivemanager.BM_w_MainsMonitorWarning": [
		{ bit: 0, description: "Aviso no monitor de rede", alarm: 265, type: "warning" },
	],
	// PARÂMETRO 300
	"Drivemanager.BM_w_Controlword": [
		{ bit: 0, description: "Controle de palavra", alarm: 300, type: "warning" },
	],
	// PARÂMETRO 301
	"Drivemanager.BM_w_Statusword": [
		{ bit: 0, description: "Status da palavra", alarm: 301, type: "warning" },
	],
	// PARÂMETRO 303
	"Drivemanager.BM_w_Statusword2": [
		{ bit: 0, description: "Status da palavra 2", alarm: 303, type: "warning" },
	],
	// PARÂMETRO 304
	"Drivemanager.BM_i_OperationModeAct": [
		{ bit: 0, description: "Modo de operação atual", alarm: 304, type: "warning" },
	],
	// PARÂMETRO 305
	"Drivemanager.BM_w_DriveStatus": [
		{ bit: 0, description: "Status do drive", alarm: 305, type: "warning" },
	],
	// PARÂMETRO 306
	"Drivemanager.BM_w_DI_StatusDrvControl": [
		{ bit: 0, description: "Status do controle de drive", alarm: 306, type: "warning" },
	],
	// PARÂMETRO 308
	"Drivemanager.BM_w_Statusword3": [
		{ bit: 0, description: "Status da palavra 3", alarm: 308, type: "warning" },
	],
	// PARÂMETRO 461
	"Drivemanager.BM_w_PPosSwitchStatus": [
		{ bit: 0, description: "Status do interruptor de posição", alarm: 461, type: "warning" },
	],
	// PARÂMETRO 1000
	"Drivemanager.BM_i_Ds0_OperationMode": [
		{ bit: 0, description: "Modo de operação DS0", alarm: 1000, type: "warning" },
	],
};


interface ErrorResult {
	key: string;
	bit: number;
	description: string;
	alarm: string;
	type: string;
}

export function getErrorMessages(errorData: { [key: string]: number }): ErrorResult[] {
	const result: ErrorResult[] = [];
	for (const key in errorData) {
		if (errorData.hasOwnProperty(key) && errorDefinitions.hasOwnProperty(key)) {
			const value = errorData[key];
			const binaryString = value.toString(2).padStart(16, "0").split("").reverse().join("");
			const errors = errorDefinitions[key];
			for (let i = 0; i < binaryString.length; i++) {
				if (binaryString[i] === "1") {
					const error = errors.find(error => error.bit === i);
					if (error) {
						result.push({
							key,
							bit: i,
							description: error.description,
							alarm: `${error.alarm}`,
							type: error.type
						});
					}
				}
			}
		}
	}
	return result;
}