import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CREATE_SYSTEM_USER, DELETE_SYSTEM_USER, LIST_SYSTEM_USERS, READ_SYSTEM_USER, READ_USERS_IMAGE, UPDATE_SYSTEM_USER, UPLOAD_USERS_IMAGE, USERS_RESET } from "../../app/actionTypes"
import {
  createUser as _createUser,
  listUsers as _listUsers,
  updateUserByUsername,
  getUserByUsername,
  deleteUserByUsername,
  uploadFile,
  readFile,
} from "../../services/backendAPI"

export const createUser = createAsyncThunk(
  CREATE_SYSTEM_USER,
  async (payload: any, thunkAPI) => {
    try {
      const response = await _createUser(payload)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const listUsers = createAsyncThunk(
  LIST_SYSTEM_USERS,
  async (payload: { limit: number, lastIndex: string | null, search: string }, thunkAPI) => {
    try {
      const response = await _listUsers(payload.limit, payload.lastIndex, payload.search)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const readUser = createAsyncThunk(
  READ_SYSTEM_USER,
  async (payload: string, thunkAPI) => {
    try {
      const response = await getUserByUsername(payload)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const updateUser = createAsyncThunk(
  UPDATE_SYSTEM_USER,
  async (payload: { username: string, userData: any }, thunkAPI) => {
    try {
      const response = await updateUserByUsername(payload.username, payload.userData)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const deleteUser = createAsyncThunk(
  DELETE_SYSTEM_USER,
  async (payload: string, thunkAPI) => {
    try {
      const response = await deleteUserByUsername(payload)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const uploadProfileImage = createAsyncThunk(
  UPLOAD_USERS_IMAGE,
  async ({ imageKey, file }: { imageKey: string, file: File }, thunkAPI) => {
    try {
      const { url: signedUrlResponse } = await uploadFile(imageKey)
      await fetch(signedUrlResponse, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
      return imageKey
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const readProfileImage = createAsyncThunk(
  READ_USERS_IMAGE,
  async (imageKey: string, thunkAPI) => {
    try {
      const { url } = await readFile(imageKey)
      return { url }
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

interface UserState {
  loadingUsers: boolean;
  loading: boolean;
  deletingUser: boolean;
  error: string | null;
  currentUser: any;
  readingUser: boolean;
  creatingUser: boolean;
  updatingUser: boolean;
}

const initialState: UserState = {
  loadingUsers: false,
  loading: false,
  deletingUser: false,
  error: null,
  currentUser: null,
  readingUser: false,
  creatingUser: false,
  updatingUser: false
};
const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    [USERS_RESET]: (state) => {
      state.loading = false;
      state.loadingUsers = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state) => {
        state.creatingUser = false;
        state.error = null;
      })
      .addCase(createUser.pending, (state) => {
        state.creatingUser = true;
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.creatingUser = false;
      })
      .addCase(listUsers.fulfilled, (state) => {
        state.loadingUsers = false;
        state.error = null;
      })
      .addCase(listUsers.pending, (state) => {
        state.loadingUsers = true;
        state.error = null;
      })
      .addCase(listUsers.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingUsers = false;
      })
      .addCase(readUser.fulfilled, (state) => {
        state.readingUser = false;
        state.error = null;
      })
      .addCase(readUser.pending, (state) => {
        state.readingUser = true;
        state.error = null;
      })
      .addCase(readUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.readingUser = false;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.updatingUser = false;
        state.error = null;
      })
      .addCase(updateUser.pending, (state) => {
        state.updatingUser = true;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.updatingUser = false;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.deletingUser = false;
        state.error = null;
      })
      .addCase(deleteUser.pending, (state) => {
        state.deletingUser = true;
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.deletingUser = false;
      })
      .addCase(uploadProfileImage.fulfilled, () => { })
      .addCase(uploadProfileImage.pending, () => { })
      .addCase(uploadProfileImage.rejected, () => { })
      .addCase(readProfileImage.fulfilled, () => { })
      .addCase(readProfileImage.pending, () => { })
      .addCase(readProfileImage.rejected, () => { })
  }
})

export const actions = slice.actions
export default slice.reducer
