import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_DEVICE,
  LIST_DEVICES,
  READ_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  CREATE_OWN_DEVICE,
  LIST_OWN_DEVICES,
  READ_OWN_DEVICE,
  UPDATE_OWN_DEVICE,
  DELETE_OWN_DEVICE,
  DEVICES_RESET,
  UPLOAD_DEVICE_IMAGE,
  READ_DEVICE_IMAGE,
} from "../../app/actionTypes";
import {
  createDevice as _createDevice,
  listDevices as _listDevices,
  updateDevice as _updateDevice,
  readDevice as _readDevice,
  deleteDevice as _deleteDevice,
  createOwnDevice as _createOwnDevice,
  listOwnDevices as _listOwnDevices,
  updateOwnDevice as _updateOwnDevice,
  readOwnDevice as _readOwnDevice,
  deleteOwnDevice as _deleteOwnDevice,
  uploadFile,
  readFile,
} from "../../services/backendAPI";

export const createDevice = createAsyncThunk(
  CREATE_DEVICE,
  async (payload: any, thunkAPI) => {
    try {
      const response = await _createDevice(payload);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const listDevices = createAsyncThunk(
  LIST_DEVICES,
  async (payload: { userId: string, limit: number; lastIndex: string | null; search: string }, thunkAPI) => {
    try {
      const response = await _listDevices(payload.userId, payload.limit, payload.lastIndex, payload.search);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const readDevice = createAsyncThunk(
  READ_DEVICE,
  async (payload: {userId: string, deviceId: string}, thunkAPI) => {
    try {
      const response = await _readDevice(payload.userId, payload.deviceId);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const updateDevice = createAsyncThunk(
  UPDATE_DEVICE,
  async (payload: { userId: string, deviceId: string, deviceData: any }, thunkAPI) => {
    try {
      const response = await _updateDevice(payload.userId, payload.deviceId, payload.deviceData);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const deleteDevice = createAsyncThunk(
  DELETE_DEVICE,
  async (payload: {userId: string, deviceId: string}, thunkAPI) => {
    try {
      const response = await _deleteDevice(payload.userId, payload.deviceId);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const createOwnDevice = createAsyncThunk(
  CREATE_OWN_DEVICE,
  async (payload: any, thunkAPI) => {
    try {
      const response = await _createOwnDevice(payload);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const listOwnDevices = createAsyncThunk(
  LIST_OWN_DEVICES,
  async (payload: { limit: number; lastIndex: string | null; search: string }, thunkAPI) => {
    try {
      const response = await _listOwnDevices(payload.limit, payload.lastIndex, payload.search);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const readOwnDevice = createAsyncThunk(
  READ_OWN_DEVICE,
  async (payload: string, thunkAPI) => {
    try {
      const response = await _readOwnDevice(payload);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const updateOwnDevice = createAsyncThunk(
  UPDATE_OWN_DEVICE,
  async (payload: { deviceId: string; deviceData: any }, thunkAPI) => {
    try {
      const response = await _updateOwnDevice(payload.deviceId, payload.deviceData);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const deleteOwnDevice = createAsyncThunk(
  DELETE_OWN_DEVICE,
  async (payload: string, thunkAPI) => {
    try {
      const response = await _deleteOwnDevice(payload);
      return response;
    } catch (error: any) {
      const errorResponse = error.response?.data;
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
);

export const uploadDeviceImage = createAsyncThunk(
  UPLOAD_DEVICE_IMAGE,
  async ({ imageKey, file }: { imageKey: string, file: File }, thunkAPI) => {
    try {
      const { url: signedUrlResponse } = await uploadFile(imageKey)
      await fetch(signedUrlResponse, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
      return imageKey
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const readDeviceImage = createAsyncThunk(
  READ_DEVICE_IMAGE,
  async (imageKey: string, thunkAPI) => {
    try {
      const { url } = await readFile(imageKey)
      return { url }
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

interface DeviceState {
  loadingDevices: boolean;
  loading: boolean;
  deletingDevice: boolean;
  error: string | null;
  currentDevice: any;
  readingDevice: boolean;
  creatingDevice: boolean;
  updatingDevice: boolean;
  uploadingDeviceImage: boolean;
}

const initialState: DeviceState = {
  loadingDevices: false,
  loading: false,
  deletingDevice: false,
  error: null,
  currentDevice: null,
  readingDevice: false,
  creatingDevice: false,
  updatingDevice: false,
  uploadingDeviceImage: false
};

const slice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    [DEVICES_RESET]: (state) => {
      state.loadingDevices = false;
      state.readingDevice = false;
      state.creatingDevice = false;
      state.updatingDevice = false;
      state.deletingDevice = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDevice.fulfilled, (state) => {
        state.creatingDevice = false;
        state.error = null;
      })
      .addCase(createDevice.pending, (state) => {
        state.creatingDevice = true;
        state.error = null;
      })
      .addCase(createDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.creatingDevice = false;
      })
      .addCase(listDevices.fulfilled, (state) => {
        state.loadingDevices = false;
        state.error = null;
      })
      .addCase(listDevices.pending, (state) => {
        state.loadingDevices = true;
        state.error = null;
      })
      .addCase(listDevices.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingDevices = false;
      })
      .addCase(readDevice.fulfilled, (state) => {
        state.readingDevice = false;
        state.error = null;
      })
      .addCase(readDevice.pending, (state) => {
        state.readingDevice = true;
        state.error = null;
      })
      .addCase(readDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.readingDevice = false;
      })
      .addCase(updateDevice.fulfilled, (state) => {
        state.updatingDevice = false;
        state.error = null;
      })
      .addCase(updateDevice.pending, (state) => {
        state.updatingDevice = true;
        state.error = null;
      })
      .addCase(updateDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.updatingDevice = false;
      })
      .addCase(deleteDevice.fulfilled, (state) => {
        state.deletingDevice = false;
        state.error = null;
      })
      .addCase(deleteDevice.pending, (state) => {
        state.deletingDevice = true;
        state.error = null;
      })
      .addCase(deleteDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.deletingDevice = false;
      })
      .addCase(createOwnDevice.fulfilled, (state) => {
        state.creatingDevice = false;
        state.error = null;
      })
      .addCase(createOwnDevice.pending, (state) => {
        state.creatingDevice = true;
        state.error = null;
      })
      .addCase(createOwnDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.creatingDevice = false;
      })
      .addCase(listOwnDevices.fulfilled, (state) => {
        state.loadingDevices = false;
        state.error = null;
      })
      .addCase(listOwnDevices.pending, (state) => {
        state.loadingDevices = true;
        state.error = null;
      })
      .addCase(listOwnDevices.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingDevices = false;
      })
      .addCase(readOwnDevice.fulfilled, (state) => {
        state.readingDevice = false;
        state.error = null;
      })
      .addCase(readOwnDevice.pending, (state) => {
        state.readingDevice = true;
        state.error = null;
      })
      .addCase(readOwnDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.readingDevice = false;
      })
      .addCase(updateOwnDevice.fulfilled, (state) => {
        state.updatingDevice = false;
        state.error = null;
      })
      .addCase(updateOwnDevice.pending, (state) => {
        state.updatingDevice = true;
        state.error = null;
      })
      .addCase(updateOwnDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.updatingDevice = false;
      })
      .addCase(deleteOwnDevice.fulfilled, (state) => {
        state.deletingDevice = false;
        state.error = null;
      })
      .addCase(deleteOwnDevice.pending, (state) => {
        state.deletingDevice = true;
        state.error = null;
      })
      .addCase(deleteOwnDevice.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.deletingDevice = false;
      })
      .addCase(uploadDeviceImage.fulfilled, (state) => {
        state.uploadingDeviceImage = false;
        state.error = null;
      })
      .addCase(uploadDeviceImage.pending, (state) => {
        state.uploadingDeviceImage = true;
        state.error = null;
      })
      .addCase(uploadDeviceImage.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.uploadingDeviceImage = false;
      })
      .addCase(readDeviceImage.fulfilled, (state) => {
        state.uploadingDeviceImage = false;
        state.error = null;
      })
      .addCase(readDeviceImage.pending, (state) => {
        state.uploadingDeviceImage = true;
        state.error = null;
      })
      .addCase(readDeviceImage.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.uploadingDeviceImage = false;
      })
  }
});

export const actions = slice.actions;
export default slice.reducer;
