import React, { PropsWithChildren, useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { actions, listUsers, readOtherUser, readProfileImage, updatePassword, updateUser, uploadProfileImage } from "./slice"
import FormField from "../../components/FormField"
import LoadingButton from "../../components/LoadingButton"
import CustomAutoComplete from "../../components/CustomAutoComplete"
import { SET_CURRENT_USER_PROFILE, UPDATE_LOGGED_USER_ATTRIBUTES } from "../../app/actionTypes"
import { actions as commonActions } from '../../app/commonSlice';
import ImageFormField from "../../components/ImageFormField"
import { v4 as uuidv4 } from 'uuid';
import { maskCpfCNPJ, maskPhone, unmaskNumber } from "../../libs/masks"
import Alert from "../../components/Alert"

interface Props extends PropsWithChildren { }
type ProfileFormData = {
	name: string;
	email: string;
	document: string;
	phone: string;
	password: string;
	newPassword: string;
	code: string;
	currentPassword: string;
};

const validation = (values: any, key: keyof ProfileFormData | "all"): { name: string, errors: Array<{ isValidated: boolean, message: string }> } => {
	const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);
	const isCPFValid = (cpf: string) => /^\d{11}$/.test(cpf);
	const isCNPJValid = (cnpj: string) => /^\d{14}$/.test(cnpj);
	const isPhoneValid = (phone: string) => /^\+\d{13}$/.test(phone);

	switch (key) {
		case 'name':
			return ({
				name: "Nome",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					}
				]
			});
		case 'email':
			return ({
				name: "Email",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: isEmailValid(values[key]),
						message: "Formato de e-mail inválido"
					}
				]
			});
		case 'document':
			return ({
				name: "CPF ou CNPJ",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: isCPFValid(values[key]) || isCNPJValid(values[key]),
						message: "Formato de CPF ou CNPJ inválido"
					}
				]
			});
		case 'phone':
			return ({
				name: "Celular",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: isPhoneValid(values[key]),
						message: "Formato de celular inválido. Use o formato xx xxxxxxxxx"
					}
				]
			});
		case 'password':
			return ({
				name: "Senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length >= 8,
						message: "A senha deve ter ao menos 8 caracteres"
					}
				]
			});
		case 'newPassword':
			return ({
				name: "Nova senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length >= 8,
						message: "A senha deve ter ao menos 8 caracteres"
					}
				]
			});
		case 'currentPassword':
			return ({
				name: "Senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length >= 8,
						message: "A senha deve ter ao menos 8 caracteres"
					}
				]
			});
		case 'code':
			return ({
				name: "Código de confirmação",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length === 6,
						message: "O código de verificação deve ter 6 caracteres"
					}
				]
			});

		case 'all':
			let errors: any = [];
			for (let _key of Object.keys(values) as Array<keyof ProfileFormData>) {
				const validations = validation(values, _key);
				const newErrors = validations.errors.filter((error: any) => !error.isValidated);
				errors = [...errors, ...newErrors.map((error) => ({ ...error, message: `${validations.name}: ${error.message}` }))];
			}
			return {
				name: "Validação dos campos",
				errors
			};
		default:
			return {
				name: "",
				errors: []
			};
	}
};


function Profile(_: Props) {
	const appDispatch = useAppDispatch()
	const { currentUser, updateLoading } = useAppSelector((state: RootState) => state.profileReducer)
	const { user, groups }: any = useAppSelector(
		(state: RootState) => state.commonReducer,
	)
	useEffect(() => {
		if (!currentUser) {
			appDispatch(actions[SET_CURRENT_USER_PROFILE](user))
		}
	}, [])

	const [userSearch, setUserSearch] = useState<any>(currentUser || user)
	useEffect(() => {
		if (userSearch) {
			appDispatch((readOtherUser(userSearch.username)))
		}
	}, [userSearch])
	const [formData, setFormData] = useState<any>(user || {})
	const [formDataPassword, setFormDataPassword] = useState<any>({
		currentPassword: "",
		newPassword: ""
	})
	const [showErrors, setShowErrors] = useState<boolean>(false)

	const [errorTitle, setErrorTitle] = useState("")
	const [successTitle, setSuccessTitle] = useState("")

	const handleOnSubmitUpdateAttributes: React.FormEventHandler<HTMLFormElement> = useCallback((e) => {
		e.preventDefault()
		setShowErrors(true)
		const validationResponse = validation(formData, "all")
		if (validationResponse.errors.length) {
			setErrorTitle(validationResponse.errors.map((error) => error.message).join("\n * "))
			return
		} else {
			appDispatch(updateUser({
				name: formData.name,
				email: formData.email,
				phone: formData.phone,
				document: formData.document,
				profileImageKey: formData.profileImageKey
			})).unwrap()
				.then(() => {
					appDispatch(commonActions[UPDATE_LOGGED_USER_ATTRIBUTES]({ profileImageKey: formData.profileImageKey }))
					setSuccessTitle("Atualização de usuário")
				})
				.catch(() => {
					setErrorTitle("Erro ao atualizar usuário, tente novamente")
				})
		}
	}, [formData, validation, updateUser])

	const handleOnSubmitUpdatePassword: React.FormEventHandler<HTMLFormElement> = useCallback((e) => {
		e.preventDefault()
		setShowErrors(true)
		const validationResponse = validation(formDataPassword, "all")
		if (validationResponse.errors.length) {
			setErrorTitle(validationResponse.errors.map((error) => error.message).join("\n * "))
			return
		} else {
			appDispatch(updatePassword(formDataPassword)).unwrap()
				.then(() => {
					setSuccessTitle("Atualização de senha")
				})
				.catch(() => {
					setErrorTitle("Verifique os campos e tente novamente")
				})
		}
	}, [formDataPassword, validation])

	const handleSelectImage = useCallback(async (image: File) => {
		try {
			const imageKey = uuidv4()
			const response = await appDispatch(uploadProfileImage({ imageKey, file: image })).unwrap()
			setFormData({
				...formData,
				profileImageKey: imageKey
			})
			return response
		} catch (error) {
			return;
		}
	}, [formData])
	if (!currentUser) {
		return <></>
	}
	return (
		<main className="space-y-5">
			<div className="max-w-[50rem]">
				<span hidden={!groups.find((group: any) => group.name === "Administradores")}>
					<form className="flex mt-10 flex-wrap gap-10 pb-10" onSubmit={(e) => { e.preventDefault() }} noValidate>
						<label className="w-[38.5rem] border-b-[1px] border-[#706f6f]">Alterar usuário accessado - Usuário autenticado: {user.name}</label>
						<CustomAutoComplete
							containerClassName="min-w-[30rem] sm:min-w-full"
							fetchData={async (search) => {
								const response: any = await appDispatch(listUsers({ limit: 60, lastIndex: null, search })).unwrap()
								return response.results
							}}
							value={userSearch}
							onSelect={(data) => {
								if (data && data.username) {
									setUserSearch(data)
								} else {
									setUserSearch(null)
								}
							}}
							errors={[]}
							showerrors={true}
							dataDisplay={(data) => data && data.username || ""}
							labelNoResult="Nenhum resultado encontrado"
						/>
					</form>
				</span>
				<span hidden={user.username !== currentUser.username}>
					<form className="flex flex-wrap gap-10 sm:px-2 pb-10" onSubmit={handleOnSubmitUpdateAttributes} noValidate>
						<label className="w-[38.5rem] mt-10 border-b-[1px] border-[#706f6f] sm:min-w-full">Atualização de informações da conta</label>
						<FormField
							id="name"
							name="name"
							type="text"
							required
							onChange={(e: any) => setFormData({ ...formData, name: e.target.value })}
							label="Nome"
							labelclassname="block text-sm font-medium leading-6 text-gray-900"
							errorclassname="text-sm text-red-500"
							containerClassName="min-w-[30rem] sm:min-w-full"
							errors={validation(formData, 'name').errors}
							showerrors={!!showErrors}
							value={formData.name}
						/>
						<ImageFormField
							onFileSelect={async (image) => {
								await handleSelectImage(image)
							}}
							imageKey={formData.profileImageKey}
							readFileRequest={async (imageKey) => appDispatch(readProfileImage(imageKey)).unwrap()}
							labelText={"Foto de perfil"}
							buttonText={"Mudar"}
						/>
						<FormField
							id="email"
							name="email"
							type="text"
							required
							onChange={(e: any) => setFormData({ ...formData, email: e.target.value })}
							label="Email"
							labelclassname="block text-sm font-medium leading-6 text-gray-900"
							errorclassname="text-sm text-red-500"
							containerClassName="min-w-[30rem] sm:min-w-full"
							errors={validation(formData, 'email').errors}
							showerrors={!!showErrors}
							value={formData.email}
						/>
						<FormField
							id="phone"
							name="phone"
							type="text"
							required
							onChange={(e: any) => setFormData({ ...formData, phone: '+55' + unmaskNumber(e.target.value) })}
							label="Celular (Whatsapp)"
							labelclassname="block text-sm font-medium leading-6 text-gray-900"
							errorclassname="text-sm text-red-500"
							containerClassName="min-w-[18rem] sm:min-w-full"
							errors={validation(formData, 'phone').errors}
							showerrors={!!showErrors}
							value={maskPhone(formData.phone.replace('+55', ''))}
						/>
						<FormField
							id="document"
							name="document"
							type="text"
							required
							onChange={(e: any) => setFormData({ ...formData, document: unmaskNumber(e.target.value) })}
							label="CPF/CNPJ"
							labelclassname="block text-sm font-medium leading-6 text-gray-900"
							errorclassname="text-sm text-red-500"
							containerClassName="min-w-[18rem] sm:min-w-full"
							errors={validation(formData, 'document').errors}
							showerrors={!!showErrors}
							value={maskCpfCNPJ(formData.document)}
						/>
						<div className="min-w-[38.5rem] sm:min-w-full">
							<LoadingButton
								type="submit"
								className="flex w-fulljustify-center rounded-md bg-[#4E2581] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#b691e2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4E2581] group-invalid:pointer-events-none group-invalid:opacity-30"
								isloading={updateLoading}
							>
								Atualizar dados
							</LoadingButton>
						</div>
					</form>
				</span>
				<span hidden={user.username !== currentUser.username}>
					<form className="flex mt-10 flex-wrap gap-10 pb-10" onSubmit={handleOnSubmitUpdatePassword} noValidate>
						<label className="w-[38.5rem] border-b-[1px] border-[#706f6f]">Atualização de senha</label>
						<FormField
							id="currentPassword"
							name="currentPassword"
							type="password"
							required
							onChange={(e: any) => setFormDataPassword({ ...formDataPassword, currentPassword: e.target.value })}
							label="Senha atual"
							labelclassname="block text-sm font-medium leading-6 text-gray-900"
							errorclassname="text-sm text-red-500"
							containerClassName="min-w-[18rem] sm:min-w-full"
							errors={validation(formDataPassword, 'currentPassword').errors}
							showerrors={!!showErrors}
						/>
						<FormField
							id="newPassword"
							name="newPassword"
							type="password"
							required
							onChange={(e: any) => setFormDataPassword({ ...formDataPassword, newPassword: e.target.value })}
							label="Nova senha"
							labelclassname="block text-sm font-medium leading-6 text-gray-900"
							errorclassname="text-sm text-red-500"
							containerClassName="min-w-[18rem] sm:min-w-full"
							errors={validation(formDataPassword, 'newPassword').errors}
							showerrors={!!showErrors}
						/>
						<div className="min-w-[38.5rem] sm:min-w-full">
							<LoadingButton
								type="submit"
								className="flex w-fulljustify-center rounded-md bg-[#4E2581] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#b691e2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4E2581] group-invalid:pointer-events-none group-invalid:opacity-30"
								isloading={updateLoading}
							>
								Atualizar senha
							</LoadingButton>
						</div>
					</form>
				</span>
			</div>
			<Alert onClose={() => {
				setErrorTitle("")
			}} message={errorTitle} duration={5000} position="top-right" type={"error"} />
			<Alert onClose={() => {
				setSuccessTitle("")
			}} message={successTitle} duration={5000} position="top-right" type={"success"} />
		</main>
	)
}

export default Profile
