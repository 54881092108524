import { useCallback, useEffect, useState } from 'react';
import LogoBranco from '../../assets/logo.svg';
import { authActions, confirmLoginWithMFAUser, loginUser, requestForgotPassword, resetPassword, respondNewPasswordRequired } from './slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import SignIn from '../../components/SignIn';
import { RootState } from '../../app/store';
import { CLEAR_AUTH_ERROR, FORGET_PASSWORD, REMEMBER_PASSWORD, RESET_INFO, SIGN_IN_NO_MFA } from '../../app/actionTypes';
import ForgotPassword from '../../components/ForgotPassword';
import GoBackButton from '../../components/GoBackButton';
import Alert from '../../components/Alert';
import { actions as commonActions } from '../../app/commonSlice';

type AuthComponent = "SignIn" | "ForgotPassword" | "NewPasswordRequired";
export default function Auth() {
	const appDispatch = useAppDispatch()
	const [successMessage, setSuccessMessage] = useState("")
	const [successTitle, setSuccessTitle] = useState("")
	const [successItems, setSuccessItems] = useState<Array<string>>([])
	const [errorTitle, setErrorTitle] = useState("")
	const [errorItems, setErrorItems] = useState<Array<string>>([])
	const [authActiveComponent, setAuthActiveComponent] = useState<AuthComponent>("SignIn")
	console.log({ successTitle, successItems, errorItems })
	useEffect(() => {
		if (successMessage) {
			setSuccessItems([successMessage])
		}
	}, [successMessage])

	const { error, storedUsername, storedPassword, rememberPassword }: any = useAppSelector(
		(state: RootState) => state.authReducer,
	)

	useEffect(() => {
		if (error) {
			setErrorTitle("Error na autenticação")
			if (Array.isArray(error)) {
				setErrorItems(error)
			} if (error.code === "#UNEXPECTED") {
				setErrorItems(["Usuário não autorizado"])
			} else if (error.code === "#UserNotConfirmed") {
				setErrorItems(["E-mail de usuário ainda não foi confirmado"])
			} else if (error.code === "#NotAuthorizedException") {
				setErrorItems(["Não autorizado"])
			} else {
				setErrorItems([error.message])
			}
		} else {
			setErrorTitle("")
			setErrorItems([])
		}
	}, [error])

	const handleChangeAuthActiveComponent = useCallback((authComponent: AuthComponent) => {
		setAuthActiveComponent(authComponent)
	}, [setAuthActiveComponent])

	const handleLoginSubmition = useCallback(async ({ username, password }: any) => {
		const response = await appDispatch(loginUser({ username, password })).unwrap()
		if (response.challengeName === "#NewPasswordRequired") {
			handleChangeAuthActiveComponent("NewPasswordRequired")
		} else {
			appDispatch(commonActions[RESET_INFO]())
		}
		return response
	}, [appDispatch, loginUser, handleChangeAuthActiveComponent])

	const handleLoginConfirmationSubmition = useCallback(async ({ username, code, session, type }: any) => {
		const response = await appDispatch(confirmLoginWithMFAUser({ username, code, session, type })).unwrap()
		return response
	}, [appDispatch, confirmLoginWithMFAUser])

	const handleForgotPasswordSubmition = useCallback(async ({ username }: any) => {
		const response = await appDispatch(requestForgotPassword(username)).unwrap()
		return response
	}, [appDispatch, requestForgotPassword])

	const handleForgotPasswordResetSubmition = useCallback(async ({ username, confirmationCode, newPassword }: any) => {
		const response = await appDispatch(resetPassword({ username, confirmationCode, newPassword })).unwrap()
		return response
	}, [appDispatch, resetPassword])

	const handleRememberCredentials = useCallback(async ({ username, password, isToRemember }: any) => {
		if (isToRemember) {
			appDispatch(authActions[REMEMBER_PASSWORD]({ username, password }))
		} else {
			appDispatch(authActions[FORGET_PASSWORD]())
		}
	}, [appDispatch])

	const isLoading: boolean = useAppSelector((state: RootState) => state.authReducer.loading)

	const handleOnClickResetPasswordSuccess = useCallback(async () => {
		handleChangeAuthActiveComponent('SignIn')
		setSuccessTitle("Atualização de senha concluída")
		setSuccessMessage("Parabéns sua senha foi atualizada com sucesso")
	}, [handleChangeAuthActiveComponent])

	const handleLoginResponse = useCallback(async (authResponse: any) => {
		appDispatch(authActions[SIGN_IN_NO_MFA](authResponse))
	}, [appDispatch])

	const handleOnCloseAlert = useCallback(async () => {
		appDispatch(authActions[CLEAR_AUTH_ERROR]())
	}, [appDispatch])

	const handleNewPasswordRequired = useCallback(async ({ username, password, document, email, session, phone, name }: any) => {
		const response = await appDispatch(respondNewPasswordRequired({ username, password, document, email, session, phone, name })).unwrap()
		return response
	}, [appDispatch])

	return (
		<div className="flex min-h-full min-w-full">
			<div className="flex flex-grow min-h-screen flex-col justify-center items-center min-w-[100px] w-auto px-5">
				<div className="mx-auto w-full max-w-sm lg:w-96">
					<div>
						<div className="p-10 w-full gap-5 flex items-center justify-between">
							<img
								className="h-auto w-full"
								src={LogoBranco}
								alt="Logo"
								style={{ objectFit: "cover" }}
							/>
							{authActiveComponent !== "SignIn" &&
								<GoBackButton onGoBack={() => handleChangeAuthActiveComponent("SignIn")}
									className='bg-none hover:bg-none w-[6rem]'
									iconClassName='text-[#53119d] hover:text-[#130850]'
								/>
							}
						</div>
						<h2 className="mt-8 font-bold leading-9 tracking-tight">
							{
								authActiveComponent === "SignIn" &&
								"Entre com sua conta" ||
								authActiveComponent === "ForgotPassword" &&
								"Esqueceu a senha?" ||
								authActiveComponent === "NewPasswordRequired" &&
								"Atualize as suas informações e senha"
							}
						</h2>
					</div>
					{
						(authActiveComponent === "SignIn" || authActiveComponent === "NewPasswordRequired") &&
						<SignIn
							onSubmitSignIn={handleLoginSubmition}
							onSubmitSignInConfirmation={handleLoginConfirmationSubmition}
							onRememberCredentials={handleRememberCredentials}
							onClickForgotPassword={() => handleChangeAuthActiveComponent('ForgotPassword')}
							isloading={!!isLoading}
							onSignIn={handleLoginResponse}
							rememberedcredentials={{ storedUsername, storedPassword, rememberPassword }}
							onSubmitNewPasswordRequired={handleNewPasswordRequired}
						/> ||
						authActiveComponent === "ForgotPassword" &&
						<ForgotPassword
							onSubmitForgotPassword={handleForgotPasswordSubmition}
							onSubmitForgotPasswordConfirmation={handleForgotPasswordResetSubmition}
							onClickResetPasswordSuccess={handleOnClickResetPasswordSuccess}
							isloading={!!isLoading}
						/>
					}
				</div>
			</div>
			<Alert onClose={handleOnCloseAlert} message={errorTitle} duration={5000} position="top-left" type={'error'} />
		</div>
	)
}
