import { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import MenuList from "../MenuList";

interface PaginationBarProps {
  labelShowing?: string;
  labelResults?: string;
  isPageable?: boolean;
  nextLabel?: string;
  previousLabel?: string;
  nextEnabled: boolean;
  previousEnabled: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onChangePageSize?: (pageSize: number) => void;
  pageSizeOptions?: number[];
  isRelational?: boolean;
  labelTo?: string;
  totalCount?: number;
  labelRows?: string; 
  page: number;
  labelOf?: string;
}

export default function PaginationBar(Props: PaginationBarProps) {
  const {
    labelShowing, labelResults, previousLabel,
    nextLabel, nextEnabled, previousEnabled,
    onNextPage, onPreviousPage, pageSizeOptions,
    onChangePageSize, isRelational, labelTo, totalCount,
    labelRows, page, labelOf
  } = Props;

  const [pageSize, setPageSize] = useState((pageSizeOptions || [5])[0])

  useEffect(() => {
    onChangePageSize && onChangePageSize(pageSize)
  }, [pageSize])
  return (
    <nav
      className="flex items-center justify-between border-t px-4 py-3 sm:px-2"
      aria-label="Pagination"
    >
      <div className="flex justify-center items-center gap-10 sm:flex-col sm:gap-2">
        <p className="text-sm">
          {labelShowing || "Showing"}
          {" "}
          <span className="font-medium">{1 + page*pageSize}{' '}</span>
          {labelTo || "to"}
          {" "}
          <span className="font-medium">{(page + 1)*pageSize}{' '}</span>
          {!isRelational && (labelResults || "results")}
          {isRelational && ` ${labelOf || "of"} `}{isRelational && <span className="font-medium">{totalCount}</span>}{` ${labelResults || "results"}`}
        </p>
        <div className="w-10 h-10 sm:w-full">
          <MenuList
            buttonLabel={`${pageSize} ${labelRows || "rows"}`}
            menuOptions={pageSizeOptions}
            onChange={(_pageSize) => setPageSize(_pageSize)}
          />
        </div>
      </div>
      <div className="flex justify-between gap-2">
        <CustomButton disabled={!previousEnabled} onClick={onPreviousPage}>
          {previousLabel || "Previous"}
        </CustomButton>
        <CustomButton disabled={!nextEnabled} onClick={onNextPage}>
          {nextLabel || "Next"}
        </CustomButton>
      </div>
    </nav>
  )
}
