import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import Loader from "../Loader";

interface SearchInputFormProps {
	onSubmit: (value: string) => Promise<void>;
	placeHolder: string;
	className: string;
}

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}
export default function SearchInputForm(props: SearchInputFormProps) {
	const [loading, setIsLoading] = useState(false)
	const [search, setSearch] = useState("")
	return (
		<form className="relative flex h-12 w-full" onSubmit={(e) => {
			e.preventDefault()
			setIsLoading(true)
			if (props.onSubmit) {
				props.onSubmit(search)
					.then(() => {
						setIsLoading(false)
					})
					.catch(() => {
						setIsLoading(false)
					})
			} else {
				setIsLoading(false)
			}
		}}>
			{loading ?
				<Loader
					width={10}
					height={10}
					containerProps={{ className: "absolute inset-y-0 h-full w-6 right-2" }}
				/>
				:
				<MagnifyingGlassIcon
					className="absolute inset-y-0 h-full w-6 right-2"
					aria-hidden="true"
				/>
			}
			<input
				id="search-field"
				className={classNames(["focus:border-none", props.className])}
				placeholder={props.placeHolder}
				type="search"
				name="search"
				onChange={(e) => {
					setSearch(e.target.value)
				}}
			/>
		</form>
	)
}