import { Fragment, PropsWithChildren, useRef, useState } from 'react'
import { Dialog, Menu as _Menu, MenuButton, MenuItems, MenuItem, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import {
	Bars3Icon,
	BellIcon,
	HomeIcon,
	QrCodeIcon,
	UsersIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ClipboardDocumentListIcon, UserIcon } from '@heroicons/react/20/solid'
import logo from "../../assets/logo-horizontal.svg"
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import ImageByKey from '../ImageByKey'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

interface MenuProps extends PropsWithChildren {
	user: {
		id: number,
		name: string,
		document: string,
		email: string,
		phone: string,
		active: boolean,
		profileImageKey: string,
		createdAt: string,
		updatedAt: string,
	},
	userType: "administrator" | "common"
	signOut: () => void,
	readFileRequest: (imageKey: string) => Promise<{ url: string }>
}

export default function Menu({
	user, signOut, userType, readFileRequest
}: MenuProps) {
	const navigate = useNavigate()
	const location = useLocation()
	const menuOptions = [
		{ name: 'Início', href: '/home', icon: HomeIcon, current: true, onClick: () => { navigate('/home'); setSidebarOpen(false); } },
		{ name: 'Dispositivos', href: '/devices', icon: ClipboardDocumentListIcon, current: false, onClick: () => { navigate('/devices'); setSidebarOpen(false); } },
		...(userType === "administrator" ? [{ name: 'Usuários', href: '/users', icon: UsersIcon, current: false, onClick: () => { navigate('/users'); setSidebarOpen(false); } }] : []),
		...(userType === "administrator" ? [{ name: 'Grupos', href: '/groups', icon: QrCodeIcon, current: false, onClick: () => { navigate('/groups'); setSidebarOpen(false); } }] : []),
	]
	const userNavigationRef = useRef([
		{ id: 1, name: 'Perfil', href: 'profile' },
		{ id: 2, name: 'Sair', href: '#', onClick: signOut },
	])
	const [sidebarOpen, setSidebarOpen] = useState(false)
	return (
		<>
			<div className='relative z-0'>
				<Transition show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
						<TransitionChild
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0" />
						</TransitionChild>

						<div className="fixed inset-0 flex bg-white">
							<TransitionChild
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
									<TransitionChild
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</TransitionChild>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4">
										<div className="flex w-full h-20 p-5 shrink-0 items-center">
											<img
												className="w-full object-cover"
												src={logo}
												alt="Diferencial Services"
											/>
										</div>
										<nav className="flex flex-1 flex-col">
											<ul role="list" className="flex flex-1 flex-col gap-y-7">
												<li>
													<ul role="list" className="-mx-2 space-y-1">
														{menuOptions.map((item, index) => (
															<li key={item.name}>
																<a
																	onClick={item.onClick}
																	className={classNames(
																		location.pathname === item.href || (index === 0 && location.pathname === '/')
																			? 'bg-gray-100 text-[#e62021]'
																			: 'text-gray-700 hover:text-[#e62021] hover:bg-gray-100',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<item.icon
																		className={classNames(
																			location.pathname === item.href || (index === 0 && location.pathname === '/') ? 'text-[#e62021]' : 'text-[#6f6d6e] group-hover:text-[#e62021]',
																			'h-6 w-6 shrink-0'
																		)}
																		aria-hidden="true"
																	/>
																	{item.name}
																</a>
															</li>
														))}
													</ul>
												</li>
											</ul>
										</nav>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</Dialog>
				</Transition>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex grow justify-center flex-col gap-y-5 overflow-y-auto border-r border-gray-200 px-6 pb-4">
						<div className="flex w-full h-20 p-5 shrink-0 items-center">
							<img
								className="w-full object-cover"
								src={logo}
								alt="Diferencial Services"
							/>
						</div>
						<nav className="flex flex-1 flex-col">
							<ul role="list" className="flex flex-1 flex-col gap-y-7">
								<li>
									<ul role="list" className="-mx-2 space-y-1">
										{menuOptions.map((item, index) => (
											<li key={item.name}>
												<a
													onClick={item.onClick}
													className={classNames(
														location.pathname === item.href || (index === 0 && location.pathname === '/')
															? 'bg-gray-100 text-[#e62021]'
															: 'text-gray-700 hover:text-[#e62021] hover:bg-gray-100',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<item.icon
														className={classNames(
															location.pathname === item.href || (index === 0 && location.pathname === '/') ? 'text-[#e62021]' : 'text-[#6f6d6e] group-hover:text-[#e62021]',
															'h-6 w-6 shrink-0'
														)}
														aria-hidden="true"
													/>
													{item.name}
												</a>
											</li>
										))}
									</ul>
								</li>
							</ul>
						</nav>
					</div>
				</div>

				<div className="lg:pl-72">
					<div className="sticky top-0 z-40 lg:mx-auto lg:max-w-full lg:px-8">
						<div className="flex h-16 items-center gap-x-4 border-b border-gray-200 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
							<button
								type="button"
								className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
								onClick={() => setSidebarOpen(true)}
							>
								<span className="sr-only">Open sidebar</span>
								<Bars3Icon className="h-6 w-6" aria-hidden="true" />
							</button>

							{/* Separator */}
							<div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

							<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
								<div className="relative flex flex-1">
									{/* Bar options here */}
								</div>
								<div className="flex items-center gap-x-4 lg:gap-x-6">
									<button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 bg-none">
										<span className="sr-only">View notifications</span>
										<BellIcon className="h-6 w-6" aria-hidden="true" />
									</button>

									{/* Separator */}
									<div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

									{/* Profile dropdown */}
									<_Menu as="div" className="relative">
										<MenuButton className="-m-1.5 flex items-center p-1.5 bg-none">
											<span className="sr-only">Open user _Menu</span>
											{user?.profileImageKey ?
												<ImageByKey
													className="h-8 w-8 rounded-full bg-gray-50"
													alt=""
													imageKey={user?.profileImageKey}
													readFileRequest={readFileRequest}
												/>
												:
												<UserIcon className='w-8 h-8 text-gray-500' />
											}
											<span className="hidden lg:flex lg:items-center">
												<p className="ml-4 leading-6" aria-hidden="true">
													{user.name}
												</p>
												<ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
											</span>
										</MenuButton>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<MenuItems className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
												{userNavigationRef.current.map((item) => (
													<MenuItem key={item.name}>
														{() => (
															<a
																href={item.href}
																onClick={item.onClick}
																className={classNames(
																	'block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-slate-100'
																)}
															>
																{item.name}
															</a>
														)}
													</MenuItem>
												))}
											</MenuItems>
										</Transition>
									</_Menu>
								</div>
							</div>
						</div>
					</div>
					<main className="p-1">
						<Outlet />
					</main>
				</div>
			</div>
		</>
	)
}
