import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface AlertProps extends PropsWithChildren {
	position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
	message: string | null;
	duration?: number;
	onClose?: () => void;
	type: 'error' | 'success';
	className?: string;
}

interface Pos {
	top: string | null;
	right: string | null;
	bottom: string | null;
	left: string | null;
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

function calculatePos(position: string | undefined, distance: string = '50px'): Pos {
	switch (position) {
		case 'bottom-left':
			return {
				top: null,
				right: null,
				bottom: distance,
				left: distance,
			};
		case 'bottom-right':
			return {
				top: null,
				right: distance,
				bottom: distance,
				left: null,
			};
		case 'top-left':
			return {
				top: distance,
				right: null,
				bottom: null,
				left: distance,
			};
		case 'top-right':
			return {
				top: distance,
				right: distance,
				bottom: null,
				left: null,
			};
		default:
			return {
				top: null,
				right: null,
				bottom: distance,
				left: distance,
			};
	}
}

export default function Alert({
	position,
	message,
	duration = 2000,
	onClose,
	type,
	className,
}: AlertProps) {
	// Não renderiza se a mensagem for vazia ou null
	if (!message) {
		return null;
	}

	const [hidden, setHidden] = useState(true);
	const [pos, setPos] = useState<Pos>(calculatePos(position));

	// Configura as animações de spring
	const animationProps: any = useSpring({
		from: type === 'error' ? { x: -100 } : { y: -100 },
		to: type === 'error' ? { x: 0 } : { y: 0 },
		config: { tension: 170, friction: 26 },
		reset: true,
	});

	useEffect(() => {
		setHidden(false);
		const errorTimeout = setTimeout(() => {
			setHidden(true);
		}, duration);
		return () => clearTimeout(errorTimeout);
	}, [message, duration]);

	useEffect(() => {
		if (hidden) {
			onClose && onClose();
		}
	}, [hidden, onClose]);

	useEffect(() => {
		setPos(calculatePos(position));
	}, [position]);

	const alertContent = (
		<div className="flex py-3 px-3">
			<div className="flex-shrink-0">
				{type === 'error' ? (
					<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
				) : (
					<CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
				)}
			</div>
			<div className="ml-3">
				<h3 className={`text-sm font-medium ${type === 'error' ? 'text-red-800' : 'text-green-800'}`}>
					{message}
				</h3>
			</div>
		</div>
	);

	return (
		<animated.div
			className={`absolute rounded-md p-4 transition-opacity duration-300 ease-in-out ${hidden ? 'opacity-0 w-0 h-0 -z-50' : 'opacity-100 z-50'
				}`}
			style={{
				...(pos.top ? { top: pos.top } : {}),
				...(pos.right ? { right: pos.right } : {}),
				...(pos.bottom ? { bottom: pos.bottom } : {}),
				...(pos.left ? { left: pos.left } : {}),
				opacity: hidden ? 0 : 1,
				transform:
					type === 'error'
						? animationProps.x.to((x: any) => `translateX(${x}px)`)
						: animationProps.y.to((y: any) => `translateY(${y}px)`),
			}}
			aria-hidden={hidden}
		>
			<div className={classNames(type === 'error' ? 'bg-red-50' : 'bg-green-50', className || '')}>
				{alertContent}
			</div>
		</animated.div>
	);
}
