import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom"

interface PrivateRouteProps extends PropsWithChildren{
  authenticated: boolean;
  redirectRoute: string;
}

const PrivateRoute = ({
  authenticated,
  redirectRoute
}: PrivateRouteProps) => {
  return authenticated ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  )
}

export default PrivateRoute
