import { PropsWithChildren } from "react"

interface TabBarProps extends PropsWithChildren {
	tabs: {
		name: string;
		current: boolean;
		onClick: (tabIndex: number) => void
	}[];
	onChangeTab: (tabIndex: number) => void
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

export default function TabBar(props: TabBarProps) {
	const { tabs, onChangeTab } = props;
	return (
		<div className="w-full">
			<div className="hidden">
				<label htmlFor="tabs" className="sr-only">
					Select a tab
				</label>
				{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
				<select
					id="tabs"
					name="tabs"
					className="block w-full rounded-md border-gray-300 focus:border-[#f28c25] focus:ring-[#f28c25]"
					defaultValue={(tabs.find((tab) => tab.current) || {}).name || ""}
					onChange={(e) => onChangeTab(tabs.findIndex((tab) => tab.name === e.target.value))}
				>
					{tabs.map((tab, index) => (
						<option key={index}>{tab.name}</option>
					))}
				</select>
			</div>
			<div className="lg:block">
				<nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
					{tabs.map((tab, tabIdx) => (
						<a
							key={tab.name}
							onClick={() => {
								tab.onClick(tabIdx)
								onChangeTab(tabIdx)
							}}
							className={classNames(
								tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
								tabIdx === 0 ? 'rounded-l-lg' : '',
								tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
								'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-md font-medium hover:bg-gray-50 focus:z-10',
							)}
							aria-current={tab.current ? 'page' : undefined}
						>
							<span>{tab.name}</span>
							<span
								aria-hidden="true"
								className={classNames(
									tab.current ? 'bg-[#f28c25]' : 'bg-transparent',
									'absolute inset-x-0 bottom-0 h-0.5',
								)}
							/>
						</a>
					))}
				</nav>
			</div>
		</div>
	)
}
