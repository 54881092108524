import { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { apiClient as backendAPIClient } from "./backendAPI";

interface Session {
  accessToken: string;
  refreshToken?: string;
  // Adicione outros campos relevantes da sessão, se necessário
}

interface RefreshResponse {
  accessToken: string;
  // Adicione outros campos retornados pela função de refresh, se necessário
}

type OnRefreshFunction = (session: Session) => Promise<RefreshResponse>;

const apiClients: AxiosInstance[] = [
  backendAPIClient,
];

export const createInterceptors = async (session: Session, onRefresh: OnRefreshFunction) => {
  for (let apiClient of apiClients) {
    // Limpa interceptores anteriores para evitar duplicações
    apiClient.interceptors.request.clear();
    apiClient.interceptors.response.clear();

    // Interceptor de requisição para adicionar o token de acesso
    apiClient.interceptors.request.use(
      (config) => {
				config.headers.Authorization = `Bearer ${session.accessToken}`;
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    // Interceptor de resposta para lidar com erros 401 e renovar o token
    apiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError) => {
        const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.url?.includes("/auth/refresh") &&
          !originalRequest.url?.includes("/auth/log-out")
        ) {
          originalRequest._retry = true;

          try {
            // Chama a função de refresh para obter um novo token
            const { accessToken } = await onRefresh(session);

            // Atualiza o token na sessão ou estado global
            session.accessToken = accessToken;

            // Atualiza o token no header da requisição original
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            } else {
              originalRequest.headers = { Authorization: `Bearer ${accessToken}` };
            }

            // Reenvia a requisição original com o novo token
            return apiClient(originalRequest);
          } catch (refreshError) {
            // Se a renovação do token falhar, rejeita a Promise com o erro
            return Promise.reject(refreshError);
          }
        }

        // Se não for um erro 401 ou se já tentou renovar, rejeita a Promise com o erro original
        return Promise.reject(error);
      }
    );
  }
};

export const removeInterceptors = async () => {
  for (let apiClient of apiClients) {
    apiClient.interceptors.request.clear();
    apiClient.interceptors.response.clear();
  }
};
