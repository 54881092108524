interface SevenSegmentDisplayProps {
  number: number;
}

const segments: any = {
  0: [1, 1, 1, 1, 1, 1, 0],
  1: [0, 1, 1, 0, 0, 0, 0],
  2: [1, 1, 0, 1, 1, 0, 1],
  3: [1, 1, 1, 1, 0, 0, 1],
  4: [0, 1, 1, 0, 0, 1, 1],
  5: [1, 0, 1, 1, 0, 1, 1],
  6: [1, 0, 1, 1, 1, 1, 1],
  7: [1, 1, 1, 0, 0, 0, 0],
  8: [1, 1, 1, 1, 1, 1, 1],
  9: [1, 1, 1, 1, 0, 1, 1],
  14: [1, 0, 0, 1, 1, 1, 1],
  15: [1, 0, 0, 0, 1, 1, 1],
};

const SevenSegmentDisplay = ({ number }: SevenSegmentDisplayProps) => {
  const activeSegments = segments[number] || segments[0];

  return (
    <svg
      width="100"
      height="200"
      viewBox="0 0 100 200"
      className="mx-auto my-4 px-2 bg-gray-900 border-8 border-gray-400"
    >
      <polygon
        className={`segment ${activeSegments[0] ? 'fill-red-500' : 'fill-gray-700'}`}
        points="0,0 100,0 80,20 20,20"
      />
      <polygon
        className={`segment ${activeSegments[1] ? 'fill-red-500' : 'fill-gray-700'}`}
        points="100,0 100,90 90,100 80,90 80,20"
      />
      <polygon
        className={`segment ${activeSegments[2] ? 'fill-red-500' : 'fill-gray-700'}`}
        points="90,100 100,110 100,200 80,180 80,110"
      />
      <polygon
        className={`segment ${activeSegments[3] ? 'fill-red-500' : 'fill-gray-700'}`}
        points="100,200 0,200 20,180 80,180"
      />
      <polygon
        className={`segment ${activeSegments[4] ? 'fill-red-500' : 'fill-gray-700'}`}
        points="10,100 20,110 20,180 0,200 0,110"
      />
      <polygon
        className={`segment ${activeSegments[5] ? 'fill-red-500' : 'fill-gray-700'}`}
        points="0,0 20,20 20,90 10,100 0,90"
      />
      <polygon
        className={`segment ${activeSegments[6] ? 'fill-red-500' : 'fill-gray-700'}`}
        points="10,100 20,90 80,90 90,100 80,110 20,110"
      />
    </svg>
  );
};

export default SevenSegmentDisplay;
