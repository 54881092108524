import React from "react";
import Chart, { Props } from "react-apexcharts";

interface ChartProps {
  minX: number;
  data: { x: number, y: number }[];
  seriesName: string;
}

const CustomLineChart: React.FC<ChartProps> = ({ data, seriesName, minX }) => {
  const chartConfig: Props = {
    series: [
      {
        name: seriesName,
        data: data,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 240,
        toolbar: {
          show: false,
        },
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        }
      },
      title: {
        text: seriesName,
        align: 'center',
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#020617"],
      stroke: {
        lineCap: "round" as const,
        curve: "smooth" as const,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
        type: 'datetime',
        min: minX
      },
      yaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 5,
          right: 20,
        },
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  return <Chart options={chartConfig.options as any} series={chartConfig.series} type="line" height={240} />;
};

export default CustomLineChart;
