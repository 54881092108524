import React from "react";

import { CloseIcon, DateIcon } from "./utils";

interface ToggleButtonProps {
    isEmpty: boolean;
}

const ToggleButton: React.FC<ToggleButtonProps> = (e: ToggleButtonProps): JSX.Element => {
    return e.isEmpty ? <DateIcon className="h-5 w-5 text-black" /> : <CloseIcon className="h-5 w-5 text-black" />;
};

export default ToggleButton;
