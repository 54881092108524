import { useCallback, useEffect, useState } from "react";
import Loader from "../Loader";

type Url = string;
interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	imageKey: string;
	readFileRequest?: (imageKey: string) => Promise<{ url: Url }>
}
const MAX_RETRY = 3
export default function ImageByKey(props: Props) {
	const [retries, setRetries] = useState(MAX_RETRY)
	const [imageUrl, setImageUrl] = useState('');
	const [loading, setLoading] = useState(false);

	const loadImage = useCallback(async () => {
		setRetries(retries - 1)
		setLoading(true)
		try {
			if (!props.readFileRequest) {
				throw new Error("You need to pass function to read file")
			}
			const { url } = await props.readFileRequest(props.imageKey);
			setImageUrl(url);
		} catch (error) {
			console.error('Failed to load image', error);
		}
		setLoading(false)
	}, [props, retries]);

	useEffect(() => {
		if (
			!!props.imageKey &&
			(
				props.imageKey.startsWith('http') || props.imageKey.startsWith('data:')
			)
		) {
			setImageUrl(props.imageKey)
		} else {
			loadImage();
		}
	}, [props.imageKey]);

	const handleOnErrorLoad = useCallback(() => {
		if (!!retries) {
			loadImage()
		}
	}, [props.imageKey, retries])

	return (
		loading ?
			<Loader width={24} height={24} containerProps={{ className: "w-full h-full flex justify-center items-center" }} />
			:
			<img
				src={imageUrl}
				{...props}
				onError={handleOnErrorLoad}
			/>
	);
};
