import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import AlertError from "../AlertError";
import LoadingButton from "../LoadingButton";
import InputGrid from "../InputGrid";
import FormField from "../FormField";

interface ForgotPasswordProps extends PropsWithChildren {
	onSubmitForgotPassword: ({ username, password }: any) => Promise<any>;
	onSubmitForgotPasswordConfirmation: ({ username, code, session, type }: any) => Promise<any>;
	onClickResetPasswordSuccess: () => void;
	isloading: boolean
}

type AuthComponent = "ForgotPassword" | "NewPassword";

export interface AuthResponse {
	idToken: string;
	accessToken: string;
	refreshToken: string;
	expiresIn: number;
}

export interface AuthChallenge {
	challengeName: "#NewPasswordRequired" | "#SmsNewPassword" | "#SoftwareTokenNewPassword";
	challengeParameters?: {
		deliveryMedium?: string;
		destination?: string;
		deviceName?: string;
		username: string;
	},
	user?: {
		isEmailVerified: boolean;
		phone: string;
		email: string;
	};
	session: string;
}

type ForgotPasswordForm = {
	username: string;
}

type ForgotPasswordConfirmationForm = {
	confirmationCode: string;
	password: string;
	repassword?: string;
}

const validation = (values: any, key: keyof ForgotPasswordForm | keyof ForgotPasswordConfirmationForm | "all"): { name: string, errors: Array<{ isValidated: boolean, message: string }> } => {
	switch (key) {
		case 'username':
			return ({
				name: "Nome de usuário ou e-mail",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					}
				]
			})
		case 'password':
			return ({
				name: "Senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: values[key].length >= 8,
						message: "A senha deve ter ao menos 8 caracteres"
					}
				]
			})
		case 'repassword':
			return ({
				name: "Confirme a senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: values[key] === values['password'],
						message: "A senha digitada aqui deve ser igual a anterior"
					}
				]
			})
		case 'all':
			let errors: any = []
			for (let _key of Object.keys(values) as any) {
				const validations = validation(values, _key)
				const newErrors = validations.errors.filter((error: any) => !error.isValidated)
				errors = [...errors, ...(newErrors.map((error) => ({ ...error, message: validations.name + ": " + error.message })))]
			}
			return {
				name: "Validação dos campos",
				errors
			}
		default:
			return {
				name: "",
				errors: []
			}
	}

}

export default function ForgotPassword(Props: ForgotPasswordProps) {
	const { onSubmitForgotPassword, isloading, onSubmitForgotPasswordConfirmation, onClickResetPasswordSuccess } = Props
	const [forgotPasswordFormData, setForgotPasswordFormData] = useState<ForgotPasswordForm>({
		username: "",
	})
	const [forgotPasswordConfirmationFormData, setForgotPasswordConfirmationFormData] = useState<ForgotPasswordConfirmationForm>({
		confirmationCode: "",
		password: ""
	})
	const [showErrors, setshowErrors] = useState(false)
	const [forgotPasswordError, setForgotPasswordError] = useState<any>("")
	const [newpasswordError, setNewPasswordError] = useState("")
	const [errorTitle, setErrorTitle] = useState("")
	const [errors, setErrors] = useState<Array<string>>([])
	const [authActiveComponent, setAuthActiveComponent] = useState<AuthComponent>("ForgotPassword")

	useEffect(() => {
		const error: any = (forgotPasswordError || newpasswordError)
		if (error) {
			setErrorTitle("Erro ao recuperar senha")
			setErrors(typeof error === 'string' ? [
				error
			] : error.map((err: any) => err.message))
		} else {
			setErrorTitle("")
			setErrors([])
		}
	}, [forgotPasswordError, newpasswordError])

	const handleOnSubmitForgotPassword = useCallback((e: any) => {
		e && e.preventDefault()
		setshowErrors(true)
		const validationErrors = validation(forgotPasswordFormData, 'all').errors.filter((error) => !error.isValidated)
		if (validationErrors.length) {
			setErrorTitle("Erro de validação dos campos")
			setErrors(validationErrors.map((error) => error.message))
		} else if (onSubmitForgotPassword) {
			onSubmitForgotPassword(forgotPasswordFormData)
				.then(() => {
					setshowErrors(false)
					setAuthActiveComponent("NewPassword")
				})
				.catch((error: any) => {
					if (Array.isArray(error)) {
						setForgotPasswordError(error)
					} else if (error.code === "#UNEXPECTED") {
						setForgotPasswordError("Usuário não autorizado")
					} else if (error.code === "#UserNotConfirmed") {
						setForgotPasswordError("E-mail de usuário ainda não foi confirmado")
					} else if (error.code === "#NotAuthorizedException") {
						setForgotPasswordError("Não autorizado")
					} else {
						setForgotPasswordError(error.message)
					}
				})
		} else {
			setForgotPasswordError("Função de submeter credenciais não encontrada")
		}
	}, [forgotPasswordFormData, onSubmitForgotPassword, setForgotPasswordError])

	const handleOnSubmitForgotPasswordConfirmation = useCallback((e: any) => {
		e && e.preventDefault()
		setshowErrors(true)
		const validationErrors = validation(forgotPasswordFormData, 'all').errors.filter((error) => !error.isValidated)
		if (validationErrors.length) {
			setErrorTitle("Erro de validação dos campos")
			setErrors(validationErrors.map((error) => error.message))
		} else if (
			!!onSubmitForgotPasswordConfirmation
		) {
			onSubmitForgotPasswordConfirmation({
				username: forgotPasswordFormData.username,
				confirmationCode: forgotPasswordConfirmationFormData.confirmationCode,
				newPassword: forgotPasswordConfirmationFormData.password
			})
				.then(() => {
					onClickResetPasswordSuccess && onClickResetPasswordSuccess()
				})
				.catch((error: any) => {
					if (Array.isArray(error)) {
						setForgotPasswordError(error)
					} else if (error.code === "#UNEXPECTED") {
						setNewPasswordError("Usuário não autorizado")
					} else if (error.code === "#CodeMismatchException") {
						setNewPasswordError("Código inválido")
					} else if (error.code === "#NotAuthorizedException") {
						setNewPasswordError("Não autorizado")
					} else {
						setNewPasswordError(error.message)
					}
				})
		} else {
			setForgotPasswordError("Função de submeter código newpassword não encontrada")
		}
	}, [forgotPasswordFormData, onSubmitForgotPasswordConfirmation, setForgotPasswordError, forgotPasswordConfirmationFormData])

	return (
		<div className="mt-10">
			<div id="ForgotPasswordFormContainer" hidden={authActiveComponent !== "ForgotPassword"}>
				<form className="space-y-6" onSubmit={handleOnSubmitForgotPassword} noValidate>
					<FormField
						id="username"
						name="username"
						type="text"
						required
						onChange={(e: any) => setForgotPasswordFormData({ ...forgotPasswordFormData, username: e.target.value })}
						label="Nome de usuário ou e-mail"
						labelclassname="block text-sm font-medium leading-6 text-gray-900"
						errorclassname="text-sm text-red-500"
						errors={validation(forgotPasswordFormData, 'username').errors}
						showerrors={!!showErrors}
					/>
					<div>
						<LoadingButton
							type="submit"
							className="flex w-full justify-center rounded-md bg-[#4E2581] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#b691e2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4E2581] group-invalid:pointer-events-none group-invalid:opacity-30"
							isloading={!!isloading}
						>
							Recuperar senha
						</LoadingButton>
					</div>
				</form>
			</div>
			<div id="NewPasswordContainer" hidden={authActiveComponent !== "NewPassword"}>
				<form className="space-y-6" onSubmit={handleOnSubmitForgotPasswordConfirmation} noValidate>
					<div className="flex flex-col gap-6">
						<InputGrid
							label="Código enviado para o seu email"
							labelclassname="block text-sm font-medium leading-6 text-gray-900 mb-5"
							classNameContainer="flex flex-col space-x-2 justify-center"
							classNameInput="w-12 h-12 text-center form-control border border-gray-300 text-[#4E2581] focus:ring-[#4E2581] rounded-md text-lg"
							code={forgotPasswordConfirmationFormData.confirmationCode}
							setCode={(confirmationCode) => setForgotPasswordConfirmationFormData({ ...forgotPasswordConfirmationFormData, confirmationCode })}
							quantity={6}
						/>
						{forgotPasswordConfirmationFormData.confirmationCode.length === 6 &&
							<>
								<FormField
									id="password"
									name="password"
									type="password"
									required
									autoComplete="current-password"
									onChange={(e: any) => setForgotPasswordConfirmationFormData({ ...forgotPasswordConfirmationFormData, password: e.target.value })}
									label="Nova senha"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									errors={validation(forgotPasswordConfirmationFormData, 'password').errors}
									showerrors={!!showErrors}
								/>
								<FormField
									id="repassword"
									name="repassword"
									type="password"
									required
									autoComplete="current-password"
									onChange={(e: any) => setForgotPasswordConfirmationFormData({ ...forgotPasswordConfirmationFormData, repassword: e.target.value })}
									label="Confirme a sua nova senha"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									errors={validation(forgotPasswordConfirmationFormData, 'repassword').errors}
									showerrors={!!showErrors}
								/>
							</>
						}

					</div>
					<div>
						<LoadingButton
							type="submit"
							className="flex w-full justify-center rounded-md bg-[#4E2581] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4E2581]"
							isloading={!!isloading}
						>
							Atualizar senha
						</LoadingButton>
					</div>
				</form>
			</div>
			<div className="mt-10 hidden">
				<div className="relative">
					<div className="absolute inset-0 flex items-center" aria-hidden="true">
						<div className="w-full border-t border-gray-200" />
					</div>
					<div className="relative flex justify-center text-sm font-medium leading-6">
						<span className="bg-white px-6 text-gray-900">Or continue with</span>
					</div>
				</div>

				<div className="mt-6 grid grid-cols-2 gap-4">
					<a
						href="#"
						className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
					>
						<svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
							<path
								d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
								fill="#EA4335"
							/>
							<path
								d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
								fill="#4285F4"
							/>
							<path
								d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
								fill="#FBBC05"
							/>
							<path
								d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
								fill="#34A853"
							/>
						</svg>
						<span className="text-sm font-semibold leading-6">Google</span>
					</a>
					<a
						className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
					>
						<svg className="h-5 w-5 fill-[#24292F]" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
							<path
								fillRule="evenodd"
								d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
								clipRule="evenodd"
							/>
						</svg>
						<span className="text-sm font-semibold leading-6">GitHub</span>
					</a>
				</div>
			</div>
			<AlertError onClose={() => {
				setForgotPasswordError("")
			}} errorTitle={errorTitle} errors={errors} duration={5000} position="top-left" />
		</div>
	)
}