import { combineReducers } from "@reduxjs/toolkit"
import type { Reducer } from "@reduxjs/toolkit"

import commonReducer from "./commonSlice"
import authReducer from "../pages/Auth/slice"
import homeReducer from "../pages/Home/slice"
import profileReducer from "../pages/Profile/slice"
import usersReducer from "../pages/Users/slice"
import groupsReducer from "../pages/Groups/slice"
import devicesReducer from "../pages/Devices/slice"

const rootReducer: Reducer = combineReducers({
	commonReducer,
	authReducer,
	homeReducer,
	profileReducer,
	usersReducer,
	groupsReducer,
	devicesReducer
})

export default rootReducer
