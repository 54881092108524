import React, { useRef } from 'react';

interface InputGridProps {
    classNameContainer?: string
    classNameInput?: string
    code: string;
    setCode: (code: string) => void;
    quantity: number;
    label?: string;
    labelclassname?: string;
}

export default function InputGrid(Props: InputGridProps) {
    const { classNameContainer, classNameInput, code, setCode, quantity, labelclassname, label } = Props

    const inputRefs = Array(quantity).fill(null).map(() => useRef(null));

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let value = e.target.value;
        if (value.length >= quantity) {
            let _code = ""
            for (let index = 0; index < value.length; index++) {
                const currChar = e.target.selectionStart && e.target.selectionStart > quantity ? value.slice(index + 1, index + 2) : value.slice(index, index + 1)
                if ("0123456789".includes(currChar)) {
                    _code += currChar
                }
            }
            setCode(_code)
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        nextInputRef: React.MutableRefObject<any> | null,
        previousInputRef: React.MutableRefObject<any> | null
    ) => {
        const value = e.key;
        if ("0123456789".includes(value)) {
            setCode((code + value).slice(0, quantity));
        } else if (value === "Backspace") {
            setCode(code.slice(0, -1))
        }
        if ("0123456789".includes(value) || value === "Backspace") {
            if (value.length === 1 && nextInputRef) {
                nextInputRef.current.focus();
            } else if (!value.length && previousInputRef) {
                previousInputRef.current.focus();
            }
            if (value === 'Backspace' && previousInputRef) {
                previousInputRef.current.focus();
            }
        }
    };

    return (
        <div className={classNameContainer || "flex space-x-2 justify-center flex-col"}>
            {label &&
                <label className={labelclassname}>
                    {label}
                </label>
            }
            <div className="flex space-x-2 justify-center flex-row">
                {inputRefs.map((inputRef, index) => (
                    <input key={index} value={code.slice(index, index + 1)} ref={inputRef} onKeyDown={(e) => handleKeyDown(e, inputRefs[index + 1], !index ? null : inputRefs[index - 1])} onChange={handleInputChange} className={classNameInput} maxLength={quantity + 1} type="text" inputMode="numeric" pattern="[0-9]*" />
                ))}
            </div>
        </div>
    );
}
