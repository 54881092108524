import logo from '../../assets/logo.svg';

interface LoadingOverlayProps {
  progressValue: number //0 - 100
}

const LoadingOverlay = ({ progressValue }: LoadingOverlayProps) => {
  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white">
      <div className="text-center w-[30%] sm:w-full md:w-full sm:px-10 md:px-10">
        <img src={logo} alt="Loading..." className="mx-auto mb-4 w-full h-auto animate-bounce" />
        <p className="text-[2.5rem] text-black mb-4">Carregando...</p>
        <div className="w-[100%] min-w-80 bg-[#e7d5f3] rounded-full h-5 dark:bg-gray-700">
          <div className={`bg-[#591d82] h-5 rounded-full`} style={{ width: `${progressValue}%` }} />
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
