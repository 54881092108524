import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { maskPhone, unmaskNumberData } from "../../libs/masks";

interface FormFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	label?: string;
	labelclassname?: string;
	containerClassName?: string;
	errors: Array<{
		isValidated: boolean;
		message: string;
	}>
	errorclassname?: string;
	showerrors: boolean;
	countryCode?: string;  // Optional property for country code
}

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function FormField(props: FormFieldProps) {
	const { label, labelclassname, containerClassName, errors, errorclassname, type, showerrors } = props;
	const [openEye, setOpenEye] = useState(false);
	const [phoneCountryCode, setPhoneCountryCode] = useState("+55");
	const [phoneNumber, setPhoneNumber] = useState("");
	useEffect(() => {
		if (type === 'tel') {
			props.onChange && props.onChange({ target: { value: '+' + (phoneCountryCode + phoneNumber).split('+').join('') } } as any)
		}
	}, [phoneCountryCode, phoneNumber])
	return (
		<div className={containerClassName}>
			<label className={labelclassname}>
				{label}
			</label>
			<div className="mt-2 relative">
				{
					type === 'password' ?
						(
							<>
								{openEye ?
									<EyeIcon className="absolute inset-y-0 h-full w-6 right-2" onClick={() => setOpenEye(false)} />
									:
									<EyeSlashIcon className="absolute inset-y-0 h-full w-6 right-2" onClick={() => setOpenEye(true)} />
								}
								<input
									{...props}
									className={classNames(["focus:border-none", props.className])}
									type={type === 'password' && openEye ? 'text' : type}
								/>
							</>
						) :
						type === 'tel' ?
							(
								<div className="flex space-x-2">
									<input
										{...props}
										className={classNames(["w-20 focus:border-none", props.className])}
										type="tel"
										value={phoneCountryCode}
										onChange={(e) => setPhoneCountryCode(e.target.value)}
										placeholder="+55"
									/>
									<input
										{...props}
										className={classNames(["focus:border-none", props.className])}
										type="tel"
										value={(maskPhone(props.value as string).replace(unmaskNumberData(phoneCountryCode), ''))}
										onChange={(e) => setPhoneNumber(unmaskNumberData(e.target.value))}
									/>
								</div>
							) :
							type === 'checkbox' ?
							(
								<input {...props} className={classNames(["w-8 h-8 focus:border-none", props.className])} />
							)
							:
							(
								<input {...props} className={classNames(["focus:border-none", props.className])} />
							)
				}
			</div>
			{showerrors &&
				<ul role="list" className="list-disc space-y-1 pl-5">
					{errors.map((error, index) => (
						!error.isValidated && <li key={index} className={errorclassname || "text-red-600"}>{error.message}</li>
					))}
				</ul>
			}
		</div>
	);
}
