import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ADD_MEMBER_TO_GROUP, REMOVE_MEMBER_FROM_GROUP, LIST_GROUPS, LIST_GROUP_USERS, SELECT_GROUP, LIST_USERS_NOT_MEMBERS } from "../../app/actionTypes"
import {
  addUserToGroup as _addUserToGroup,
  removeUserFromGroup as _removeUserFromGroup,
  listGroups as _listGroups,
  listUsersInGroup as _listUsersInGroup,
  listUsersOutsideGroup as _listUsersOutsideGroup
} from "../../services/backendAPI"

export const addUserToGroup = createAsyncThunk(
  ADD_MEMBER_TO_GROUP,
  async (payload: any, thunkAPI) => {
    try {
      const response = await _addUserToGroup(payload.username, payload.groupName)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const removeUserFromGroup = createAsyncThunk(
  REMOVE_MEMBER_FROM_GROUP,
  async (payload: any, thunkAPI) => {
    try {
      const response = await _removeUserFromGroup(payload.username, payload.groupName)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const listUsersInGroup = createAsyncThunk(
  LIST_GROUP_USERS,
  async (payload: { groupName: string, limit: number, lastIndex: string | null, search: string }, thunkAPI) => {
    try {
      const response = await _listUsersInGroup(payload.groupName, payload.limit, payload.lastIndex, payload.search)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const listGroups = createAsyncThunk(
  LIST_GROUPS,
  async (_: { limit: number, lastIndex: string | null, search: string }, thunkAPI) => {
    try {
      const response = await _listGroups()
      return {
        ...response,
        results: response.results.filter((group: any) => !(group.name.toLowerCase().includes('facebook') || group.name.toLowerCase().includes('google')))
      }
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const listUsersOutGroup = createAsyncThunk(
  LIST_USERS_NOT_MEMBERS,
  async (payload: { groupName: string, limit: number, lastIndex: string | null, search: string }, thunkAPI) => {
    try {
      const response = await _listUsersOutsideGroup(payload.groupName, payload.limit, payload.lastIndex, payload.search)
      return response
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

interface GroupState {
  loadingGroups: boolean;
  loadingUsersInGroup: boolean;
  loadingUsersOutGroup: boolean;
  listingUsers: boolean;
  error: string | null;
  addingOrRemovingUser: boolean;
  groups: any[];
  selectedGroup: any;
}

const initialState: GroupState = {
  loadingGroups: false,
  loadingUsersInGroup: false,
  loadingUsersOutGroup: false,
  listingUsers: false,
  error: null,
  addingOrRemovingUser: false,
  groups: [],
  selectedGroup: null
};

const slice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    [SELECT_GROUP]: (state, action: PayloadAction<any>) => {
      state.selectedGroup = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserToGroup.fulfilled, (state) => {
        state.addingOrRemovingUser = false;
        state.error = null;
      })
      .addCase(addUserToGroup.pending, (state) => {
        state.addingOrRemovingUser = true;
        state.error = null;
      })
      .addCase(addUserToGroup.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.addingOrRemovingUser = false;
      })
      .addCase(listGroups.fulfilled, (state, action: PayloadAction<any>) => {
        state.loadingGroups = false;
        state.addingOrRemovingUser = false;
        state.loadingUsersInGroup = false;
        state.loadingUsersOutGroup = false;
        state.error = null;
        state.groups = action.payload.results
        state.selectedGroup = action.payload.results[0]
      })
      .addCase(listGroups.pending, (state) => {
        state.loadingGroups = true;
        state.error = null;
      })
      .addCase(listGroups.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGroups = false;
      })
      .addCase(removeUserFromGroup.fulfilled, (state) => {
        state.addingOrRemovingUser = false;
        state.error = null;
      })
      .addCase(removeUserFromGroup.pending, (state) => {
        state.addingOrRemovingUser = true;
        state.error = null;
      })
      .addCase(removeUserFromGroup.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.addingOrRemovingUser = false;
      })
      .addCase(listUsersInGroup.fulfilled, (state) => {
        state.loadingUsersInGroup = false;
        state.error = null;
      })
      .addCase(listUsersInGroup.pending, (state) => {
        state.loadingUsersInGroup = true;
        state.error = null;
      })
      .addCase(listUsersInGroup.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingUsersInGroup = false;
      })
      .addCase(listUsersOutGroup.fulfilled, (state) => {
        state.loadingUsersOutGroup = false;
        state.error = null;
      })
      .addCase(listUsersOutGroup.pending, (state) => {
        state.loadingUsersOutGroup = true;
        state.error = null;
      })
      .addCase(listUsersOutGroup.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingUsersOutGroup = false;
      })
  }
})

export const actions = slice.actions
export default slice.reducer
