import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom"

interface PublicRouteProps extends PropsWithChildren{
  authenticated: boolean;
  redirectRoute: string;
  children?: any;
}

const PublicRoute = ({
  authenticated,
  redirectRoute
}: PublicRouteProps) => {
  return !authenticated ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  )
}

export default PublicRoute
