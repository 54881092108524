import { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { actions, createUser, deleteUser, listUsers, readProfileImage, readUser, updateUser, uploadProfileImage } from "./slice";
import { RootState } from "../../app/store";
import { USERS_RESET } from "../../app/actionTypes";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import FormField from "../../components/FormField";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import ImageFormField from "../../components/ImageFormField";
import { v4 as uuidv4 } from 'uuid';
import { maskCpfCNPJ, maskPhone, unmaskNumber } from "../../libs/masks";
import LoadingButton from "../../components/LoadingButton";

const userColumns: any = [
	{ title: "ID", type: "string", key: "id", hidden: true },
	{ title: "Username", type: "string", key: "username", hidden: true },
	{ title: "Nome", type: "string", key: "name", hidden: false },
	{ title: "Email", type: "string", key: "email", hidden: false },
	{ title: "Whatsapp", type: "string", key: "phone", hidden: false },
	{ title: "CPF/CNPJ", type: "string", key: "document", hidden: false },
	{ title: "Está ativo?", type: "boolean", key: "active", hidden: false },
	{ title: "Imagem de perfil", type: "image", key: "profileImageKey", hidden: false },
	{ title: "Criado em", type: "date-time", key: "createdAt", hidden: false },
	{ title: "Atualizado em", type: "date-time", key: "updatedAt", hidden: false },
];

type UserFormData = {
	name: string;
	email: string;
	document: string;
	phone: string;
	password: string;
	newPassword: string;
	code: string;
	currentPassword: string;
};

const validation = (values: any, key: keyof UserFormData | "all"): { name: string, errors: Array<{ isValidated: boolean, message: string }> } => {
	const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);
	const isCPFValid = (cpf: string) => /^\d{11}$/.test(cpf);
	const isCNPJValid = (cnpj: string) => /^\d{14}$/.test(cnpj);
	const isPhoneValid = (phone: string) => /^\+\d{13}$/.test(phone);
	if (!values) {
		return {
			name: "",
			errors: []
		}
	}
	switch (key) {
		case 'name':
			return ({
				name: "Nome",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					}
				]
			});
		case 'email':
			return ({
				name: "Email",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: isEmailValid(values[key]),
						message: "Formato de e-mail inválido"
					}
				]
			});
		case 'document':
			return ({
				name: "CPF ou CNPJ",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: isCPFValid(values[key]) || isCNPJValid(values[key]),
						message: "Formato de CPF ou CNPJ inválido"
					}
				]
			});
		case 'phone':
			return ({
				name: "Celular",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: isPhoneValid(values[key]),
						message: "Formato de celular inválido. Use o formato xx xxxxxxxxx"
					}
				]
			});
		case 'password':
			return ({
				name: "Senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length >= 8,
						message: "A senha deve ter ao menos 8 caracteres"
					}
				]
			});
		case 'newPassword':
			return ({
				name: "Nova senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length >= 8,
						message: "A senha deve ter ao menos 8 caracteres"
					}
				]
			});
		case 'currentPassword':
			return ({
				name: "Senha",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length >= 8,
						message: "A senha deve ter ao menos 8 caracteres"
					}
				]
			});
		case 'code':
			return ({
				name: "Código de confirmação",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					},
					{
						isValidated: !!values[key] && values[key].length === 6,
						message: "O código de verificação deve ter 6 caracteres"
					}
				]
			});

		case 'all':
			let errors: any = [];
			for (let _key of Object.keys(values) as Array<keyof UserFormData>) {
				const validations = validation(values, _key);
				const newErrors = validations.errors.filter((error: any) => !error.isValidated);
				errors = [...errors, ...newErrors.map((error) => ({ ...error, message: `${validations.name}: ${error.message}` }))];
			}
			return {
				name: "Validação dos campos",
				errors
			};
		default:
			return {
				name: "",
				errors: []
			};
	}
};

const DEFAULT_FORM_DATA = {
	name: "",
	email: "",
	document: "",
	phone: "",
}
export default function Users() {
	const appDispatch = useAppDispatch()
	const { loadingUsers, readingUser, creatingUser, updatingUser, deletingUser } = useAppSelector((state: RootState) => state.usersReducer)
	const [formData, setFormData] = useState<any>({
		name: "",
		email: "",
		document: "",
		phone: "",
	})
	useEffect(() => {
		if (loadingUsers) {
			appDispatch(actions[USERS_RESET]())
		}
	}, [])
	const usersTableRef = useRef<any>(null)
	const handleListUsers = useCallback(async (limit: number, lastIndex: string, search: string) => {
		const response = await appDispatch(listUsers({ limit, lastIndex, search })).unwrap()
		return response
	}, [listUsers])

	const [user, setUser] = useState<any>(null)
	const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false)
	const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false)

	const resetForms = useCallback(() => {
		setIsCreatingUser(false)
		setIsUpdatingUser(false)
		setFormData(DEFAULT_FORM_DATA)
		setUser(null)
	}, [])

	const [errorTitle, setErrorTitle] = useState("")
	const [errorDialogTitle, setErrorDialogTitle] = useState("")
	const [successTitle, setSuccessTitle] = useState("")
	const [showErrors, setShowErrors] = useState<boolean>(false)

	const handleCreateUser = useCallback(async (user: any) => {
		const _validation = validation({
			name: user.name,
			email: user.email,
			phone: user.phone,
			document: user.document,
			active: typeof user.active !== "boolean" ? false : user.active,
			profileImageKey: user.profileImageKey || ""
		}, "all")
		setShowErrors(true)
		if (_validation.errors.length) {
			setErrorDialogTitle("Erro ao criar usuário")
		} else {
			try {
				await appDispatch(createUser(user)).unwrap()
				setSuccessTitle("Usuário cadastrado com sucesso")
				resetForms()
				usersTableRef.current && usersTableRef.current.refresh()
			} catch (error) {
				setErrorDialogTitle("Erro ao criar usuário")
			}
		}
	}, [createUser, usersTableRef])

	const handleReadUser = useCallback(async (userId: string) => {
		try {
			const response = await appDispatch(readUser(userId)).unwrap()
			setFormData(response)
			return response
		} catch (error) {
			setFormData(DEFAULT_FORM_DATA)
			setIsUpdatingUser(false)
			setErrorTitle("Erro ao buscar usuário")
			resetForms()
		}
	}, [readUser])

	const handleUpdateUser = useCallback(async (username: string, user: any) => {
		try {
			const response = await appDispatch(updateUser({
				username, userData: {
					name: user.name,
					email: user.email,
					phone: user.phone,
					document: user.document,
					active: user.active,
					profileImageKey: user.profileImageKey
				}
			})).unwrap()
			resetForms()
			setSuccessTitle("Usuário atualizado com sucesso")
			usersTableRef.current && usersTableRef.current.refresh()
			return response
		} catch (error: any) {
			if (error.code === "#DuplicatedVariable") {
				setErrorDialogTitle("Erro ao atualizar usuário")
			} else {
				setErrorDialogTitle("Erro ao atualizar usuário")
			}
		}

	}, [resetForms, usersTableRef])

	const handleDeleteUser = useCallback(async (username: string) => {
		try {
			const response = await appDispatch(deleteUser(username)).unwrap()
			return response
		} catch (error: any) {
			setErrorTitle("Erro ao apagar usuário")
			if (error.code === "#CanNotDeleteAdminOrYourself") {
				setErrorTitle("Erro ao apagar usuário")
			} else {
				setErrorTitle("Erro ao apagar usuário")
			}
		}
	}, [deleteUser])

	const handleSelectImage = useCallback(async (image: File) => {
		try {
			const imageKey = uuidv4()
			const response = await appDispatch(uploadProfileImage({ imageKey, file: image })).unwrap()
			setFormData({
				...formData,
				profileImageKey: imageKey
			})
			return response
		} catch (error) {
			return;
		}
	}, [formData])

	useEffect(() => {
		if (isUpdatingUser && user) {
			handleReadUser(user.username)
				.then((response: any) => {
					setFormData(response)
				})
				.catch(() => {
					setFormData(DEFAULT_FORM_DATA)
					setErrorTitle("Erro ao ler usuário")
				})
		}
	}, [isUpdatingUser, user])
	return (
		<>
			<div className="flex w-full h-full px-10 mt-10">
				<CustomTable
					ref={usersTableRef}
					data={handleListUsers}
					loading={loadingUsers || deletingUser}
					isSearchable={true}
					title={"Usuários"}
					subtitle={"Todos os usuários do sistema"}
					columns={userColumns}
					editLabel={""}
					isRelational={false}
					refresh={true}
					onCreate={() => {
						setIsCreatingUser(true)
						return Promise.resolve()
					}}
					onUpdate={(rowData) => {
						setUser(rowData)
						setIsUpdatingUser(true)
						return Promise.resolve()
					}}
					onDelete={(rowData) => handleDeleteUser(rowData.username)}
					readFileRequest={async (imageKey) => appDispatch(readProfileImage(imageKey)).unwrap()}
					areYouSureLabel="Você tem certeza disso?"
					areYouSureLabelNo="Não"
					areYouSureLabelYes="Sim"
					labelRows="linhas"
				/>
			</div>
			<Dialog open={isCreatingUser || isUpdatingUser} onClose={() => {
				setIsCreatingUser(false)
				setIsUpdatingUser(false)
				setFormData(DEFAULT_FORM_DATA)
			}} className="relative z-10">
				<div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-slate-900 bg-opacity-70">
					<DialogPanel className="w-full max-w-6xl space-y-4 border rounded-lg bg-white p-12 opacity-100">
						<DialogTitle className="font-bold">{isCreatingUser ? "Cadastro de usuário" : "Atualização de usuário"}</DialogTitle>
						{readingUser ?
							<Loader width={24} height={24} />
							:
							<form className="flex flex-wrap gap-10 sm:px-2 p-5"
								onSubmit={(e) => {
									e.preventDefault()
									isCreatingUser ? handleCreateUser(formData) : handleUpdateUser(formData.username, formData)
								}}
								noValidate
								hidden={isCreatingUser && isUpdatingUser}
							>
								<FormField
									id="name"
									name="name"
									type="text"
									required
									onChange={(e: any) => setFormData({ ...formData, name: e.target.value })}
									label="Nome"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[30rem] sm:min-w-full"
									errors={validation(formData, 'name').errors}
									showerrors={!!showErrors}
									value={formData && formData.name || ""}
								/>
								<ImageFormField
									onFileSelect={async (image) => {
										await handleSelectImage(image)
									}}
									imageKey={formData && formData.profileImageKey || ""}
									readFileRequest={async (imageKey) => appDispatch(readProfileImage(imageKey)).unwrap()}
									labelText={"Foto de perfil"}
									buttonText={"Mudar"}
								/>
								<FormField
									id="email"
									name="email"
									type="text"
									required
									onChange={(e: any) => setFormData({ ...formData, email: e.target.value })}
									label="Email"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[30rem] sm:min-w-full"
									errors={validation(formData, 'email').errors}
									showerrors={!!showErrors}
									value={formData && formData.email || ""}
								/>
								<FormField
									id="phone"
									name="phone"
									type="text"
									required
									onChange={(e: any) => setFormData({ ...formData, phone: '+55' + unmaskNumber(e.target.value) })}
									label="Celular (Whatsapp)"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[18rem] sm:min-w-full"
									errors={validation(formData, 'phone').errors}
									showerrors={!!showErrors}
									value={formData && maskPhone(formData.phone.replace('+55', '')) || ""}
								/>
								<FormField
									id="document"
									name="document"
									type="text"
									required
									onChange={(e: any) => setFormData({ ...formData, document: unmaskNumber(e.target.value) })}
									label="CPF/CNPJ"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[18rem] sm:min-w-full"
									errors={validation(formData, 'document').errors}
									showerrors={!!showErrors}
									value={formData && maskCpfCNPJ(formData.document) || ""}
								/>
								<FormField
									id="active"
									name="active"
									type="checkbox"
									required
									onChange={(e: any) => setFormData({ ...formData, active: e.target.checked })}
									label="Está ativo?"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[18rem] sm:min-w-full"
									errors={[]}
									showerrors={!!showErrors}
									checked={formData.active}
								/>
								<div className="w-full sm:min-w-full">
									<LoadingButton
										type="submit"
										className="flex w-fulljustify-center rounded-md bg-[#4E2581] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#b691e2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4E2581] group-invalid:pointer-events-none group-invalid:opacity-30"
										isloading={creatingUser || updatingUser}
									>
										{isCreatingUser ? "Criar usuário" : "Atualizar dados do usuário"}
									</LoadingButton>
								</div>
							</form>
						}
						<Alert onClose={() => {
							setErrorDialogTitle("");
						}} message={errorDialogTitle} duration={5000} position="top-right" type={"error"} className="min-w-[250px]" />
					</DialogPanel>
				</div>
			</Dialog>
			<Alert onClose={() => {
				setErrorTitle("");
			}} message={errorTitle} duration={5000} position="bottom-right" type={"error"} />
			<Alert onClose={() => {
				setSuccessTitle("");
			}} message={successTitle} duration={5000} position="bottom-right" type="success" />
		</>
	)
}