import {
	ChevronDownIcon,
	ChevronUpIcon,
	NoSymbolIcon,
	PencilSquareIcon,
	PlusIcon,
	TrashIcon,
} from '@heroicons/react/20/solid';
import {
	ArrowPathIcon,
	CheckCircleIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import CustomButton from '../CustomButton';
import ImageByKey from '../ImageByKey';
import Loader from '../Loader';
import PaginationBar from '../PaginationBar';
import RoundButton from '../RoundButton';
import SearchInputForm from '../SearchInputForm';
import Tooltip from '../Tooltip';

interface Column {
	title: string;
	type: 'date' | 'string' | 'boolean' | 'image' | 'date-time';
	key: string;
	className?: string;
	hidden?: boolean;
	sort?: boolean | ((a: any, b: any) => number);
	render?: (rowData: any) => any;
	width?: string | number;
}

interface DataRelational {
	count: number;
	totalCount: number;
	results: Record<string, any>[];
}

interface DataNotRelational {
	count: number;
	lastIndex: string;
	results: Record<string, any>[];
}

interface Action {
	name: string;
	action: (rowData: Record<string, any>) => Promise<void>;
	icon: React.ReactNode;
	isFreeAction?: boolean;
	buttonClassName?: string;
	hint?: string;
}

type Data = DataRelational | DataNotRelational;

interface CustomTableProps {
	data: Record<string, any>[] | any;
	loading: boolean;
	isSearchable: boolean;
	title: string;
	subtitle: string;
	addButtonTitle?: string;
	columns: Column[];
	onCreate?: () => Promise<any>;
	onUpdate?: (rowData: Record<string, any>) => Promise<any>;
	onDelete?: (rowData: Record<string, any>) => Promise<any>;
	onChangeSort?: (sortColumn: SortColumn | null) => Promise<any>;
	onRefresh?: () => Promise<any>;
	editLabel: string;
	defaultPageSizeOptions?: number[];
	isRelational: boolean;
	areYouSureLabel?: string;
	areYouSureLabelYes?: string;
	areYouSureLabelNo?: string;
	refresh?: boolean;
	customActions?: Action[];
	readFileRequest?: (imageKey: string) => Promise<{ url: string }>;
	defaultSortKey?: string;
	defaultSortOrder?: 'asc' | 'desc';
	labelRows?: string;
	onRefreshHint?: string;
	onCreateHint?: string;
	onUpdateHint?: string;
	onDeleteHint?: string;
}

interface SortColumn {
	key: string;
	order: 'asc' | 'desc';
}

function formatDateTime(date: Date): string {
	const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

	return (
		[
			padTo2Digits(date.getDate()),
			padTo2Digits(date.getMonth() + 1),
			date.getFullYear(),
		].join('/') +
		' ' +
		[
			padTo2Digits(date.getHours()),
			padTo2Digits(date.getMinutes()),
			padTo2Digits(date.getSeconds()),
		].join(':')
	);
}

function getValueFromRowData(
	rowData: any,
	column: Column,
	readFileRequest?: (imageKey: string) => Promise<{ url: string }>
): React.ReactNode {
	let value = rowData;
	const keys = column.key.split('.');

	for (const key of keys) {
		value = value ? value[key] : null;
	}

	if (column.render) {
		return column.render(rowData);
	}

	switch (column.type) {
		case 'image':
			return (
				<div className="h-11 w-11 flex-shrink-0">
					{readFileRequest && value ? (
						<ImageByKey
							className="h-11 w-11 rounded-full object-cover"
							alt=""
							imageKey={value}
							readFileRequest={readFileRequest}
						/>
					) : (
						<NoSymbolIcon width="100%" height="100%" />
					)}
				</div>
			);
		case 'date-time':
			return value ? formatDateTime(new Date(value)) : '-';
		case 'date':
			return value ? formatDateTime(new Date(value)).split(' ')[0] : '-';
		case 'boolean':
			if (value === true) {
				return <CheckCircleIcon className="text-green-500 w-10" />;
			} else if (value === false) {
				return <XMarkIcon className="text-red-500 w-10" />;
			} else {
				return '-';
			}
		default:
			return value !== null && value !== undefined && value !== '' ? value : '-';
	}
}

const CustomTable = forwardRef((props: CustomTableProps, ref: any) => {
	const {
		data,
		loading: externalLoading,
		isSearchable,
		columns,
		title,
		subtitle,
		addButtonTitle,
		onCreate,
		onUpdate,
		onDelete,
		editLabel,
		defaultPageSizeOptions,
		isRelational,
		areYouSureLabel,
		areYouSureLabelYes,
		areYouSureLabelNo,
		refresh,
		customActions,
		readFileRequest,
		onRefresh,
		defaultSortKey,
		defaultSortOrder,
		labelRows,
		onRefreshHint,
		onCreateHint,
		onUpdateHint,
		onDeleteHint,
	} = props;

	const [dataObj, setDataObj] = useState<Data>({
		count: 0,
		totalCount: 0,
		results: [],
	});
	const [sortColumn, setSortColumn] = useState<SortColumn>({
		key: defaultSortKey || columns[0].key,
		order: defaultSortOrder || 'asc',
	});
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(0);
	const pageSizeOptions = defaultPageSizeOptions || [5, 10, 20];
	const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0]);
	const [nextEnabled, setNextEnabled] = useState(true);
	const [previousEnabled, setPreviousEnabled] = useState(false);
	const [lastIndex, setLastIndex] = useState<string[]>([]);
	const [errorData, setErrorData] = useState<any>(null);
	const [rowToDoAction, setRowToDoAction] = useState<number | null>(null);
	const [actionToDo, setActionToDo] = useState<string>('');
	const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
	const [isDataLoading, setIsDataLoading] = useState<boolean>(false); // New state

	const actions: Record<string, any> = {
		onCreate,
		onUpdate,
		onDelete,
		onRefresh,
	};

	const visibleColumns = columns.filter((column) => !column.hidden);
	const columnWidth = `${100 / visibleColumns.length}%`;

	const runData = useCallback(
		(retry = 2) => {
			setIsDataLoading(true); // Start loading
			if (!externalLoading && Array.isArray(data)) {
				// Handle array data
				const filteredResults = data.filter((result: any) =>
					JSON.stringify(result)
						.toLowerCase()
						.includes(search.toLowerCase())
				);
				const sortedResults = filteredResults.sort((a: any, b: any) => {
					const column = columns.find((col) => col.key === sortColumn.key);
					const aValue = a[sortColumn.key];
					const bValue = b[sortColumn.key];

					if (column?.type === 'date' || column?.type === 'date-time') {
						return sortColumn.order === 'asc'
							? new Date(aValue).getTime() - new Date(bValue).getTime()
							: new Date(bValue).getTime() - new Date(aValue).getTime();
					}

					return sortColumn.order === 'asc'
						? aValue.localeCompare(bValue)
						: bValue.localeCompare(aValue);
				});

				const paginatedResults = sortedResults.slice(
					page * pageSize,
					(page + 1) * pageSize
				);

				setDataObj({
					count: paginatedResults.length,
					totalCount: sortedResults.length,
					results: paginatedResults,
				});

				setPreviousEnabled(page > 0);
				setNextEnabled(sortedResults.length / pageSize > page + 1);
				setErrorData(null);
				setIsDataLoading(false); // Stop loading
			} else if (!externalLoading && typeof data === 'function') {
				// Handle function data
				if (isRelational) {
					data(pageSize, page * pageSize, sortColumn.key, sortColumn.order, search)
						.then((response: DataRelational) => {
							setDataObj(response);
							setErrorData(null);
							setIsDataLoading(false); // Stop loading
						})
						.catch((error: any) => {
							if (retry > 0) runData(retry - 1);
							else {
								setErrorData(error);
								setIsDataLoading(false); // Stop loading
							}
						});
				} else {
					data(pageSize, page > 0 ? lastIndex[page - 1] : null, search)
						.then((response: DataNotRelational) => {
							const updatedLastIndex = page ? [...lastIndex] : [];
							if (updatedLastIndex.length > page - 1) {
								updatedLastIndex.push(response.lastIndex);
							}
							setLastIndex(updatedLastIndex);
							setNextEnabled(!!response.lastIndex);
							setPreviousEnabled(page > 0);
							setDataObj(response);
							setErrorData(null);
							setIsDataLoading(false); // Stop loading
						})
						.catch((error: any) => {
							if (retry > 0) runData(retry - 1);
							else {
								setErrorData(error);
								setIsDataLoading(false); // Stop loading
							}
						});
				}
			} else {
				setIsDataLoading(false); // Stop loading
			}
		},
		[
			data,
			page,
			pageSize,
			sortColumn,
			search,
			externalLoading,
			isRelational,
			lastIndex,
			columns,
		]
	);

	const handleDoAction = useCallback(() => {
		if (actionToDo && rowToDoAction !== null) {
			setIsActionLoading(true);
			actions[actionToDo]?.(dataObj.results[rowToDoAction])
				.then(() => {
					setRowToDoAction(null);
					setActionToDo('');
					setIsActionLoading(false);
					runData();
				})
				.catch((error: any) => {
					setErrorData(error);
					setRowToDoAction(null);
					setActionToDo('');
					setIsActionLoading(false);
					runData();
				});
		}
	}, [actionToDo, rowToDoAction, actions, dataObj.results, runData]);

	const handleCreate = () => {
		setIsActionLoading(true);
		onCreate?.()
			.then(() => {
				setIsActionLoading(false);
				runData();
			})
			.catch((error: any) => {
				setErrorData(error);
				setIsActionLoading(false);
				runData();
			});
	};

	const handleCustomAction = (
		actionFn: (rowData: any) => Promise<void>,
		rowData: any
	) => {
		return () => {
			setIsActionLoading(true);
			actionFn(rowData)
				.then(() => {
					setIsActionLoading(false);
					runData();
				})
				.catch((error: any) => {
					setErrorData(error);
					setIsActionLoading(false);
					runData();
				});
		};
	};

	useImperativeHandle(ref, () => ({
		refresh: runData,
	}));

	useEffect(() => {
		if (page === 0) {
			runData();
		} else {
			setPage(0)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, pageSize, sortColumn]);

	useEffect(() => {
		runData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		if (Array.isArray(data)) {
			runData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const renderTableHeader = () => (
		<thead className="border-t border-b">
			<tr>
				{columns.map((column) => (
					<th
						key={column.key}
						hidden={column.hidden}
						scope="col"
						className="py-3.5 pl-2 text-left font-semibold"
						style={{
							width: column.width || column.hidden ? undefined : columnWidth,
						}}
					>
						<div
							onClick={() =>
								setSortColumn({
									key: column.key,
									order: sortColumn.order === 'asc' ? 'desc' : 'asc',
								})
							}
							className="group inline-flex cursor-pointer"
						>
							{column.title}
							{column.sort && (
								<span className="ml-2 flex-none rounded group-hover:visible group-focus:visible">
									{sortColumn.key === column.key ? (
										sortColumn.order === 'asc' ? (
											<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
										) : (
											<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
										)
									) : (
										<ChevronDownIcon className="h-5 w-5 opacity-50" aria-hidden="true" />
									)}
								</span>
							)}
						</div>
					</th>
				))}
				{(onUpdate ||
					onDelete ||
					customActions?.some((action) => action.isFreeAction !== false)) && (
						<th
							scope="col"
							className="py-3.5 pl-4 text-left font-semibold"
							style={{ width: columnWidth }}
						>
							{editLabel || 'Ações'}
						</th>
					)}
			</tr>
		</thead>
	);

	const renderTableBody = () => (
		<tbody className="divide-y min-w-full">
			{dataObj.results.map((rowData, index) => (
				<tr key={rowData.id || index}>
					{rowToDoAction === index ? (
						<td colSpan={columns.length + 1} className="py-4">
							<div className="flex justify-between items-center w-full">
								<span>{areYouSureLabel || 'Are you sure?'}</span>
								<div className="flex gap-2">
									<CustomButton onClick={handleDoAction}>
										{areYouSureLabelYes || 'Yes'}
									</CustomButton>
									<CustomButton onClick={() => setRowToDoAction(null)}>
										{areYouSureLabelNo || 'No'}
									</CustomButton>
								</div>
							</div>
						</td>
					) : (
						<>
							{columns.map((column, colIndex) => (
								<td
									key={colIndex}
									hidden={column.hidden}
									className={column.className || 'whitespace-nowrap px-3 py-4 text-sm'}
									style={{
										width: column.width || column.hidden ? undefined : columnWidth,
									}}
								>
									{getValueFromRowData(rowData, column, readFileRequest)}
								</td>
							))}
							{(onUpdate ||
								onDelete ||
								customActions?.some((action) => action.isFreeAction !== false)) && (
									<td
										className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0 sm:text-center"
										style={{ width: columnWidth }}
									>
										<div className="flex justify-center items-center gap-2 sm:flex-col">
											{onUpdate && (
												<RoundButton
													onClick={() => {
														setRowToDoAction(index);
														setActionToDo('onUpdate');
													}}
												>
													<Tooltip message={onUpdateHint}>
														<PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
													</Tooltip>
												</RoundButton>
											)}
											{onDelete && (
												<RoundButton
													onClick={() => {
														setRowToDoAction(index);
														setActionToDo('onDelete');
													}}
												>
													<Tooltip message={onDeleteHint}>
														<TrashIcon className="h-5 w-5" aria-hidden="true" />
													</Tooltip>
												</RoundButton>
											)}
											{customActions
												?.filter((action) => action.isFreeAction !== false)
												.map((action, actionIndex) => (
													<RoundButton
														key={actionIndex}
														className={action.buttonClassName}
														onClick={handleCustomAction(action.action, rowData)}
													>
														<Tooltip message={action.hint}>
															{action.icon || action.name}
														</Tooltip>
													</RoundButton>
												))}
										</div>
									</td>
								)}
						</>
					)}
				</tr>
			))}
		</tbody>
	);

	const isLoading = externalLoading || isActionLoading || isDataLoading;

	return (
		<div className="p-10 border-2 rounded-lg w-full sm:p-0 sm:px-2 sm:py-10 lg:px-8">
			<div className="flex items-center justify-between">
				<div>
					<h2 className="leading-8">{title}</h2>
					<p className="mt-2 sm:mt-0">{subtitle}</p>
				</div>
				<div className="flex items-center space-x-4">
					{isSearchable && (
						<SearchInputForm
							onSubmit={(value) => {
								setSearch(value);
								return Promise.resolve();
							}}
							placeHolder="Pesquisar"
							className="bg-black w-64"
						/>
					)}
					{((ref?.current?.refresh && refresh) || onRefresh) && (
						<RoundButton
							onClick={() => {
								setPage(0);
								if (onRefresh) {
									return onRefresh();
								} else if (ref?.current?.refresh) {
									return ref.current.refresh();
								}
							}}
						>
							<Tooltip message={onRefreshHint}>
								<ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
							</Tooltip>
						</RoundButton>
					)}
					{onCreate && (
						<RoundButton onClick={handleCreate}>
							<Tooltip message={onCreateHint}>
								{addButtonTitle || <PlusIcon className="h-5 w-5" aria-hidden="true" />}
							</Tooltip>
						</RoundButton>
					)}
				</div>
			</div>

			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:mx-0 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-8">
						<table
							className="min-w-full divide-y"
							style={{ tableLayout: 'fixed' }} // Added table-layout fixed
						>
							{renderTableHeader()}
							{!errorData && !isLoading && renderTableBody()}
						</table>

						{errorData && !isLoading && (
							<div className="flex w-full h-full justify-center items-center text-center p-10">
								<p>{errorData.message || errorData.toString()}</p>
							</div>
						)}

						{isLoading && (
							<div className="flex w-full h-full justify-center items-center text-center p-10 bg-opacity-10">
								<Loader
									containerProps={{
										className: 'w-full h-full flex justify-center align-center p-10',
									}}
									width="40px"
									height="40px"
								/>
							</div>
						)}

						{!errorData && (
							<PaginationBar
								isPageable
								labelShowing="Mostrando"
								labelResults={dataObj.count > 1 ? 'resultados' : 'resultado'}
								nextEnabled={nextEnabled}
								previousEnabled={previousEnabled}
								nextLabel="Próximo"
								previousLabel="Anterior"
								onNextPage={() => setPage(page + 1)}
								onPreviousPage={() => setPage(Math.max(page - 1, 0))}
								onChangePageSize={setPageSize}
								pageSizeOptions={pageSizeOptions}
								isRelational={isRelational}
								totalCount={(dataObj as DataRelational).totalCount || dataObj.count || 0}
								labelTo="até"
								labelRows={labelRows}
								page={page}
								labelOf="de"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

export default CustomTable;
