import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CHECK_SESSION, GET_CURRENT_GROUP, GET_CURRENT_USER, READ_PROFILE_IMAGE_COMMON, RESET_INFO, SET_CURRENT_USER, SET_IS_LOADING, SET_PROGRESS_VALUE, UPDATE_LOGGED_USER_ATTRIBUTES } from "./actionTypes"
import { getOwnGroup, getOwnUser, readFile } from "../services/backendAPI";
import axios from 'axios';

export const getUser = createAsyncThunk(GET_CURRENT_USER, async (_, thunkAPI) => {
	try {
		const response = await getOwnUser();
		return response;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 400) {
				return thunkAPI.rejectWithValue(error.response?.data.map((err: any) => ({ ...err })));
			} else {
				return thunkAPI.rejectWithValue(error.response?.data);
			}
		} else {
			return thunkAPI.rejectWithValue(error);
		}
	}
});

export const getGroups = createAsyncThunk(GET_CURRENT_GROUP, async (_, thunkAPI) => {
	try {
		const response = await getOwnGroup();
		return response;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 400) {
				return thunkAPI.rejectWithValue(error.response?.data.map((err: any) => ({ ...err })));
			} else {
				return thunkAPI.rejectWithValue(error.response?.data);
			}
		} else {
			return thunkAPI.rejectWithValue(error);
		}
	}
});

export const readProfileImage = createAsyncThunk(
  READ_PROFILE_IMAGE_COMMON,
  async (imageKey: string, thunkAPI) => {
    try {
      const { url } = await readFile(imageKey)
      return { url }
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

interface InitialState {
	isLoading: boolean;
	progressValue: number;
	error: any;
	loadingGetUser: boolean;
	user: any;
	groups: any[];
	sessionChecked: boolean;
}

const initialState: InitialState = {
	isLoading: true,
	progressValue: 1,
	error: null,
	loadingGetUser: false,
	user: null,
	groups: [],
	sessionChecked: false
}

const slice = createSlice({
	name: "common",
	initialState,
	reducers: {
		[SET_IS_LOADING]: (
			state,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.isLoading = payload
		},
		[SET_PROGRESS_VALUE]: (
			state,
			{ payload }: PayloadAction<number>,
		) => {
			state.progressValue = payload
		},
		[SET_CURRENT_USER]: (
			state,
			{ payload }: PayloadAction<any>,
		) => {
			state.user = payload
		},
    [UPDATE_LOGGED_USER_ATTRIBUTES]: (state, {
      payload,
    }: PayloadAction<any>) => {
      if (!payload) {
        state.user = null
      } else if(!!state.user){
        state.user = {
          ...state.user,
          ...payload
        }
      }
    },
    [RESET_INFO]: (state) => {
			state.sessionChecked = false
			state.user = null
			state.groups = []
			state.isLoading = false
			state.loadingGetUser = false
    },
		[CHECK_SESSION]: (state) => {
			state.sessionChecked = true
    }
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
				state.user = action.payload
				state.loadingGetUser = false;
				state.error = null;
			})
			.addCase(getUser.pending, (state) => {
				state.user = null;
				state.loadingGetUser = true;
			})
			.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
				state.error = action.payload;
				state.loadingGetUser = false;
			})
			.addCase(getGroups.fulfilled, (state, action: PayloadAction<any>) => {
				state.groups = action.payload
				state.loadingGetUser = false;
				state.error = null;
			})
			.addCase(getGroups.pending, (state) => {
				state.groups = [];
				state.loadingGetUser = true;
			})
			.addCase(getGroups.rejected, (state, action: PayloadAction<any>) => {
				state.error = action.payload;
				state.loadingGetUser = false;
			})
	}
})

export const actions = slice.actions
export default slice.reducer