import React, { useState, useEffect, useRef, HTMLAttributes, Fragment } from 'react';
import { XMarkIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash';
import clsx from 'clsx';
import { ChevronUpIcon, ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import {
	Combobox,
	Transition,
	ComboboxButton,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
} from '@headlessui/react';

interface CustomAutoCompleteProps extends HTMLAttributes<HTMLInputElement> {
	className?: string;
	fetchData: (query: string) => Promise<Record<string, any>[]>;
	onSelect: (data: any) => void;
	label?: string;
	labelclassname?: string;
	containerClassName?: string;
	errors: Array<{
		isValidated: boolean;
		message: string;
	}>;
	errorclassname?: string;
	showerrors: boolean;
	dataDisplay: (data: Record<string, any> | null) => string;
	value: Record<string, any>;
	labelNoResult: string;
	refreshKey?: any; // Nova prop para forçar atualização
	placeholder?: string;
}

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
	className,
	fetchData,
	onSelect,
	showerrors,
	errors,
	errorclassname,
	value,
	dataDisplay,
	labelNoResult,
	containerClassName,
	refreshKey,
	placeholder,
	...props
}) => {
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [data, setData] = useState<Record<string, any>[]>([]);
	const [selectedItem, setSelectedItem] = useState<any>(value || null);
	const prevQueryRef = useRef<string | null>(null);

	// Armazena a função fetchData atualizada
	const fetchDataRef = useRef(fetchData);

	useEffect(() => {
		fetchDataRef.current = fetchData;
	}, [fetchData]);

	// Inicializa debouncedFetchData apenas uma vez
	const debouncedFetchData = useRef(
		debounce(async (query: string) => {
			if (query !== prevQueryRef.current) {
				prevQueryRef.current = query;
				setLoading(true);
				try {
					const result = await fetchDataRef.current(query);
					setData(result);
				} catch (error) {
					console.error('Error fetching data:', error);
				} finally {
					setLoading(false);
				}
			}
		}, 1000)
	).current;

	// Limpa a função debounce ao desmontar o componente
	useEffect(() => {
		return () => {
			debouncedFetchData.cancel();
		};
	}, [debouncedFetchData]);

	// Chama debouncedFetchData quando inputValue ou refreshKey mudam
	useEffect(() => {
		if (refreshKey !== undefined) {
			prevQueryRef.current = null; // Redefine prevQueryRef para forçar a atualização
		}
		debouncedFetchData(inputValue);
	}, [inputValue, refreshKey]);

	const handleClear = () => {
		setInputValue('');
		setSelectedItem(null);
		setData([]);
		onSelect(null);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
		setSelectedItem(null);
	};

	const handleSelectItem = (item: Record<string, any>) => {
		setSelectedItem(item);
		onSelect(item);
	};

	useEffect(() => {
		if (value) {
			setSelectedItem(value);
			setInputValue(dataDisplay(value));
		} else {
			setSelectedItem(null);
			setInputValue('');
		}
	}, [value, dataDisplay]);

	return (
		<div className={clsx('relative', containerClassName)}>
			<Combobox value={selectedItem} onChange={handleSelectItem}>
				{({ open }) => (
					<div className="relative">
						{loading && (
							<ArrowPathIcon className="absolute inset-y-0 right-10 h-full w-6 animate-spin text-black" />
						)}
						{!loading && inputValue && (
							<XMarkIcon
								className="absolute inset-y-0 right-10 h-full w-6 cursor-pointer"
								onClick={handleClear}
							/>
						)}
						<ComboboxInput
							{...props}
							className={clsx('focus:border-none pl-2 pr-10', className)}
							displayValue={(item: any) => dataDisplay(item)}
							onChange={handleInputChange}
							placeholder={placeholder || ""}
						/>
						<ComboboxButton className="absolute inset-y-0 right-2 flex items-center pr-2 bg-none">
							{open ? (
								<ChevronUpIcon className="h-5 w-5 text-black" />
							) : (
								<ChevronDownIcon className="h-5 w-5 text-black" />
							)}
						</ComboboxButton>
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<ComboboxOptions className="absolute z-10 mt-1 w-full max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{data.length === 0 && inputValue !== '' ? (
									<div className="relative cursor-default select-none py-2 px-4 text-gray-700">
										{labelNoResult || 'Nenhum resultado encontrado'}
									</div>
								) : (
									data.map((item, index) => (
										<ComboboxOption
											key={index}
											className={({ active }) =>
												clsx(
													'relative cursor-default select-none py-2 pl-10 pr-4',
													active ? 'bg-indigo-600 text-white' : 'text-gray-900'
												)
											}
											value={item}
										>
											{({ active, selected }) => (
												<>
													<span
														className={clsx(
															'block truncate',
															selected ? 'font-medium' : 'font-normal'
														)}
													>
														{dataDisplay(item)}
													</span>
													{selected && (
														<span
															className={clsx(
																'absolute inset-y-0 left-0 flex items-center pl-3',
																active ? 'text-white' : 'text-indigo-600'
															)}
														>
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
														</span>
													)}
												</>
											)}
										</ComboboxOption>
									))
								)}
							</ComboboxOptions>
						</Transition>
					</div>
				)}
			</Combobox>
			{showerrors && errors.filter((error) => !error.isValidated).length > 0 && (
				<ul role="list" className="list-disc space-y-1 pl-5">
					{errors
						.filter((error) => !error.isValidated)
						.map((error, index) => (
							<li key={index} className={errorclassname || 'text-red-600'}>
								{error.message}
							</li>
						))}
				</ul>
			)}
		</div>
	);
};

export default CustomAutoComplete;
