import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { PropsWithChildren, useEffect, useState } from 'react'

interface AlertSuccessProps extends PropsWithChildren {
	position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
	title: string;
	items: Array<string>;
	duration: number;
	onClose: any;
}

interface Pos {
	top: string | null;
	right: string | null;
	bottom: string | null;
	left: string | null;
}

function calculatePos(position: string | undefined, distance: string = "50px"): Pos {
	switch (position) {
		case "bottom-left":
			return {
				top: null,
				right: null,
				bottom: distance,
				left: distance,
			}
		case "bottom-right":
			return {
				top: null,
				right: distance,
				bottom: distance,
				left: null,
			}
		case "top-left":
			return {
				top: distance,
				right: null,
				bottom: null,
				left: distance,
			}
		case "top-right":
			return {
				top: distance,
				right: distance,
				bottom: null,
				left: null,
			}

		default:
			return {
				top: null,
				right: null,
				bottom: distance,
				left: distance,
			}
	}
}

export default function AlertSuccess(Props: AlertSuccessProps) {
	const {
		position,
		title,
		items,
		duration = 2000,
		onClose
	} = Props

	const [hidden, setHidden] = useState(true)
	const [pos, setPos] = useState<any>(calculatePos(position))

	useEffect(() => {
		if (items&&items.length) {
			setHidden(false)
			const successTimeout = setTimeout(() => {
				setHidden(true)
				onClose&&onClose()
			}, duration)
			return () => clearTimeout(successTimeout)
		}
	}, [items])

	useEffect(() => {
		setPos(calculatePos(position))
	}, [position])
	
	return (
		<div className="fixed rounded-md bg-green-50 p-4" style={{top: pos.top, right: pos.right, bottom: pos.bottom, left: pos.left}} hidden={hidden}>
			<div className="flex">
				<div className="flex-shrink-0">
					<CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-green-800">{title}</h3>
					<div className="mt-2 text-sm text-green-700">
						<ul role="list" className="list-disc space-y-1 pl-5">
							{
								items.map((success, index) => (
									<li key={index}>{success}</li>
								))
							}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}
