import React, { useState } from "react";

interface TooltipProps {
	children: React.ReactNode;
	message?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, message }) => {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<div
			className="relative flex items-center"
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
		>
			{children}
			{message && showTooltip && (
				<div className="absolute left-1/2 bottom-full mb-3 w-max -translate-x-1/2 px-2 py-1 bg-gray-700 text-white text-xs rounded-md shadow-lg z-50">
					{message}
				</div>
			)}
		</div>
	);
};

export default Tooltip;
