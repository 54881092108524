import axios from 'axios';

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  timeout: 30000
});

export const loginConfirmation = async (username: string, code: string, session: string, type: 'sms' | 'softwareToken') => {
  try {
    const response = await apiClient.post('/auth/log-in/confirmation', { username, code, session, type });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async (_refreshToken: string) => {
  try {
    const response = await apiClient.post('/auth/refresh', { refreshToken: _refreshToken });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await apiClient.post('/auth/log-out');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (username: string) => {
  try {
    const response = await apiClient.post('/auth/forgot-password', { username });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyAttribute = async (confirmationCode: string, attributeName: string) => {
  try {
    const response = await apiClient.post('/auth/verify-attribute', { confirmationCode, attributeName });
    return response.data;
  } catch (error) {
    throw error;
  }
};

interface User {
  name: string;
  email: string;
  phone: string;
  document: string;
  profileImageKey?: string;
}


export const getUserById = async (userId: string) => {
  try {
    const response = await apiClient.get(`/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserById = async (userId: string, userData: User) => {
  try {
    const response = await apiClient.put(`/user/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUserById = async (userId: string) => {
  try {
    const response = await apiClient.delete(`/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addMemberToGroup = async (userId: string, groupId: string) => {
  try {
    const response = await apiClient.post('/add-member-to-group', { groupId, userId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeMemberFromGroup = async (userId: string, groupId: string) => {
  try {
    const response = await apiClient.post('/remove-member-from-group', { groupId, userId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listUsersNotMembers = async (groupId: string, limit: number, lastIndex: string | null = null, search: string = "") => {
  try {
    const params = new URLSearchParams();
    params.append('limit', `${limit}`);
    params.append('groupId', `${groupId}`);
    if (lastIndex) {
      params.append('lastIndex', lastIndex);
    }
    if (search) {
      params.append('search', search);
    }
    const response = await apiClient.get('/group/users/not-members', { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Device Functions
export const createDevice = async (deviceData: any) => {
  try {
    const response = await apiClient.post('/device', deviceData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createOwnDevice = async (deviceData: any) => {
  try {
    const response = await apiClient.post('/device/own', deviceData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listDevices = async (userId: string, limit: number, lastIndex: string | null = null, search: string = "") => {
  try {
    const params = new URLSearchParams();
    params.append('limit', `${limit}`);
    if (lastIndex) {
      params.append('lastIndex', lastIndex);
    }
    if (search) {
      params.append('search', search);
    }
    const response = await apiClient.get(`/devices/user/${userId}`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listOwnDevices = async (limit: number, lastIndex: string | null = null, search: string = "") => {
  try {
    const params = new URLSearchParams();
    params.append('limit', `${limit}`);
    if (lastIndex) {
      params.append('lastIndex', lastIndex);
    }
    if (search) {
      params.append('search', search);
    }
    const response = await apiClient.get(`/devices/own`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const readDevice = async (userId: string, deviceId: string) => {
  try {
    const response = await apiClient.get(`/device/${deviceId}/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const readOwnDevice = async (deviceId: string) => {
  try {
    const response = await apiClient.get(`/device/${deviceId}/own`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDevice = async (userId: string, deviceId: string, deviceData: any) => {
  try {
    const response = await apiClient.put(`/device/${deviceId}/user/${userId}`, deviceData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOwnDevice = async (deviceId: string, deviceData: any) => {
  try {
    const response = await apiClient.put(`/device/${deviceId}/own`, deviceData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDevice = async (userId: string, deviceId: string) => {
  try {
    const response = await apiClient.delete(`/device/${deviceId}/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteOwnDevice = async (deviceId: string) => {
  try {
    const response = await apiClient.delete(`/device/${deviceId}/own`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDeviceData = async (userId: string, deviceId: string) => {
  try {
    const response = await apiClient.get(`/device/${deviceId}/deviceData/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOwnDeviceData = async (deviceId: string) => {
  try {
    const response = await apiClient.get(`/device/${deviceId}/deviceData/own`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDeviceDataReport = async (userId: string, deviceId: string, startDate: string, endDate: string) => {
  try {
    const params = new URLSearchParams();
    params.append('startDate', `${startDate}`);
    params.append('endDate', `${endDate}`);
    const response = await apiClient.get(`/device/${deviceId}/deviceData/user/${userId}/report`, {params});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOwnDeviceDataReport = async (deviceId: string, startDate: string, endDate: string) => {
  try {
    const params = new URLSearchParams();
    params.append('startDate', `${startDate}`);
    params.append('endDate', `${endDate}`);
    const response = await apiClient.get(`/device/${deviceId}/deviceData/own/report`, {params});
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const createUser = async (userData: any) => {
  try {
    const response = await apiClient.post('/auth/user', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Resend Confirmation Code (for MFA)
export const resendConfirmationCode = async (username: string) => {
  try {
    const response = await apiClient.post('/auth/resend-confirmation-code', { username });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Associate Software Token (for MFA)
export const associateSoftwareToken = async (session: string) => {
  try {
    const response = await apiClient.post('/auth/associate-software-token', { session });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Confirm Software Token (for MFA)
export const confirmSoftwareToken = async (session: string, userCode: string) => {
  try {
    const response = await apiClient.post('/auth/confirm-software-token', { session, userCode });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Enable MFA (Multi-Factor Authentication)
export const enableMFA = async (accessToken: string) => {
  try {
    const response = await apiClient.post('/auth/enable-mfa', { accessToken });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Disable MFA
export const disableMFA = async (accessToken: string) => {
  try {
    const response = await apiClient.post('/auth/disable-mfa', { accessToken });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Respond to MFA Challenge (Challenge for authentication)
export const respondToMFAChallenge = async (challengeName: string, session: string, code: string) => {
  try {
    const response = await apiClient.post('/auth/respond-to-mfa-challenge', { challengeName, session, code });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Attribute Confirmation (Check confirmation status of user attributes like email or phone)
export const getAttributeConfirmation = async (attributeName: string) => {
  try {
    const response = await apiClient.post('/auth/get-attribute-confirmation', { attributeName });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Auth Functions (existing)
export const login = async (username: string, password: string) => {
  try {
    const response = await apiClient.post('/auth/log-in', { username, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// New Auth Functions based on OpenAPI

export const confirmLoginWithMFA = async (username: string, session: string, code: string, type: 'sms' | 'softwareToken') => {
  try {
    const response = await apiClient.post('/auth/log-in/confirmation', { username, session, code, type });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const refreshAuthToken = async (refreshAuthToken: string) => {
  try {
    const response = await apiClient.post('/auth/refresh', { refreshToken: refreshAuthToken });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logOut = async () => {
  try {
    const response = await apiClient.post('/auth/log-out');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Forgot Password Flow
export const forgotPasswordRequest = async (username: string, email?: string, phone?: string) => {
  try {
    const response = await apiClient.post('/auth/forgot-password', { username, email, phone });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmForgotPassword = async (username: string, confirmationCode: string, password: string) => {
  try {
    const response = await apiClient.post('/auth/confirm-forgot-password', { username, confirmationCode, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// New Password Required (Admin Flow)
export const newPasswordRequired = async (username: string, password: string, session: string, name: string, phone: string, email: string, document: string) => {
  try {
    const response = await apiClient.post('/auth/new-password-required', { username, password, session, name, phone, email, document });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Verify Attribute (Email or Phone)
export const verifyUserAttribute = async (confirmationCode: string, attributeName: 'email' | 'phone') => {
  try {
    const response = await apiClient.post('/auth/verify-attribute', { confirmationCode, attributeName });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Password Update Flow
export const updatePassword = async (previousPassword: string, proposedPassword: string) => {
  try {
    const response = await apiClient.post('/auth/update-password', { previousPassword, proposedPassword });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// User Management
export const listUsers = async (limit: number, lastIndex: string | null = null, search: string = "") => {
  try {
    const params = new URLSearchParams({ limit: `${limit}` });
    if (lastIndex) params.append('lastIndex', lastIndex);
    if (search) params.append('search', search);

    const response = await apiClient.get('/auth/users', { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserByUsername = async (username: string) => {
  try {
    const response = await apiClient.get(`/auth/user/${username}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserByUsername = async (username: string, userData: any) => {
  try {
    const response = await apiClient.put(`/auth/user/${username}`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOwnUser = async (userData: any) => {
  try {
    const response = await apiClient.put(`/auth/user/own`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUserByUsername = async (username: string) => {
  try {
    const response = await apiClient.delete(`/auth/user/${username}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Group Management
export const listGroups = async () => {
  try {
    const response = await apiClient.get('/auth/groups');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listUsersInGroup = async (groupName: string, limit: number, lastIndex: string | null = null, search: string = "") => {
  try {
    const params = new URLSearchParams({ groupName, limit: `${limit}` });
    if (lastIndex) params.append('lastIndex', lastIndex);
    if (search) params.append('search', search);

    const response = await apiClient.get('/auth/group/users', { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listUsersOutsideGroup = async (groupName: string, limit: number, lastIndex: string | null = null, search: string = "") => {
  try {
    const params = new URLSearchParams({ groupName, limit: `${limit}` });
    if (lastIndex) params.append('lastIndex', lastIndex);
    if (search) params.append('search', search);

    const response = await apiClient.get('/auth/group/users/not-users', { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addUserToGroup = async (username: string, groupName: string) => {
  try {
    const response = await apiClient.post('/auth/add-user-to-group', { username, groupName });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeUserFromGroup = async (username: string, groupName: string) => {
  try {
    const response = await apiClient.post('/auth/remove-user-from-group', { username, groupName });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOwnGroup = async () => {
  try {
    const response = await apiClient.get('/auth/group/own');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOwnUser = async () => {
  try {
    const response = await apiClient.get('/auth/user/own');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// File Management
export const uploadFile = async (key: string, metadata?: object) => {
  try {
    const response = await apiClient.post('/file/upload', { key, metadata });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const readFile = async (key: string) => {
  try {
    const response = await apiClient.post('/file/read', { key });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (key: string) => {
  try {
    const response = await apiClient.post('/file/delete', { key });
    return response.data;
  } catch (error) {
    throw error;
  }
};
