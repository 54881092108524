import { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { actions, addUserToGroup, removeUserFromGroup, listUsersInGroup, listGroups, listUsersOutGroup } from "./slice";
import { RootState } from "../../app/store";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import Alert from "../../components/Alert";
import { readProfileImage } from "../Users/slice";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import { SELECT_GROUP } from "../../app/actionTypes";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

const usersInGroupColumns: any = [
	{ title: "ID", type: "string", key: "id", hidden: true },
	{ title: "Imagem de perfil", type: "image", key: "profileImageKey", hidden: false },
	{ title: "Nome", type: "string", key: "name", hidden: false },
];

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export default function Groups() {
	const appDispatch = useAppDispatch()
	const { groups, loadingUsersInGroup, addingOrRemovingUser, selectedGroup, loadingUsersOutGroup, loadingGroups } = useAppSelector((state: RootState) => state.groupsReducer)
	const usersInGroupRef = useRef<any>(null)
	const usersOutGroupRef = useRef<any>(null)

	const handleListGroups = useCallback(async (limit: number, lastIndex: string, search: string) => {
		try {
			const response = await appDispatch(listGroups({ limit, lastIndex, search })).unwrap()
			return response
		} catch { }
	}, [listGroups])

	useEffect(() => {
		if (!selectedGroup) {
			handleListGroups(100, "", "")
		}
	}, [selectedGroup])

	const handleAddUserToGroup = useCallback(async (username: string, groupName: string) => {
		try {
			await appDispatch(addUserToGroup({ username, groupName })).unwrap()
			setIsAddingUser(false)
			setSuccessTitle("Usuário faz parte do grupo")
		} catch (error) {
			setErrorTitle("Erro inesperado ao adicionar usuário")
		}
	}, [addUserToGroup])

	const handleRemoveUserFromGroup = useCallback(async (username: string, groupName: string) => {
		try {
			await appDispatch(removeUserFromGroup({ username, groupName })).unwrap()
			setSuccessTitle("Usuário cadastrado com sucesso")
		} catch (error) {
			setErrorTitle("Erro inesperado ao remover usuário")
		}
	}, [removeUserFromGroup])

	const handleListUsersInGroup = useCallback(async (limit: number, lastIndex: string, search: string) => {
		try {
			const response = await appDispatch(listUsersInGroup({ groupName: selectedGroup.name, limit, lastIndex, search })).unwrap()
			return response
		} catch (error) {
			setErrorTitle("Usuário não encontrado, tente novamente")
		}
	}, [listUsersInGroup, selectedGroup])

	const handleListUsersOutGroup = useCallback(async (groupName: string, limit: number, lastIndex: string, search: string) => {
		try {
			const response = await appDispatch(listUsersOutGroup({ groupName, limit, lastIndex, search })).unwrap()
			return response
		} catch (error) {
			setErrorTitle("Usuário não encontrado, tente novamente")
			throw error
		}
	}, [listUsersInGroup])

	const [errorTitle, setErrorTitle] = useState("")
	const [successTitle, setSuccessTitle] = useState("")
	const [isAddingUser, setIsAddingUser] = useState<boolean>(false)

	useEffect(() => {
		if (selectedGroup) {
			usersInGroupRef.current && usersInGroupRef.current.refresh()
		}
	}, [selectedGroup])

	return (
		<>
			<form className="flex flex-col mt-10 flex-wrap gap-5 pb-10 px-10 max-w-96" onSubmit={(e) => { e.preventDefault() }} noValidate>
				<label className="w-[38.5rem] border-b-[1px] border-[#706f6f]">Selecione um grupo</label>
				<CustomAutoComplete
					containerClassName="min-w-[30rem] sm:min-w-full"
					fetchData={() => Promise.resolve(groups)}
					value={selectedGroup}
					onSelect={(data) => {
						if (data && data.name !== selectedGroup.name) {
							appDispatch(actions[SELECT_GROUP](data))
						} else {
							appDispatch(actions[SELECT_GROUP](null))
						}
					}}
					errors={[]}
					showerrors={false}
					dataDisplay={(data) => data && data.name || ""}
					labelNoResult="Nenhum resultado encontrado"
				/>
			</form>
			<div className={classNames("flex w-full h-full px-10", !selectedGroup && "hidden" || "")}>
				<CustomTable
					ref={usersInGroupRef}
					data={(...args: any) => handleListUsersInGroup(args[0], args[1], args[2])}
					loading={loadingUsersInGroup || addingOrRemovingUser || loadingGroups}
					isSearchable={true}
					title={`Usuários do grupo ${selectedGroup && selectedGroup.name.toLowerCase()}`}
					subtitle={""}
					columns={usersInGroupColumns}
					editLabel={""}
					isRelational={false}
					refresh={true}
					onCreate={() => {
						setIsAddingUser(true)
						return Promise.resolve()
					}}
					onDelete={(rowData) => handleRemoveUserFromGroup(rowData.username, selectedGroup.name)}
					readFileRequest={async (imageKey) => appDispatch(readProfileImage(imageKey)).unwrap()}
					areYouSureLabel="Você tem certeza disso?"
					areYouSureLabelNo="Não"
					areYouSureLabelYes="Sim"
					labelRows="linhas"
				/>
			</div>

			<Dialog open={isAddingUser} onClose={() => {
				setIsAddingUser(false)
			}} className="relative z-10">
				<div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-slate-900 bg-opacity-70">
					<DialogPanel className="w-full max-w-6xl space-y-4 border rounded-lg bg-white p-12 opacity-100">
						<DialogTitle className="font-bold">Adicionar membro ao grupo</DialogTitle>
						<CustomTable
							ref={usersOutGroupRef}
							data={(...args: any) => handleListUsersOutGroup(selectedGroup.name, args[0], args[1], args[2])}
							loading={loadingUsersOutGroup || addingOrRemovingUser}
							isSearchable={true}
							title={`Usuários fora do grupo ${selectedGroup && selectedGroup.name.toLowerCase()}`}
							subtitle={"Selecione um deles"}
							columns={usersInGroupColumns}
							editLabel={""}
							isRelational={false}
							refresh={true}
							readFileRequest={async (imageKey) => appDispatch(readProfileImage(imageKey)).unwrap()}
							areYouSureLabel="Você tem certeza disso?"
							areYouSureLabelNo="Não"
							areYouSureLabelYes="Sim"
							customActions={[
								{
									name: "addToGroup",
									action: async (rowData) => {
										await handleAddUserToGroup(rowData.username, selectedGroup.name)
										usersOutGroupRef.current && usersOutGroupRef.current.refresh()
										usersInGroupRef.current && usersInGroupRef.current.refresh()
									},
									icon: <PlusCircleIcon className="w-8 h-8 text-[#e59e53]" aria-label="Add user to group" />,
									buttonClassName: "bg-none p-0"
								}
							]}
							labelRows="linhas"
						/>
					</DialogPanel>
				</div>
				<Alert onClose={() => {
					setErrorTitle("");
				}} message={errorTitle} duration={5000} position="bottom-right" type={"error"} />
			</Dialog>
			<Alert onClose={() => {
				setErrorTitle("");
			}} message={errorTitle} duration={5000} position="bottom-right" type={"error"} />
			<Alert onClose={() => {
				setSuccessTitle("");
			}} message={successTitle} duration={5000} position="bottom-right" type={"success"} />
		</>
	)
}