import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  login,
  logOut,
  forgotPasswordRequest,
  confirmForgotPassword,
  newPasswordRequired,
  refreshAuthToken,
  confirmLoginWithMFA,
  resendConfirmationCode as _resendConfirmationCode,
  createUser,
  refreshToken,
} from '../../services/backendAPI';
import {
  CLEAR_AUTH_ERROR, FORGET_PASSWORD, MFA, NEW_PASSWORD_REQUIRED,
  REEFRESH_SESSION, REMEMBER_PASSWORD, REQUEST_FORGOT_PASSWORD, RESEND_CONFIRM_SIGN_UP,
  RESET_PASSWORD, SIGN_IN, SIGN_IN_NO_MFA, SIGN_OUT, SIGN_UP, VALIDATE_TOKEN
} from '../../app/actionTypes';
import { jwtDecode } from 'jwt-decode'
import { createInterceptors, removeInterceptors } from '../../services/interceptors';

interface AuthResponse {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

interface LoginPayload {
  username: string;
  password: string;
}

interface LoginConfirmationPayload {
  username: string;
  code: string;
  session: string;
  type: 'sms' | 'softwareToken';
}

interface RegisterPayload {
  name: string;
  document: string;
  phone: string;
  email: string;
  password: string;
}

export const registerUser = createAsyncThunk(SIGN_UP, async (payload: RegisterPayload, thunkAPI) => {
  try {
    const data = await createUser(payload);
    return data;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

export const loginUser = createAsyncThunk(SIGN_IN, async (payload: LoginPayload, thunkAPI) => {
  try {
    const data = await login(payload.username, payload.password);
    if (data.accessToken && data.refreshToken) {
      await removeInterceptors()
      await createInterceptors(data, (session) => refreshToken(session.refreshToken as string))
    }
    return data;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

export const confirmLoginWithMFAUser = createAsyncThunk(MFA, async (payload: LoginConfirmationPayload, thunkAPI) => {
  try {
    const data = await confirmLoginWithMFA(payload.username, payload.code, payload.session, payload.type);
    return data;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

export const logOutUser = createAsyncThunk(SIGN_OUT, async (_, thunkAPI) => {
  try {
    await logOut();
    return;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

export const refreshSession = createAsyncThunk(REEFRESH_SESSION, async (session: any, thunkAPI) => {
  try {
    const data = await refreshAuthToken(session.refreshToken);
    return data;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

export const validateToken = createAsyncThunk(VALIDATE_TOKEN, async ({ session }: any, thunkAPI) => {
  try {
    if (!session) {
      throw new Error("No session available")
    }
    await removeInterceptors()
    await createInterceptors(session, (session) => refreshToken(session.refreshToken as string))
    return session
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

export const requestForgotPassword = createAsyncThunk(REQUEST_FORGOT_PASSWORD, async (username: string, thunkAPI) => {
  try {
    const data = await forgotPasswordRequest(username);
    return data;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

export const resendConfirmationCode = createAsyncThunk(RESEND_CONFIRM_SIGN_UP, async (username: string, thunkAPI) => {
  try {
    await _resendConfirmationCode(username);
    return;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

interface ResetPasswordPayload {
  username: string;
  confirmationCode: string;
  newPassword: string;
}
export const resetPassword = createAsyncThunk(RESET_PASSWORD, async (payload: ResetPasswordPayload, thunkAPI) => {
  try {
    const data = await confirmForgotPassword(payload.username, payload.confirmationCode, payload.newPassword);
    return data;
  } catch (error: any) {
    const errorResponse = error.response?.data
    if (error.response?.status === 400) {
      return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
    } else {
      return thunkAPI.rejectWithValue(errorResponse);
    }
  }
});

interface NewPasswordRequiredPayload {
  username: string;
  password: string;
  document: string;
  email: string;
  phone: string;
  name: string;
  session: string;
}
export const respondNewPasswordRequired = createAsyncThunk(
  NEW_PASSWORD_REQUIRED,
  async (payload: NewPasswordRequiredPayload, thunkAPI) => {
    try {
      const data = await newPasswordRequired(
        payload.username,
        payload.password,
        payload.session,
        payload.name,
        payload.phone,
        payload.email,
        payload.document
      );
      return data;
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  }
);

interface UserState {
  loggedUser: any;
  session: any;
  loading: boolean;
  loadinSignIn: boolean;
  error: string | null;
  rememberPassword: boolean;
  storedUsername: string;
  storedPassword: string;
  expiresAt: Date;
}

const initialState: UserState = {
  loggedUser: null,
  session: null,
  loading: false,
  loadinSignIn: false,
  error: null,
  rememberPassword: false,
  storedUsername: '',
  storedPassword: '',
  expiresAt: new Date()
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    [REMEMBER_PASSWORD]: (
      state,
      {
        payload: { username, password },
      }: PayloadAction<{ username: string; password: string }>,
    ) => {
      state.storedUsername = username
      state.storedPassword = password
      state.rememberPassword = true
    },
    [FORGET_PASSWORD]: (state) => {
      state.storedUsername = ""
      state.storedPassword = ""
      state.rememberPassword = !state.rememberPassword
    },
    [SIGN_IN_NO_MFA]: (state,
      {
        payload,
      }: PayloadAction<AuthResponse>
    ) => {
      state.loading = false;
      state.error = null;
      const jwtUserInfo: any = jwtDecode(payload.idToken);
      state.loggedUser = {
        username: jwtUserInfo["cognito:username"],
        email: jwtUserInfo.email,
        name: jwtUserInfo.name,
        phone: jwtUserInfo.phone_number,
        document: jwtUserInfo.preferred_username
      }
      state.session = {
        accessToken: payload.accessToken,
        expiresIn: payload.expiresIn,
        idToken: payload.idToken,
        refreshToken: payload.refreshToken,
      }
      state.expiresAt = new Date((new Date()).getTime() + payload.expiresIn * 1000);
    },
    [CLEAR_AUTH_ERROR]: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state) => {
        state.loadinSignIn = false;
        state.loading = false;
        state.error = null;
      })
      .addCase(loginUser.pending, (state) => {
        state.loadinSignIn = true;
        state.loading = true;
      })
      .addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.loadinSignIn = false;
      })
      .addCase(confirmLoginWithMFAUser.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.session = payload;
        state.expiresAt = new Date((new Date()).getTime() + payload.expiresIn * 1000);
        const jwtUserInfo: any = jwtDecode(payload.idToken);
        state.loggedUser = {
          username: jwtUserInfo["cognito:username"],
          email: jwtUserInfo.email,
          name: jwtUserInfo.name,
          phone: jwtUserInfo.phone_number,
          document: jwtUserInfo.preferred_username
        }
      })
      .addCase(confirmLoginWithMFAUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmLoginWithMFAUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(logOutUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.loggedUser = null;
        state.session = null;
      })
      .addCase(logOutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logOutUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload?.message;
        state.loading = false;
        state.error = null;
        state.loggedUser = null;
        state.session = null;
      })
      .addCase(requestForgotPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(requestForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestForgotPassword.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(respondNewPasswordRequired.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(respondNewPasswordRequired.pending, (state) => {
        state.loading = true;
      })
      .addCase(respondNewPasswordRequired.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(refreshSession.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        const jwtUserInfo: any = jwtDecode(payload.idToken);
        state.loggedUser = {
          username: jwtUserInfo["cognito:username"],
          email: jwtUserInfo.email,
          name: jwtUserInfo.name,
          phone: jwtUserInfo.phone_number,
          document: jwtUserInfo.preferred_username
        }
        state.session.accessToken = payload.accessToken
        state.session.expiresIn = payload.expiresIn
        state.session.idToken = payload.idToken
        state.expiresAt = new Date((new Date()).getTime() + payload.expiresIn * 1000);
      })
      .addCase(refreshSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshSession.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.session = null;
      })
      .addCase(validateToken.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const jwtUserInfo: any = jwtDecode(payload.idToken);
        state.loggedUser = {
          username: jwtUserInfo["cognito:username"],
          email: jwtUserInfo.email,
          name: jwtUserInfo.name,
          phone: jwtUserInfo.phone_number,
          document: jwtUserInfo.preferred_username
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(validateToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateToken.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(resendConfirmationCode.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(resendConfirmationCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendConfirmationCode.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });

  },
});

export const authActions = authSlice.actions
export default authSlice.reducer;
