import { UserCircleIcon } from '@heroicons/react/20/solid';
import React, { useRef, useState } from 'react';
import ImageByKey from '../ImageByKey';
import CustomButton from '../CustomButton';
import Loader from '../Loader';

interface ImageFormFieldProps {
  onFileSelect: (file: File) => Promise<void>;
  imageKey: string;
  readFileRequest?: (imageKey: string) => Promise<{ url: string }>;
  labelText: string;
  buttonText: string;
  imageClassName?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ImageFormField: React.FC<ImageFormFieldProps> = ({ onFileSelect, imageKey, readFileRequest, labelText, buttonText, imageClassName }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const [loading, setLoadind] = useState(false)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setLoadind(true)
      onFileSelect(file)
        .catch(() => { })
        .finally(() => {
          setLoadind(false)
        })
    } else {
      console.error('Selected file is not an image');
    }
  };

  return (
    <div className="col-span-full">
      <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
        {labelText}
      </label>
      <div className="mt-1 h-12 flex items-center gap-x-3">
        <div className='h-12 w-12'>
          {loading ?
            <Loader width={"100%"} height={"100%"} />
            :
            imageKey ?
              <ImageByKey
                imageKey={imageKey}
                readFileRequest={readFileRequest}
                className={classNames("h-full w-full text-gray-300 rounded-[3rem] object-contain", imageClassName || "")}
              />
              :
              <UserCircleIcon className="h-full w-full text-gray-300" aria-hidden="true" />
          }
        </div>
        <CustomButton
          type="button"
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={handleButtonClick}
        >
          {buttonText}
        </CustomButton>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default ImageFormField;
