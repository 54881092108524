/**
 * COMMON
 */

export const SET_IS_LOADING = "COMMON/SET_IS_LOADING"
export const SET_PROGRESS_VALUE = "COMMON/SET_PROGRESS_VALUE"
export const GET_CURRENT_USER = "COMMON/GET_CURRENT_USER"
export const GET_CURRENT_GROUP = "COMMON/GET_CURRENT_GROUP"
export const SET_CURRENT_USER = "COMMON/SET_CURRENT_USER"
export const UPDATE_LOGGED_USER_ATTRIBUTES = "COMMON/UPDATE_LOGGED_USER_ATTRIBUTES"
export const READ_PROFILE_IMAGE_COMMON = "COMMON/READ_PROFILE_IMAGE"
export const RESET_INFO = "COMMON/RESET_INFO"
export const CHECK_SESSION = "COMMON/CHECK_SESSION"

/**
 * AUTH PAGE
 */

export const FORGOT_PASSWORD = "SIGN-IN/FORGOT_PASSWORD"
export const COMPLETE_NEW_PASSWORD = "SIGN-IN/COMPLETE_NEW_PASSWORD"
export const TOGGLE_REMEMBER_PASSWORD = "SIGN-IN/TOGGLE_REMEMBER_PASSWORD"
export const VALIDATE_TOKEN = "AUTH/VALIDATE_TOKEN"
export const SIGN_UP = "AUTH/SIGN_UP"
export const SIGN_IN = "AUTH/SIGN_IN"
export const SIGN_IN_NO_MFA = "AUTH/SIGN_IN_NO_MFA"
export const MFA = "AUTH/MFA"
export const SIGN_OUT = "AUTH/SIGN_OUT"
export const REEFRESH_SESSION = "AUTH/REEFRESH_SESSION"
export const REQUEST_FORGOT_PASSWORD = "AUTH/REQUEST_FORGOT_PASSWORD"
export const RESET_PASSWORD = "AUTH/RESET_PASSWORD"
export const NEW_PASSWORD_REQUIRED = "AUTH/NEW_PASSWORD_REQUIRED"
export const CONFIRM_SIGN_UP = "AUTH/CONFIRM_SIGN_UP"
export const RESEND_CONFIRM_SIGN_UP = "AUTH/RESEND_CONFIRM_SIGN_UP"
export const RESPOND_NEW_PASSWORD_REQUIRED = "AUTH/RESPOND_NEW_PASSWORD_REQUIRED"
export const REMEMBER_PASSWORD = "AUTH/REMEMBER_PASSWORD"
export const FORGET_PASSWORD = "AUTH/FORGET_PASSWORD"
export const SAVE_USERNAME_PASSWORD = "AUTH/SAVE_USERNAME_PASSWORD"
export const GET_USER = "AUTH/GET_USER"
export const CLEAR_AUTH_ERROR = "AUTH/CLEAR_AUTH_ERROR"
export const CLEAR_SESSION_CHECKED = "AUTH/CLEAR_SESSION_CHECKED"

/**
 * SPLASH PAGE
 */

export const TOGGLE_IS_LOADING = "SPLASH/TOGGLE_IS_LOADING"

/**
 * HOME PAGE
 */

export const SELECT_DEVICE = "HOME/SELECT_DEVICE"
export const READ_LATEST_DEVICE_DATA = "HOME/READ_LATEST_DEVICE_DATA"
export const READ_OWN_LATEST_DEVICE_DATA = "HOME/READ_OWN_LATEST_DEVICE_DATA"
export const READ_DEVICE_IMAGE_HOME = "HOME/READ_DEVICE_IMAGE_HOME"
export const LIST_DEVICE_DATA = "HOME/LIST_DEVICE_DATA"
export const LIST_OWN_DEVICE_DATA = "HOME/LIST_OWN_DEVICE_DATA"


/**
 * PROFILE PAGE
 */

export const READ_MY_USER = "PROFILE/READ_MY_USER"
export const READ_MY_GROUP = "PROFILE/READ_MY_GROUP"
export const READ_OTHER_USER = "PROFILE/READ_OTHER_USER"
export const READ_OTHER_USER_GROUP = "PROFILE/READ_OTHER_USER_GROUP"
export const UPDATE_MY_USER_ATTRIBUTES = "PROFILE/UPDATE_MY_USER_ATTRIBUTES"
export const UPDATE_OTHER_USER_ATTRIBUTES = "PROFILE/UPDATE_OTHER_USER_ATTRIBUTES"
export const LIST_USERS = "PROFILE/LIST_USERS"
export const UPLOAD_PROFILE_IMAGE = "PROFILE/UPLOAD_PROFILE_IMAGE"
export const READ_PROFILE_IMAGE = "PROFILE/READ_PROFILE_IMAGE"
export const UPDATE_PASSWORD = "PROFILE/UPDATE_PASSWORD"
export const SET_CURRENT_USER_PROFILE = "PROFILE/SET_CURRENT_USER_PROFILE"

/**
 * USERS PAGE
 */

export const CREATE_SYSTEM_USER = "USERS/CREATE_SYSTEM_USER"
export const READ_SYSTEM_USER = "USERS/READ_SYSTEM_USER"
export const LIST_SYSTEM_USERS = "USERS/LIST_SYSTEM_USERS"
export const UPDATE_SYSTEM_USER = "USERS/UPDATE_SYSTEM_USER"
export const DELETE_SYSTEM_USER = "USERS/DELETE_SYSTEM_USER"
export const UPLOAD_USERS_IMAGE = "USERS/UPLOAD_USERS_IMAGE"
export const READ_USERS_IMAGE = "USERS/READ_USERS_IMAGE"
export const USERS_RESET = "USERS/USERS_RESET"

/**
 * GROUPS PAGE
 */

export const ADD_MEMBER_TO_GROUP = "GROUPS/ADD_MEMBER_TO_GROUP"
export const REMOVE_MEMBER_FROM_GROUP = "GROUPS/REMOVE_MEMBER_FROM_GROUP"
export const LIST_GROUPS = "GROUPS/LIST_GROUPS"
export const LIST_GROUP_USERS = "GROUPS/LIST_GROUP_USERS"
export const SELECT_GROUP = "GROUPS/SELECT_GROUP"
export const LIST_USERS_NOT_MEMBERS = "GROUPS/LIST_USERS_NOT_MEMBERS"

/**
 * DEVICES PAGE
 */

export const CREATE_DEVICE = "DEVICES/CREATE_DEVICE"
export const LIST_DEVICES = "DEVICES/LIST_DEVICES"
export const READ_DEVICE = "DEVICES/READ_DEVICE"
export const UPDATE_DEVICE = "DEVICES/UPDATE_DEVICE"
export const DELETE_DEVICE = "DEVICES/DELETE_DEVICE"
export const CREATE_OWN_DEVICE = "DEVICES/CREATE_OWN_DEVICE"
export const LIST_OWN_DEVICES = "DEVICES/LIST_OWN_DEVICES"
export const READ_OWN_DEVICE = "DEVICES/READ_OWN_DEVICE"
export const UPDATE_OWN_DEVICE = "DEVICES/UPDATE_OWN_DEVICE"
export const DELETE_OWN_DEVICE = "DEVICES/DELETE_OWN_DEVICE"
export const DEVICES_RESET = "DEVICES/DEVICES_RESET"
export const UPLOAD_DEVICE_IMAGE = "DEVICES/UPLOAD_DEVICE_IMAGE"
export const READ_DEVICE_IMAGE = "DEVICES/READ_DEVICE_IMAGE"
