import { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../../components/CustomTable";
import { useAppDispatch, useAppSelector, useWindowSize } from "../../app/hooks";
import { actions, createDevice, deleteDevice, listDevices, readDevice, updateDevice, createOwnDevice, deleteOwnDevice, listOwnDevices, readOwnDevice, updateOwnDevice, uploadDeviceImage, readDeviceImage } from "./slice";
import { actions as homeActions } from "../Home/slice"
import { RootState } from "../../app/store";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import FormField from "../../components/FormField";
import Loader from "../../components/Loader";
import AlertError from "../../components/AlertError";
import AlertSuccess from "../../components/AlertSuccess";
import LoadingButton from "../../components/LoadingButton";
import { DEVICES_RESET, SELECT_DEVICE, SET_CURRENT_USER_PROFILE } from "../../app/actionTypes";
import { EyeIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import RoundButton from "../../components/RoundButton";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import ImageFormField from "../../components/ImageFormField";
import { actions as actionsProfile } from '../../pages/Profile/slice';

type DeviceFormData = {
	id: string;
	userId: string;
	name: string;
	nodeId: string;
	desktopName: string;
	createdAt: string;
	updatedAt: string;
	config: any;
	deviceImageKey: string;
};

const validation = (values: Partial<DeviceFormData>, key: keyof DeviceFormData | "all"): { name: string, errors: Array<{ isValidated: boolean, message: string }> } => {
	if (!values) {
		return {
			name: "",
			errors: []
		}
	}

	switch (key) {
		case 'name':
			return ({
				name: "Nome",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					}
				]
			});
		case 'nodeId':
			return ({
				name: "Node ID",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					}
				]
			});
		case 'desktopName':
			return ({
				name: "Desktop Name",
				errors: [
					{
						isValidated: !!values[key],
						message: "Este campo é requerido"
					}
				]
			});
		case 'all':
			let errors: any = [];
			for (let _key of Object.keys(values) as Array<keyof DeviceFormData>) {
				const validations = validation(values, _key);
				const newErrors = validations.errors.filter((error: any) => !error.isValidated);
				errors = [...errors, ...newErrors.map((error) => ({ ...error, message: `${validations.name}: ${error.message}` }))];
			}
			return {
				name: "Validação dos campos",
				errors
			};
		default:
			return {
				name: "",
				errors: []
			};
	}
};

const DEFAULT_FORM_DATA: DeviceFormData = {
	id: "",
	userId: "",
	name: "",
	nodeId: "",
	desktopName: "",
	createdAt: "",
	updatedAt: "",
	config: {},
	deviceImageKey: ""
};

export default function Devices() {
	const windowSize = useWindowSize()
	const deviceColumns: any = useRef([
		{ title: "ID", type: "string", key: "id", hidden: true },
		{ title: "ID do usuário", type: "string", key: "userId", hidden: true },
		{ title: "Imagem", type: "image", key: "deviceImageKey", hidden: false },
		{ title: "Nome", type: "string", key: "name", hidden: false },
		{ title: "Id do nó", type: "string", key: "nodeId", hidden: false },
		{ title: "Nome do computador", type: "string", key: "desktopName", hidden: false },
		{ title: "Criado em", type: "date-time", key: "createdAt", hidden: false },
		{ title: "Atualizado em", type: "date-time", key: "updatedAt", hidden: false },
	]);

	useEffect(() => {
		if (windowSize.width < windowSize.height) {
			deviceColumns.current[3].hidden = true
			deviceColumns.current[4].hidden = true
			deviceColumns.current[5].hidden = true
			deviceColumns.current[7].hidden = true
		} else {
			deviceColumns.current[3].hidden = false
			deviceColumns.current[4].hidden = false
			deviceColumns.current[5].hidden = false
			deviceColumns.current[7].hidden = false
		}
	}, [windowSize])
	const navigate = useNavigate()
	const appDispatch = useAppDispatch();
	const { loadingDevices, readingDevice, creatingDevice, updatingDevice, deletingDevice } = useAppSelector((state: RootState) => state.devicesReducer);
	const { user }: any = useAppSelector((state: RootState) => state.commonReducer);
	const { currentUser } = useAppSelector((state: RootState) => state.profileReducer);
	const [formData, setFormData] = useState<DeviceFormData>(DEFAULT_FORM_DATA);
	const devicesTableRef = useRef<any>(null);
	const [device, setDevice] = useState<DeviceFormData | null>(null);
	const [isCreatingDevice, setIsCreatingDevice] = useState<boolean>(false);
	const [isUpdatingDevice, setIsUpdatingDevice] = useState<boolean>(false);

	const isOwnUser = !currentUser || user.id === currentUser.id;

	useEffect(() => {
		if (!currentUser) {
			appDispatch(actionsProfile[SET_CURRENT_USER_PROFILE](user))
		}
	}, [])

	useEffect(() => {
		if (loadingDevices) {
			appDispatch(actions[DEVICES_RESET]());
		}
	}, []);

	const handleListDevices = useCallback(async (limit: number, lastIndex: string | null, search: string) => {
		const response = isOwnUser
			? await appDispatch(listOwnDevices({ limit, lastIndex, search })).unwrap()
			: await appDispatch(listDevices({ userId: currentUser.id, limit, lastIndex, search })).unwrap();
		return response;
	}, [isOwnUser, user.id, currentUser, listOwnDevices, listDevices]);

	const resetForms = useCallback(() => {
		setIsCreatingDevice(false);
		setIsUpdatingDevice(false);
		setFormData(DEFAULT_FORM_DATA);
		setDevice(null);
	}, []);

	const handleCreateDevice = useCallback(async (device: DeviceFormData) => {
		const _validation = validation(device, "all");
		setShowErrors(true);
		if (_validation.errors.length) {
			setErrorTitle("Erro ao criar dispositivo");
			setErrors(_validation.errors.map((error) => error.message));
		} else {
			try {
				const _device: any = {
					name: device.name,
					nodeId: device.nodeId,
					desktopName: device.desktopName,
					config: device.config
				}
				if (device.deviceImageKey) {
					_device.deviceImageKey = device.deviceImageKey
				}
				if (isOwnUser) {
					await appDispatch(createOwnDevice(_device)).unwrap();
				} else {
					await appDispatch(createDevice({ ..._device, userId: currentUser.id })).unwrap();
				}
				setSuccessTitle("Dispositivo cadastrado com sucesso");
				setSuccessItems(["Tudo certo!"]);
				resetForms();
				devicesTableRef.current && devicesTableRef.current.refresh();
			} catch (error) {
				setErrorTitle("Erro ao criar dispositivo");
				setErrors(["Erro inesperado ao criar dispositivo"]);
			}
		}
	}, [isOwnUser, createOwnDevice, createDevice, currentUser, devicesTableRef]);

	const handleReadDevice = useCallback(async (deviceId: string) => {
		try {
			const response = isOwnUser
				? await appDispatch(readOwnDevice(deviceId)).unwrap()
				: await appDispatch(readDevice({ userId: currentUser.id, deviceId })).unwrap();
			setLimits(response.config?.limits || []);
			setFormData(response);
			return response;
		} catch (error) {
			resetForms();
			setIsUpdatingDevice(false);
			setErrorTitle("Erro ao buscar dispositivo");
			setErrors(["Dispositivo não encontrado, tente novamente"]);
		}
	}, [isOwnUser, user.id, currentUser, readOwnDevice, readDevice]);

	const handleUpdateDevice = useCallback(async (deviceId: string, device: DeviceFormData) => {
		try {
			const _device: any = {
				name: device.name,
				nodeId: device.nodeId,
				desktopName: device.desktopName,
				config: device.config
			}
			if (device.deviceImageKey) {
				_device.deviceImageKey = device.deviceImageKey
			}
			const response = isOwnUser
				? await appDispatch(updateOwnDevice({ deviceId, deviceData: _device })).unwrap()
				: await appDispatch(updateDevice({ userId: currentUser.id, deviceId, deviceData: _device })).unwrap();
			resetForms();
			setSuccessTitle("Dispositivo atualizado com sucesso");
			setSuccessItems(["Sucesso!"]);
			devicesTableRef.current && devicesTableRef.current.refresh();
			return response;
		} catch (error: any) {
			setErrorTitle("Erro ao atualizar dispositivo");
			if (error.code === "#DuplicatedVariable") {
				setErrors([`A variável ${error.message.split(' ')} já existe`]);
			} else {
				setErrors(["Erro inesperado"]);
			}
		}
	}, [isOwnUser, updateOwnDevice, updateDevice, currentUser, resetForms, devicesTableRef]);

	const handleDeleteDevice = useCallback(async (deviceId: string) => {
		try {
			const response = isOwnUser
				? await appDispatch(deleteOwnDevice(deviceId)).unwrap()
				: await appDispatch(deleteDevice({ userId: currentUser.id, deviceId })).unwrap();
			return response;
		} catch (error: any) {
			setErrorTitle("Erro ao apagar dispositivo");
			if (error.code === "#CanNotDeleteAdminOrYourself") {
				setErrors(["Você não pode apagar o usuário administrador ou você mesmo"]);
			} else {
				setErrors(["Erro inesperado"]);
			}
		}
	}, [isOwnUser, deleteOwnDevice, deleteDevice, currentUser]);

	const [errorTitle, setErrorTitle] = useState("");
	const [errors, setErrors] = useState<string[]>([]);
	const [successTitle, setSuccessTitle] = useState("");
	const [successItems, setSuccessItems] = useState<string[]>([]);
	const [showErrors, setShowErrors] = useState<boolean>(false);
	const [limits, setLimits] = useState<{ key: string, value: any }[]>([]);
	useEffect(() => {
		if (JSON.stringify(limits) !== JSON.stringify(formData&&formData.config.limits)) {
			setFormData({
				...formData,
				config: {
					...(formData || {config: {}}).config,
					limits
				}
			})
		}
	}, [limits, formData])

	useEffect(() => {
		if (isUpdatingDevice && device) {
			handleReadDevice(device.id)
				.then((response: any) => {
					setFormData(response);
				})
				.catch(() => {
					setFormData(DEFAULT_FORM_DATA);
					setErrorTitle("Erro ao ler dispositivo");
					setErrors(["Tente novamente"]);
				});
		}
	}, [isUpdatingDevice, device, handleReadDevice]);

	const handleSelectImage = useCallback(async (image: File) => {
		try {
			const imageKey = uuidv4()
			const response = await appDispatch(uploadDeviceImage({ imageKey, file: image })).unwrap()
			setFormData({
				...formData,
				deviceImageKey: imageKey
			})
			return response
		} catch (error) {
			return;
		}
	}, [formData])

	if (!currentUser) {
		return <></>
	}

	return (
		<>
			<div className="flex w-full h-full min-w-[320px]">
				<CustomTable
					ref={devicesTableRef}
					data={handleListDevices}
					loading={loadingDevices || deletingDevice}
					isSearchable={true}
					title={"Dispositivos"}
					subtitle={"Todos os dispositivos do sistema"}
					columns={deviceColumns.current}
					editLabel={""}
					isRelational={false}
					refresh={true}
					onCreate={() => {
						setIsCreatingDevice(true);
						return Promise.resolve();
					}}
					onUpdate={(rowData: any) => {
						setDevice(rowData);
						setIsUpdatingDevice(true);
						return Promise.resolve();
					}}
					onDelete={(rowData) => handleDeleteDevice(rowData.id)}
					areYouSureLabel="Você tem certeza disso?"
					areYouSureLabelNo="Não"
					areYouSureLabelYes="Sim"
					customActions={[
						{
							name: "SeeDeviceData",
							action: async (rowData) => {
								appDispatch(homeActions[SELECT_DEVICE](rowData))
								navigate('/home')
							},
							icon: <EyeIcon className="w-[24px] h-[24px] text-black" aria-label="See Data from device" />,
						}
					]}
					readFileRequest={async (imageKey) => appDispatch(readDeviceImage(imageKey)).unwrap()}
				/>
			</div>
			<Dialog open={isCreatingDevice || isUpdatingDevice} onClose={() => {
				setIsCreatingDevice(false);
				setIsUpdatingDevice(false);
				setFormData(DEFAULT_FORM_DATA);
			}} className="relative z-10">
				<div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-slate-900 bg-opacity-70">
					<DialogPanel className="w-full max-w-6xl space-y-4 border rounded-lg bg-white p-12 opacity-100">
						<DialogTitle className="font-bold">{isCreatingDevice ? "Cadastro de dispositivo" : "Atualização de dispositivo"}</DialogTitle>
						{readingDevice ?
							<Loader width={24} height={24} />
							:
							<form className="flex flex-wrap gap-10 sm:px-2 p-5"
								onSubmit={(e) => {
									e.preventDefault();
									isCreatingDevice ? handleCreateDevice(formData) : handleUpdateDevice(formData.id, formData);
								}}
								noValidate
								hidden={isCreatingDevice && isUpdatingDevice}
							>
								<ImageFormField
									onFileSelect={async (image) => {
										await handleSelectImage(image)
									}}
									imageKey={formData && formData.deviceImageKey || ""}
									readFileRequest={async (imageKey) => appDispatch(readDeviceImage(imageKey)).unwrap()}
									labelText={"Imagem do dispositivo"}
									buttonText={"Mudar"}
								/>
								<FormField
									id="name"
									name="name"
									type="text"
									required
									onChange={(e: any) => setFormData({ ...formData, name: e.target.value })}
									label="Nome"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[30rem] sm:min-w-full"
									errors={validation(formData, 'name').errors}
									showerrors={!!showErrors}
									value={formData && formData.name || ""}
								/>
								<FormField
									id="nodeId"
									name="nodeId"
									type="text"
									required
									onChange={(e: any) => setFormData({ ...formData, nodeId: e.target.value })}
									label="Node ID"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[30rem] sm:min-w-full"
									errors={validation(formData, 'nodeId').errors}
									showerrors={!!showErrors}
									value={formData&&formData.nodeId}
								/>
								<FormField
									id="desktopName"
									name="desktopName"
									type="text"
									required
									onChange={(e: any) => setFormData({ ...formData, desktopName: e.target.value })}
									label="Desktop Name"
									labelclassname="block text-sm font-medium leading-6 text-gray-900"
									errorclassname="text-sm text-red-500"
									containerClassName="min-w-[30rem] sm:min-w-full"
									errors={validation(formData, 'desktopName').errors}
									showerrors={!!showErrors}
									value={formData&&formData.desktopName}
								/>
								<div className="w-full flex flex-row items-center justify-left gap-10">
									<label>
										Limites:
									</label>
									<RoundButton
										type="button"
										onClick={() => {
											setLimits([...limits, {
												key: "chave" + limits.length,
												value: "valor" + limits.length,
											}])
										}}
									>
										<PlusIcon width={24} height={24} className="text-white" />
									</RoundButton>
								</div>
								{limits.map((limit, index) => (
									<div className="w-full flex flex-row justify-around gap-10 items-end" key={index}>
										<FormField
											id={limit.key}
											name="key"
											type="text"
											required
											onChange={(e) => {
												const _limits = [...limits];
												_limits[index].key = e.target.value;
												setLimits(_limits);
											}}
											label="Chave"
											labelclassname="block text-sm font-medium leading-6 text-gray-900"
											errorclassname="text-sm text-red-500"
											containerClassName="w-1/2"
											errors={!limit.key ? [{ isValidated: false, message: "Este campo é requerido" }] : []}
											showerrors={!!showErrors}
											value={limit.key}
										/>
										<FormField
											id={limit.value}
											name="value"
											type="text"
											required
											onChange={(e) => {
												const _limits = [...limits];
												_limits[index].value = e.target.value;
												setLimits(_limits);
											}}
											label="Valor"
											labelclassname="block text-sm font-medium leading-6 text-gray-900"
											errorclassname="text-sm text-red-500"
											containerClassName="w-1/2"
											errors={!limit.value ? [{ isValidated: false, message: "Este campo é requerido" }] : []}
											showerrors={!!showErrors}
											value={limit.value}
										/>
										<RoundButton
											type="button"
											onClick={() => {
												const _limits = [...limits];
												_limits.splice(index, 1);
												setLimits(_limits);
											}}
											className="w-10 h-10 bg-none bg-red-600"
										>
											<XMarkIcon width={24} height={24} className="text-white" />
										</RoundButton>
									</div>
								))}
								<div className="w-full sm:min-w-full">
									<LoadingButton
										type="submit"
										className="flex w-full justify-center rounded-md bg-[#4E2581] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#b691e2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4E2581] group-invalid:pointer-events-none group-invalid:opacity-30"
										isloading={creatingDevice || updatingDevice}
									>
										{isCreatingDevice ? "Criar dispositivo" : "Atualizar dados do dispositivo"}
									</LoadingButton>
								</div>
							</form>
						}
					</DialogPanel>
				</div>
				<AlertError onClose={() => {
					setErrors([]);
				}} errorTitle={errorTitle} errors={errors} duration={5000} position="bottom-right" />
			</Dialog>
			<AlertError onClose={() => {
				setErrors([]);
			}} errorTitle={errorTitle} errors={errors} duration={5000} position="bottom-right" />
			<AlertSuccess onClose={() => {
				setSuccessItems([]);
			}} title={successTitle} items={successItems} duration={5000} position="bottom-right" />
		</>
	);
}
