export const maskCpf = (text: string) => {
  if (!text) {
    return ""
  }
  if (text.replace(/\D/g, "").length <= 11) {
    return text
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
  } else {
    return text
      .replace(/\D/g, "")
      .replace(/[.]/g, "")
      .replace(/[-]/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/([.]\d{3})(\d)/, "$1/$2")
      .replace(/([/]\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }
}

export const maskCpfCNPJ = (text: string) => {
  if (!text) {
    return ""
  }
  if (text.replace(/\D/g, "").length <= 11) {
    return text
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
  } else {
    return text.slice(0,14)
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/([.]\d{3})(\d)/, "$1/$2")
      .replace(/([/]\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }
}

export const maskPhone = (text: string) => {
  return text
    .replace(/\D/g, "")
    .slice(0,13)
    .replace(/(\d{2})(\d)/, "$1 $2")
    .replace(/(\d{2}) (\d{9})/, "$1 $2")
    .replace(/(\d{9})\d+?$/, "$1")
}

export const currencyMask = (_value: string) => {
  let value = _value.replace(".", "").replace(",", "").replace(/\D/g, "")
  if (!value) {
    return ""
  }
  const options = { minimumFractionDigits: 2 }
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100,
  )
  return "R$ " + result
}

export const currencyUnMask = (value: string) => {
  value = value.replace(".", "").replace(",", "").replace(/\D/g, "")
  return value
}

export const maskNumberData = (_value: string) => {
  let value = _value.replace(".", "").replace(",", "").replace(/\D/g, "")
  if (!value) {
    return ""
  }
  const options = { minimumFractionDigits: 1 }
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseInt(value) / 10,
  )
  return result
}

export const unmaskNumberData = (value: string) => {
  value = value.replace(".", "").replace(",", ".")
  return value
}

export const unmaskNumber = (value: string) => {
  value = value.replace(/\D/g, "")
  return value
}