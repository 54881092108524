import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SELECT_DEVICE, READ_LATEST_DEVICE_DATA, READ_OWN_LATEST_DEVICE_DATA, READ_DEVICE_IMAGE_HOME, LIST_OWN_DEVICE_DATA, LIST_DEVICE_DATA } from '../../app/actionTypes';
import axios from 'axios';
import {
	getDeviceData,
	getDeviceDataReport,
	getOwnDeviceData,
	getOwnDeviceDataReport,
	readFile
} from '../../services/backendAPI';

export const readLatestDeviceData = createAsyncThunk(READ_LATEST_DEVICE_DATA, async (payload: { userId: string, deviceId: string }, thunkAPI) => {
	try {
		const response = await getDeviceData(payload.userId, payload.deviceId);
		return response;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 400) {
				return thunkAPI.rejectWithValue(error.response?.data.map((err: any) => ({ ...err })));
			} else {
				return thunkAPI.rejectWithValue(error.response?.data);
			}
		} else {
			return thunkAPI.rejectWithValue(error);
		}
	}
});

export const readOwnLatestDeviceData = createAsyncThunk(READ_OWN_LATEST_DEVICE_DATA, async (payload: { deviceId: string }, thunkAPI) => {
	try {
		const response = await getOwnDeviceData(payload.deviceId);
		return response;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 400) {
				return thunkAPI.rejectWithValue(error.response?.data.map((err: any) => ({ ...err })));
			} else {
				return thunkAPI.rejectWithValue(error.response?.data);
			}
		} else {
			return thunkAPI.rejectWithValue(error);
		}
	}
});

export const readDeviceImage = createAsyncThunk(
  READ_DEVICE_IMAGE_HOME,
  async (imageKey: string, thunkAPI) => {
    try {
      const { url } = await readFile(imageKey)
      return { url }
    } catch (error: any) {
      const errorResponse = error.response?.data
      if (error.response?.status === 400) {
        return thunkAPI.rejectWithValue(errorResponse.map((err: any) => ({ ...err })));
      } else {
        return thunkAPI.rejectWithValue(errorResponse);
      }
    }
  },
)

export const listDeviceData = createAsyncThunk(LIST_DEVICE_DATA, async (payload: { userId: string, deviceId: string, startDate: string, endDate: string }, thunkAPI) => {
	try {
		const response = await getDeviceDataReport(payload.userId, payload.deviceId, payload.startDate, payload.endDate);
		return response;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 400) {
				return thunkAPI.rejectWithValue(error.response?.data.map((err: any) => ({ ...err })));
			} else {
				return thunkAPI.rejectWithValue(error.response?.data);
			}
		} else {
			return thunkAPI.rejectWithValue(error);
		}
	}
});

export const listOwnDeviceData = createAsyncThunk(LIST_OWN_DEVICE_DATA, async (payload: { deviceId: string, startDate: string, endDate: string }, thunkAPI) => {
	try {
		const response = await getOwnDeviceDataReport(payload.deviceId, payload.startDate, payload.endDate);
		return response;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 400) {
				return thunkAPI.rejectWithValue(error.response?.data.map((err: any) => ({ ...err })));
			} else {
				return thunkAPI.rejectWithValue(error.response?.data);
			}
		} else {
			return thunkAPI.rejectWithValue(error);
		}
	}
});

interface HomeState {
	dataQueryLoading: boolean;
	error: any;
	device: any;
	deviceData: any;
	loading: boolean;
	report: any[];
}

const initialState: HomeState = {
	dataQueryLoading: false,
	device: {},
	error: null,
	deviceData: null,
	loading: false,
	report: []
};

const homeSlice = createSlice({
	name: 'home',
	initialState,
	reducers: {
		[SELECT_DEVICE]: (state, action: PayloadAction<any>) => {
			state.device = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(readLatestDeviceData.fulfilled, (state, action: PayloadAction<any>) => {
				state.dataQueryLoading = false;
				state.error = null;
				state.deviceData = action.payload;
			})
			.addCase(readLatestDeviceData.pending, (state) => {
				state.dataQueryLoading = true;
			})
			.addCase(readLatestDeviceData.rejected, (state, action: PayloadAction<any>) => {
				state.error = action.payload;
				state.dataQueryLoading = false;
			})
			.addCase(readOwnLatestDeviceData.fulfilled, (state, action: PayloadAction<any>) => {
				state.dataQueryLoading = false;
				state.error = null;
				state.deviceData = action.payload;
			})
			.addCase(readOwnLatestDeviceData.pending, (state) => {
				state.dataQueryLoading = true;
			})
			.addCase(readOwnLatestDeviceData.rejected, (state, action: PayloadAction<any>) => {
				state.error = action.payload;
				state.dataQueryLoading = false;
			})
			.addCase(listDeviceData.fulfilled, (state, action: PayloadAction<any>) => {
				state.loading = false;
				state.error = null;
				state.report = action.payload;
			})
			.addCase(listDeviceData.pending, (state) => {
				state.loading = true;
			})
			.addCase(listDeviceData.rejected, (state, action: PayloadAction<any>) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(listOwnDeviceData.fulfilled, (state, action: PayloadAction<any>) => {
				state.loading = false;
				state.error = null;
				state.report = action.payload;
			})
			.addCase(listOwnDeviceData.pending, (state) => {
				state.loading = true;
			})
			.addCase(listOwnDeviceData.rejected, (state, action: PayloadAction<any>) => {
				state.error = action.payload;
				state.loading = false;
			})
	},
});

export const actions = homeSlice.actions
export default homeSlice.reducer;
