import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";

interface GoBackButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    onGoBack: () => void;
    iconClassName?: string
}
function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}
export default function GoBackButton(Props: GoBackButtonProps) {
    const { onGoBack, iconClassName } = Props
    return (
        <button
            {...Props}
            onClick={onGoBack}
        >
            <ArrowLeftCircleIcon className={classNames("w-full h-full", iconClassName)}/>
        </button>
    )
}